import * as IState from '../IState'
import * as DFConsts from '../../components/DFConsts'

export interface IGenerativAPIError {
    error: string;
}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Error checking on any DeSocialWorld API function
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function HasError<T extends IGenerativAPIError>(json: T): boolean {
    if (json !== undefined && json !== null && (json.error === undefined || json.error === null || json.error.length === 0))
    {
        return false;
    }

    return true;
}

export function HandleGenerativAPIFail<T extends IGenerativAPIError | null>(InfoMessage: string, CurrentState: IState.IState, ResponseJson: T): boolean {
    if (ResponseJson === undefined || ResponseJson === null || HasError(ResponseJson))
    {
        if (ResponseJson !== undefined && ResponseJson !== null)
        {
            DFConsts.gConsoleLog('HandleGenerativAPIFail - ResponseJson.error: ' + ResponseJson.error);
        }

        const UpdatedState: IState.IState = DFConsts.CloneState(CurrentState);
        UpdatedState.props.ShowBusyIndicator = false;
        UpdatedState.props.InfoMessage = InfoMessage !== '' ? InfoMessage : undefined;
        window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));

        return true;
    }
    else
    {
        return false;
    }
}
