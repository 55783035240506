import * as IState from '../classes/IState'
import * as DFConsts from './DFConsts'
import Deso from 'deso-protocol';
import 'deso-protocol';
import { GetAppStateRequest, GetAppStateResponse, GetUsersStatelessRequest, GetUsersResponse } from 'deso-protocol-types';

export async function getAppState(PublicKeyBase58Check: string | undefined): Promise<GetAppStateResponse> {
  const deso = new Deso();
  const request: GetAppStateRequest = {
    "PublicKeyBase58Check": PublicKeyBase58Check !== undefined ? PublicKeyBase58Check : "BC1YLheA3NepQ8Zohcf5ApY6sYQee9aPJCPY6m3u6XxCL57Asix5peY",
  };
   const response: GetAppStateResponse = await deso.metaData.getAppState(request);

   return response;
}

export async function getUserDeSoNanosBalance(state: IState.IState): Promise<number | null> {
  DFConsts.gConsoleLog('DeSoMetadata.getUserDeSoBalance - User PublicKey: ' + state.props.PublicKey);

  if (state.props.PublicKey === undefined)
  {
    return null;
  }

  const deso = new Deso();
  const requestGetUsersStateless: GetUsersStatelessRequest = { 
    PublicKeysBase58Check: [state.props.PublicKey], 
    SkipForLeaderboard: false, 
    GetUnminedBalance: true, 
    IncludeBalance: true, 
  };
  const jsonResponseDataUserInfo: GetUsersResponse = await deso.user.getUserStateless(requestGetUsersStateless);

  if (jsonResponseDataUserInfo.UserList === null || jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse === null)
  {
    return null;
  }

  return jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse.DESOBalanceNanos;
}