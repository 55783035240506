import * as IGenerativAPIError from '../Security/IGenerativAPIError'
import * as IGenerativAPISecurity from '../Security/IGenerativAPISecurity'

export interface ISeriesMgmtRequest extends IGenerativAPISecurity.IGenerativAPISecurity
{
    NewSeriesDescription: string;
    SeriesBanner: File;
    NewSeriesPageComponents: string;
    IsMintingAllowed: boolean;
}
export interface ISeriesMgmtResponse  extends IGenerativAPIError.IGenerativAPIError
{
    updatedSeries: ISeries;
}

export interface ISeries
{
  uuid: string;
  name: string;
  description: string;
  maxCount: number;
  currentMintedCount: number;
  currentInitialMintedCount: number;
  isMultigeneration: boolean;
  isFirstInMultigeneration : boolean;
  parentGenerationUuid: string;
  inSeriesMergeMintingAllowed: boolean;
  isActive: boolean;
  isMintingAllowed: boolean;
  targetBlockchain: string;
  mintingPublicKey: string;
  mintingDerivedPublicKey: string;
  creatorRoyaltyNanos: number;
  coinRoyaltyNanos: number;
  minBidNanos: number;
  buyNowNanos: number;
  skipMintingNanos: number;
  additionalDeSoRoyaltiesLst: IAdditionalDeSoRoyalties[];
  additionalCoinRoyaltiesLst: IAdditionalCoinRoyalties[];
  postBodyTemplate: string;
  imagePixelsHorizontal: number;
  imagePixelsVertical: number;
  mintProcessType: MintProcessType;
  pmergeMintMinBidNanos: number;
  mergeMintBuyNowNanos: number;
  hasGuessedTrait: boolean;
  guessedTraitName: string;
  guessedTraitItemNameArr: string[];
  frontEndDisplayTheme: ISeriesFrontEndDisplayTheme; // not used currently
  seriesPageComponents: ISeriesPageComponents;
  seriesBannerImageMimeType: string;
}
export interface IAdditionalDeSoRoyalties
{
  publicKey: string;
  nanos: number;
}
export interface IAdditionalCoinRoyalties
{
  publicKey: string;
  nanos: number;
}
export interface ISeriesFrontEndDisplayTheme
{

}
export interface ISeriesPageComponents
{
    topPageBelowMintButtonBanner: string;
}

export enum MintProcessType
{
    UNDEFINED = 0,

    MintAndTransfer = 10,

    MintBidAcceptBid = 20,
}
