import * as DFConsts from './DFConsts';
import * as IState from "../classes/IState";
import * as IMetadata from "../classes/ProjectMgmt/IMetadata"
import * as IGenerativAPIError from '../classes/Security/IGenerativAPIError'

export async function getGenerativAPIMetadata(state: IState.IState): Promise<IState.IState> {
  DFConsts.gConsoleLog('GenerativAPICommon.getGenerativAPIMetadata');

  const requestJson: IMetadata.IMetadataRequest = { };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', accept: 'application/json', }, 
    RequestMode: 'cors',
    body: JSON.stringify(requestJson),
  } as RequestInit;

  return fetch(DFConsts.GenerativAPIURL_Metadata, requestOptions)
    .then(response => {
      if (response.status === 200) { return response.json() }
      DFConsts.gConsoleLog('GenerativAPICommon.getGenerativAPIMetadata - response.status: ' + response.status);
    })
    .then(jsonData  => {
      const ResponseJson: IMetadata.IMetadataResponse = jsonData;

      if (IGenerativAPIError.HandleGenerativAPIFail('Error getting metadata: ' + ResponseJson.error, state, ResponseJson)) { return state; };

      DFConsts.setGenericTheme(ResponseJson.defaultFrontendTheme);

      DFConsts.gConsoleLog('GenerativAPICommon.getGenerativAPIMetadata - got metadata');

      const UpdatedState: IState.IState = DFConsts.CloneState(state);
      UpdatedState.props.DefaultFrontendTheme = ResponseJson.defaultFrontendTheme;
      UpdatedState.props.IsMetadataLoaded = true;
      return UpdatedState;
    })
    .catch(err => {
      DFConsts.gConsoleLog('GenerativAPICommon.getGenerativAPIMetadata - error: ' + err);

      DFConsts.setGenericTheme(undefined);

      const UpdatedState: IState.IState = DFConsts.CloneState(state);
      UpdatedState.props.DefaultFrontendTheme = undefined;
      UpdatedState.props.IsMetadataLoaded = false;
      UpdatedState.props.ShowBusyIndicator = false;
      return UpdatedState;
    });
}
