export enum NFTItemStatus
{
    Undefined = 0,
    New_WaitingForConfirmOrSkip = 10, // ->> "waiting for mint" or "skipped" -> standard start
    New_MergeMint_WaitingForSourceTransfers = 11, // ->> "waiting for mint" -> merge mint start, advances automagically when source NFTs are received
    
    InProgress_Confirmed_WaitingForPost = 100, // ->> "postsubmitted"
    InProgress_PostSubmitted_WaitingForMint = 102, // ->> "minetd" (in one of paths)

    // transfer path
    InProgress_Minted_WaitingForTransfer = 110, // ->> "transferred or bid accepted"

    // bid / accept bid path
    InProgress_Minted_WaitingForBid = 120, // ->> "minted and bid placed"
    InProgress_MintedAndBidPlaced_WaitingForAcceptBid = 122, // -> "transferred or bid accepted"

    // terminal statuses
    EndStatus_Skipped = 200, // end status, will never be minted
    EndStatus_SourcesNotTransferred = 201, // end status, will never be minted
    EndStatus_TransferredOrBidAccepted = 210, // end status, minted and trasnferred to owner
    EndStatus_Burned = 1000, // end status, burned on merge minting
  }
export function statusAllowsShowingTraits(StatusToCheck: NFTItemStatus): boolean {
  switch(StatusToCheck)
  {
    case NFTItemStatus.New_WaitingForConfirmOrSkip:
    case NFTItemStatus.New_MergeMint_WaitingForSourceTransfers:
      return false;

    case NFTItemStatus.InProgress_Confirmed_WaitingForPost:
    case NFTItemStatus.InProgress_PostSubmitted_WaitingForMint:
    case NFTItemStatus.InProgress_Minted_WaitingForTransfer:
    case NFTItemStatus.InProgress_Minted_WaitingForBid:
    case NFTItemStatus.InProgress_MintedAndBidPlaced_WaitingForAcceptBid:
    case NFTItemStatus.EndStatus_Skipped:
    case NFTItemStatus.EndStatus_SourcesNotTransferred:
    case NFTItemStatus.EndStatus_TransferredOrBidAccepted:
    case NFTItemStatus.EndStatus_Burned:
      return true;

    case NFTItemStatus.Undefined:
      throw new Error('getNFTItemStatusPrettyString - Undefined status');
    default:
      throw new Error('getNFTItemStatusPrettyString - unwanted status');
  }
}
export function statusIsMinted(StatusToCheck: NFTItemStatus): boolean {
  switch(StatusToCheck)
  {
    case NFTItemStatus.New_WaitingForConfirmOrSkip:
    case NFTItemStatus.New_MergeMint_WaitingForSourceTransfers:
    case NFTItemStatus.InProgress_Confirmed_WaitingForPost:
    case NFTItemStatus.InProgress_PostSubmitted_WaitingForMint:
    case NFTItemStatus.EndStatus_Skipped:
    case NFTItemStatus.EndStatus_SourcesNotTransferred:
      return false;

    case NFTItemStatus.InProgress_Minted_WaitingForTransfer:
    case NFTItemStatus.InProgress_Minted_WaitingForBid:
    case NFTItemStatus.InProgress_MintedAndBidPlaced_WaitingForAcceptBid:
    case NFTItemStatus.EndStatus_TransferredOrBidAccepted:
    case NFTItemStatus.EndStatus_Burned:
      return true;

    case NFTItemStatus.Undefined:
      throw new Error('getNFTItemStatusPrettyString - Undefined status');
    default:
      throw new Error('getNFTItemStatusPrettyString - unwanted status');
  }
}
export function statusIsTerminal(StatusToCheck: NFTItemStatus): boolean {
  switch(StatusToCheck)
  {
    case NFTItemStatus.New_WaitingForConfirmOrSkip:
    case NFTItemStatus.New_MergeMint_WaitingForSourceTransfers:
    case NFTItemStatus.InProgress_Confirmed_WaitingForPost:
    case NFTItemStatus.InProgress_PostSubmitted_WaitingForMint:
    case NFTItemStatus.InProgress_Minted_WaitingForTransfer:
    case NFTItemStatus.InProgress_Minted_WaitingForBid:
    case NFTItemStatus.InProgress_MintedAndBidPlaced_WaitingForAcceptBid:
      return false;

    case NFTItemStatus.EndStatus_Skipped:
    case NFTItemStatus.EndStatus_SourcesNotTransferred:
    case NFTItemStatus.EndStatus_TransferredOrBidAccepted:
    case NFTItemStatus.EndStatus_Burned:
      return true;

    case NFTItemStatus.Undefined:
      throw new Error('getNFTItemStatusPrettyString - Undefined status');
    default:
      throw new Error('getNFTItemStatusPrettyString - unwanted status');
  }
}

export function getNFTItemStatusPrettyString (StatusToGet: NFTItemStatus): string {
    switch(StatusToGet)
    {
      case NFTItemStatus.New_WaitingForConfirmOrSkip:
        return 'Waiting for confirmation or skipping';
      case NFTItemStatus.New_MergeMint_WaitingForSourceTransfers:
        return 'Waiting for transfer of source NFTs';

      case NFTItemStatus.InProgress_Confirmed_WaitingForPost:
        return 'Confirmed, waiting for post creation';
      case NFTItemStatus.InProgress_PostSubmitted_WaitingForMint:
        return 'Post created, waiting for minting';
  
      case NFTItemStatus.InProgress_Minted_WaitingForTransfer:
        return 'Minted, waiting for transfer';

      case NFTItemStatus.InProgress_Minted_WaitingForBid:
        return 'Minted, waiting for user bid';
      case NFTItemStatus.InProgress_MintedAndBidPlaced_WaitingForAcceptBid:
        return 'Bid placed, waiting for acceptance';
      
      case NFTItemStatus.EndStatus_Skipped:
        return 'Minting skipped';
      case NFTItemStatus.EndStatus_SourcesNotTransferred:
        return 'Cancelled, source NFTs not received';
      case NFTItemStatus.EndStatus_TransferredOrBidAccepted:
        return 'In Owner\'s account';
      case NFTItemStatus.EndStatus_Burned:
        return 'Burned for merge-minting';

      case NFTItemStatus.Undefined:
        throw new Error('getNFTItemStatusPrettyString - Undefined status');
      default:
        throw new Error('getNFTItemStatusPrettyString - unwanted status');
    }
}
