import React from 'react';
import '../../styles/App.css';
import * as Button from "../_CustomComponents/CustomButtonComponent";
import * as IState from '../../classes/IState'
import * as IMintPass from '../../classes/MintPass/IMintPass'
import * as DFConsts from '../DFConsts'
import Modal from 'react-modal';
import * as CustomInputDeSoUsername from "../_CustomComponents/CustomInputDeSoUsername";
import * as IGenerativAPIError from '../../classes/Security/IGenerativAPIError'

export interface MintPassAddModalArgs {
  itemAsProp: {
    state: IState.IState;
    addButtonWidth: string;
  },
}

export function MintPassAddModal(props: MintPassAddModalArgs): JSX.Element {

// https://github.com/reactjs/react-modal
// https://stackoverflow.com/questions/48269381/warning-react-modal-app-element-is-not-defined-please-use-modal-setappeleme

// npm install --save react-modal
// npm i --save-dev @types/react-modal

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: '-10%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      background: 'var(--modal-windows-background-color)',
    },
  };

  const id_MintPassAddModal: string = "MintPassAddModal";
  let subtitle: HTMLHeadingElement | null;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle!.style.color = '#ffffff';
  }

  function closeModal() {
    setIsOpen(false);
  }

  async function addMintPass(PKInputElementId: string) {
    DFConsts.gConsoleLog('MintPassAddModal.addMintPass, PKInputElementId: ' + PKInputElementId);

    const PublicKeyToAdd: string = (document.getElementById(PKInputElementId) as HTMLInputElement).value;

    if (!DFConsts.IsPublicKeyValid(PublicKeyToAdd))
    {
      DFConsts.gConsoleLog('MintPassAddModal.addMintPass, PublicKeyToAdd invalid');

      const UpdatedState: IState.IState = DFConsts.CloneState(props.itemAsProp.state);
      UpdatedState.props.InfoMessage = 'Error - invalid Public Key: ' + (PublicKeyToAdd === undefined || PublicKeyToAdd === null || PublicKeyToAdd.length === 0 ? '<empty>' : PublicKeyToAdd) + ', not added.'
      window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
      return;
    }

    const requestJson: IMintPass.IMintPassAddRequest = {
      projectUuid: props.itemAsProp.state.props.NFTListProjectSelected?.uuid!, 
      publicKeyOwner: PublicKeyToAdd, 
      name: props.itemAsProp.state.props.NFTListProjectSelected?.name + ' - PK-based MintPass', 
      description: 'MintPass for PK: ' + PublicKeyToAdd, 
      sourceNFTPostHashHex: undefined,
      sourceNFTSerialNumber: undefined,
  
      UpdaterPublicKey: props.itemAsProp.state.props.PublicKey!, 
      SeriesUuid: props.itemAsProp.state.props.NFTListSeriesSelected?.uuid!, // TODO when I add option of adding non-series-assigned mint passes, need to change session key validation in API (now it just takes this session)
      SessionKey: DFConsts.GetCreatorInSeriesSessionKey(props.itemAsProp.state.props.PublicKey!, props.itemAsProp.state.props.NFTListSeriesSelected!.uuid)!,
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', accept: 'application/json', }, 
      RequestMode: 'cors',
      body: JSON.stringify(requestJson),
    } as RequestInit;

    fetch(DFConsts.GenerativAPIURL_MintPassAdd, requestOptions)
      .then(response => {
        if (response.status === 200) { return response.json(); }
        DFConsts.gConsoleLog('MintPassAddModal.addMintPass - response.status: ' + response.status);
      })
      .then(jsonData  => {
        const ResponseJson: IMintPass.IMintPassAddResponse = jsonData;

        if (IGenerativAPIError.HandleGenerativAPIFail('Adding MintPass failed.', props.itemAsProp.state, ResponseJson)) 
        {
          (document.getElementById('inmodal_addmintpass_infomessage_label') as HTMLLabelElement).textContent = 'MintPass add Error: ' + ResponseJson.error;
           return;
        };

        (document.getElementById('inmodal_addmintpass_infomessage_label') as HTMLLabelElement).textContent = 'MintPass added: ' + ResponseJson.addedMintPassItem.uuid;

        const MintPassListUpdatedState: IState.IState = DFConsts.CloneState(props.itemAsProp.state);
        MintPassListUpdatedState.props.CreatorMintPassDisplayList?.push(ResponseJson.addedMintPassItem);
        window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(MintPassListUpdatedState) }));
      })
      .catch(err => {
        DFConsts.gConsoleLog('MintPassAddModal.addMintPass error: ' + err);
        if (IGenerativAPIError.HandleGenerativAPIFail('Adding MintPass failed.', props.itemAsProp.state, null)) 
        {
          (document.getElementById('inmodal_addmintpass_infomessage_label') as HTMLLabelElement).textContent = 'MintPass add Error: ' + err;
          return;
        };
      });
  }

  return (
    <div className="user-container-internal"> 
      <Button.Button id="add_mint_pass_open_modal_button" onClick={(event) => { openModal(); }} width = {props.itemAsProp.addButtonWidth} children = "Add MintPass" />

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add MintPass Modal"
        id={id_MintPassAddModal}
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Add New MintPass</h2>
        <div>Public Key:</div>
        <form>
          <CustomInputDeSoUsername.CustomInputDeSoUsername 
            disabled={false} 
            input_id_publickey_to_populate="publickey_to_add_mintpass_for" 
            input_id_username_to_populate="username_to_add_mintpass_for" 
            input_id_encompassing_modal={id_MintPassAddModal } />
          <input type="text" id="username_to_add_mintpass_for" name="username_to_add_mintpass_for" 
            size={25} maxLength={25} style={{ fontSize: 'calc(6px + 1vmin)' }} disabled={true} 
            placeholder="Found DeSo Username..."
            onChange={ () => { }} />
          <input type="text" id="publickey_to_add_mintpass_for" name="publickey_to_add_mintpass_for" 
            size={60} maxLength={55} style={{ fontSize: 'calc(6px + 1vmin)', fontFamily:'monospace' }} disabled={true} 
            placeholder="Found DeSo Public Key..."
            onChange={ () => { }} />
        </form>
        <br/>
        <label  id="inmodal_addmintpass_infomessage_label" ></label>
        <br/><br/>
        <Button.Button id="add_mint_pass_exec_button"  
            onClick={() => { addMintPass('publickey_to_add_mintpass_for'); }} children = "Add MintPass" />
        &nbsp;&nbsp;
        <Button.Button id="add_mint_pass_close_button" 
            onClick={() => { closeModal(); }} children = "Close" />
      </Modal>
    </div>
  );
}
