import React from "react";

// https://www.twilio.com/blog/intro-custom-button-component-typescript-react

// calc widths/heights: https://stackoverflow.com/questions/38823863/use-css-jquery-calc-function-in-react-js

interface Props {
  fontsize?: string;
  border?: string;
  color?: string;
  children?: React.ReactNode;
  height?: string;
  onClick: (event: any) => void;
  radius?: string
  width?: string;
  id: string;
  disabled?: boolean;
}

// CSS colors: https://www.w3schools.com/cssref/css_colors.asp

const EnabledColor:string = 'black';
const DisabledColor:string = '#444444';

export const Button: React.FC<Props> = ({ 
    fontsize = "calc(6px + 1vmin)",
    border = "2px solid",
    color = 'var(--custom-button-component-background-color)',
    children,
    height = "calc(24px + 2vmin)",
    onClick, 
    radius = '5%',
    width = "calc(160px + 2vmin)",
    id,
    disabled = false,
  }) => { 

  const FinalBorderColor: string = disabled ? DisabledColor : EnabledColor;

  return (
    <button 
      id={id} 
      disabled={disabled}
      onClick={(ev) => {
        onClick(ev); 
        if((document.getElementById(id) as HTMLButtonElement) !== undefined && (document.getElementById(id) as HTMLButtonElement) !== null &&
          (document.getElementById(id) as HTMLButtonElement).style !== undefined && (document.getElementById(id) as HTMLButtonElement).style !== null) {

          (document.getElementById(id) as HTMLButtonElement).style.background = 'var(--custom-button-component-background-color-clicked)';  
          (document.getElementById(id) as HTMLButtonElement).style.borderColor = 'red';  
          
          setTimeout(() => {
            if((document.getElementById(id) as HTMLButtonElement) !== undefined && (document.getElementById(id) as HTMLButtonElement) !== null &&
              (document.getElementById(id) as HTMLButtonElement).style !== undefined && (document.getElementById(id) as HTMLButtonElement).style !== null) {

              (document.getElementById(id) as HTMLButtonElement).style.background = color;  
              (document.getElementById(id) as HTMLButtonElement).style.borderColor = FinalBorderColor;  
            }
          }, 500);
        }
      }}
      style={{
        fontSize: fontsize,
        backgroundColor: color,
        
        border,
        borderRadius: radius, 
        borderColor: FinalBorderColor, 
        color: FinalBorderColor, 
        
        padding: '1px', 
        marginBottom: '3px', 
        height,
        width,
      }}
    >
    {children}
    </button>
  );
}
