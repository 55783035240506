import React from 'react';
import '../../styles/App.css';
import * as Button from "../_CustomComponents/CustomButtonComponent";
import * as IState from '../../classes/IState'
import * as DFConsts from '../DFConsts'
import Deso from 'deso-protocol';
import 'deso-protocol';
import { TransactionType, NFTOperationLimitMap, NFTLimitOperationString, } from 'deso-protocol-types';
import { SendMessageStatelessResponse } from 'deso-protocol-types';
import * as MintPassAddModal from '../MintPass/MintPassAddModal'
import * as MintPassListModal from '../MintPass/MintPassListModal'
import * as IAuthorizeDerivedKey from '../../classes/Security/IAuthorizeDerivedKey'
import * as UpdateSeriesModal from '../ProjectMgmt/UpdateSeriesModal'

export class ColumnLeft extends React.Component<IState.IState, IState.IState> {
  constructor (props: IState.IState) {
    super(props);
    DFConsts.gConsoleLog('ColumnLeft.constructor - PublicKey: ' + props.props.PublicKey);
  }

  addMintPass = async (buttonName: string) => {
    DFConsts.gConsoleLog('ColumnLeft.addMintPass');

    (document.getElementById(buttonName) as HTMLButtonElement).disabled = false;

    const AddMintPassState: IState.IState = DFConsts.CloneState(this.props);
    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(AddMintPassState) }));
  }
  
  authorizeDerivedKeyForMinting  = async (buttonName: string) => {
    DFConsts.gConsoleLog('ColumnLeft.authorizeDerivedKeyForMinting');

    const BasicTransferTransactionsNumber: number = 0; // not needed currently
    const GlobalReserveFactor: number = 1.2; // TODO assume 20%; parametrize globally someday, in metadata
    const MiscTransactionsReserveNumber: number = Math.round(this.props.props.NFTListSeriesSelected!.maxCount * 0.1); // TODO assume 10% of max count in series, parametrize in metadata

    // TODO parametrize?; number of transactions (submit post + create nft + transfer nft + reserve) * gas price + reserve;
    const MergeMintsMultiplier: number = 2; // TODO parametrizable in series; probably different for in-series merge mints, different for multigeneration; for in-series: * 2 should be enough
    const GlobalDESOLimit_Deso: number = Math.ceil(((this.props.props.NFTListSeriesSelected!.maxCount * 3 + this.props.props.NFTListSeriesSelected!.maxCount * MergeMintsMultiplier) * GlobalReserveFactor * DFConsts.DerivedKeySigningMinFeeRateNanosPerKB) / DFConsts.NanosInDeso);

    const NFTOperationLimitMap: NFTOperationLimitMap = {      
        '': { // only unknown PostHashHex - we will be minting them, unknown at start
          0: { // serial number one - we only allow minting 1/1 NFTs
            [NFTLimitOperationString.ANY]: Math.round(MiscTransactionsReserveNumber), // reserve -> UNLESS this has to include the 10k mints 
            [NFTLimitOperationString.UPDATE]: Math.round(MiscTransactionsReserveNumber), // reserve for bad cases
            [NFTLimitOperationString.TRANSFER]: Math.round(this.props.props.NFTListSeriesSelected!.maxCount * MergeMintsMultiplier * GlobalReserveFactor), // max number in series * merge-mint factor + global reserve
            [NFTLimitOperationString.ACCEPT_TRANSFER]: Math.round(this.props.props.NFTListSeriesSelected!.maxCount * MergeMintsMultiplier * GlobalReserveFactor), // reverse transfers from users for merge minting - for now setting for all, should be dependent on is series minting and generations + if there are only 2 sources + merge-mint
            [NFTLimitOperationString.ACCEPT_BID]: Math.round(this.props.props.NFTListSeriesSelected!.maxCount * MergeMintsMultiplier * GlobalReserveFactor), // max number in series * merge-mint factor + global reserve
            [NFTLimitOperationString.BURN]: Math.round(this.props.props.NFTListSeriesSelected!.maxCount * MergeMintsMultiplier * GlobalReserveFactor), // burning of source NFTs from users for merge minting - for now setting for all, should be dependent on is series minting and generations + if there are only 2 sources + merge-mint
          }
        }      
    };
    const TransactionCountLimitMap: {[key: string]: number;} = {
      [TransactionType.AuthorizeDerivedKey]: Math.round(IAuthorizeDerivedKey.AuthorizeDerivedKeyTransactionsNumber_MinRequired),
      [TransactionType.BasicTransfer]: Math.round(BasicTransferTransactionsNumber), // not needed currently
      [TransactionType.SubmitPost]: Math.round(this.props.props.NFTListSeriesSelected!.maxCount * MergeMintsMultiplier * GlobalReserveFactor), // max number in series * mergemint + global reserve
      [TransactionType.CreateNFT]: Math.round(this.props.props.NFTListSeriesSelected!.maxCount * MergeMintsMultiplier * GlobalReserveFactor), // max number in series * mergemint + global reserve
      [TransactionType.UpdateNFT]: Math.round(MiscTransactionsReserveNumber), // reserve for bad cases
      [TransactionType.NFTTransfer]: Math.round(this.props.props.NFTListSeriesSelected!.maxCount * MergeMintsMultiplier * GlobalReserveFactor), // as above in submit post and create NFT - max number in series + global reserve + mergemint
      [TransactionType.AcceptNFTTransfer]: Math.round(this.props.props.NFTListSeriesSelected!.maxCount * MergeMintsMultiplier * GlobalReserveFactor), // burning of source NFTs from users for merge minting - for now setting for all, should be dependent on is series minting and generations + if there are only 2 sources + mergemint
      [TransactionType.AcceptNFTBid]: Math.round(this.props.props.NFTListSeriesSelected!.maxCount * MergeMintsMultiplier * GlobalReserveFactor), // max number in series * mergemint + global reserve
      [TransactionType.BurnNFT]: Math.round(this.props.props.NFTListSeriesSelected!.maxCount * MergeMintsMultiplier * GlobalReserveFactor), // burning of source NFTs from users for merge minting - for now setting for all, should be dependent on is series minting and generations + if there are only 2 sources + mergemint
  };

  const IsKeyAuthorized: boolean = await IAuthorizeDerivedKey.authorizeDerivedKey_Creator_ForMinting(
      this.props,
      IAuthorizeDerivedKey.AuthorizeDerivedKeyMode.Creator,
      GlobalDESOLimit_Deso,
      TransactionCountLimitMap,
      NFTOperationLimitMap,
      {}
      );

    DFConsts.gConsoleLog('CampaignListTable.authorizeDerivedKey_Post - authorizeDerivedKey_Payout result: ' + IsKeyAuthorized);

    (document.getElementById(buttonName) as HTMLButtonElement).disabled = false;
  };

  displayNFTList = async () => {
    DFConsts.gConsoleLog('ColumnLeft.displayNFTList');
    const UpdatedState: IState.IState = DFConsts.CloneState(this.props);
    UpdatedState.props.BodyMode = DFConsts.BodyMode_ProjectNFTList;
    UpdatedState.props.IsLeftSlidingMenuBarVisible = false;
    UpdatedState.props.NFTItemGridDisplayList = undefined;
    UpdatedState.props.InfoMessage = undefined;
    UpdatedState.props.NFTMergeMintDictAllForUser_Left = undefined;
    UpdatedState.props.NFTMergeMintDictAllForUser_Right = undefined;
    UpdatedState.props.NFTMergeMintDictAllForUser_Merged = undefined;
    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
  };

  showuserNFTs = async () => {
    DFConsts.setGenericTheme(this.props.props.DefaultFrontendTheme);
    const UpdatedState: IState.IState = DFConsts.CloneState(this.props);
    UpdatedState.props.BodyMode = DFConsts.BodyMode_UserNFTList;
    UpdatedState.props.IsLeftSlidingMenuBarVisible = false;
    UpdatedState.props.NFTItemGridDisplayList = undefined;
    UpdatedState.props.InfoMessage = undefined;
    UpdatedState.props.NFTMergeMintDictAllForUser_Left = undefined;
    UpdatedState.props.NFTMergeMintDictAllForUser_Right = undefined;
    UpdatedState.props.NFTMergeMintDictAllForUser_Merged = undefined;
    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
  };
  
  DMtest = async () => {

    const request = {
      SenderPublicKeyBase58Check: DFConsts.key_StaryKolarz,
      RecipientPublicKeyBase58Check: DFConsts.key_Celestoid,
      MessageText: 'test message X',
    };
    const deso = new Deso();
    const jsonResponse:SendMessageStatelessResponse = await deso.social.sendMessage(request);
    console.log(jsonResponse);
  }

  render() {
    //DFConsts.gConsoleLog('ColumnLeft.render');

    if (this.props.props.IsLoggedIn)
    {
      let AuthorizeButton: JSX.Element = (<div></div>);
      if (this.props.props.PublicKey === this.props.props.NFTListSeriesSelected?.mintingPublicKey)
      {
        AuthorizeButton = (
            <Button.Button id="authorize_derived_key_for_minting_button" 
              onClick={(event) => {
                (document.getElementById('authorize_derived_key_for_minting_button') as HTMLButtonElement).disabled = true; 
                DFConsts.showBusyIndicator(this.props); 
                this.authorizeDerivedKeyForMinting('authorize_derived_key_for_minting_button'); 
              }} width = "80%" children = "Authorize Minting" />
        )
      }

      const AddModalProps: MintPassAddModal.MintPassAddModalArgs = { itemAsProp: { state: this.props, addButtonWidth: "80%"}};
      const CreatorMenu: JSX.Element = (this.props.props.NFTListSeriesSelected !== undefined && this.props.props.PublicKey === this.props.props.NFTListSeriesSelected.mintingPublicKey) ? 
        <div>
          <p>
            Creator
          </p>
          <div className="user-container">
            {AuthorizeButton}
          </div>
          <div className="user-container" onClick={(event) => {  }}>
            <MintPassAddModal.MintPassAddModal {...AddModalProps}/>
          </div>
          <div className="user-container" onClick={(event) => {  }}>
            <MintPassListModal.MintPassListModal {...this.props}/>
          </div>
          <div className="user-container" onClick={(event) => {  }}>
            <UpdateSeriesModal.UpdateSeriesModal {...AddModalProps}/>
          </div>
        </div>
        : <div></div>;

      return (  
        <div className="ColumnLeft" onClick={(event) => { event.stopPropagation(); }} >
          <p>
            Collector
          </p>
          {
            this.props.props.BodyMode !== DFConsts.BodyMode_Landing &&
            <>
              <div className="user-container"> 
                <Button.Button id="column_left_goto_landing_loggedin_button" onClick={(event) => {DFConsts.gotoLanding(this.props);  }} width = "80%" children = "Projects" />
              </div>
              {
                this.props.props.NFTListSeriesSelected !== undefined && 
                <>
                  <div className="user-container"> 
                    <Button.Button id="column_left_display_nft_list_loggedin_button" onClick={(event) => {this.displayNFTList();  }} width = "80%" children = "NFT Series" />
                  </div>
                  <div className="user-container"> 
                    <Button.Button id="column_left_mint_nft_button" disabled={(this.props.props.IsLoggedIn && this.props.props.NFTListSeriesSelected?.isMintingAllowed) ? false : true} 
                      onClick={(event) => {DFConsts.mintNFT(this.props);  }} width = "80%" children = "Mint NFT" />
                  </div>
                </>
              }
            </>
          }
          <div className="user-container"> 
            <Button.Button id="column_left_user_nfts_button" onClick={(event) => { this.showuserNFTs();  }} width = "80%" children = "My NFTs" />
          </div>
          <div className="user-container"> 
            <Button.Button id="column_left_user_authorizenewbrowser_button" onClick={(event) => { IAuthorizeDerivedKey.authorizeDerivedKey_AuthorizeBrowser(this.props); }} width = "80%" children = "Authorize New Browser" />
          </div>
          {
            this.props.props.PublicKey === DFConsts.key_StaryKolarz && 
            <div className="user-container"> 
              <Button.Button id="DM_test" onClick={(event) => {this.DMtest();  }} width = "80%" children = "DM test" />
            </div>
          }
          {CreatorMenu}
          <div className="versioninfo" > {DFConsts.AppVersion} </div>
        </div>
      );
    }
    else
    {
      return (
      <div className="ColumnLeft" onClick={(event) => { event.stopPropagation(); }} >
        <p>
          Menu
        </p>
        <div className="user-container"> 
          <Button.Button id="column_left_goto_landing_notloggedin_button" onClick={(event) => {DFConsts.gotoLanding(this.props);  }} width = "80%" children = "Projects" />
        </div>
        {
          this.props.props.NFTListProjectSelected !== undefined && this.props.props.NFTListSeriesSelected !== undefined &&
            <div className="user-container"> 
              <Button.Button id="column_left_display_nft_list_notloggedin_button" onClick={(event) => {this.displayNFTList();  }} width = "80%" children = "NFT List" />
            </div>
        }
        <div className="versioninfo" > {DFConsts.AppVersion} </div>
      </div>
      );
    }
  }
}

export default ColumnLeft;
