import * as React from "react";
import NFTItemData from './NFTItemData'
import * as DFConsts from '../DFConsts';
import * as IState from "../../classes/IState";
import * as INFTItemList from "../../classes/NFTItemOps/INFTItemList";
import * as INFTItemStatus from "../../classes/NFTItemOps/INFTItemStatus"
import * as IGenerativAPIError from '../../classes/Security/IGenerativAPIError'

interface INFTImageGridState {
  ItemGridShowTraits: boolean;
  ItemGridShowDesoCurrentInfo: boolean;
}

export class NFTImageGrid extends React.Component<IState.IState, INFTImageGridState> {
  constructor (props: IState.IState) {
    super(props);
    DFConsts.gConsoleLog('NFTImageGrid.constructor - PublicKey: ' + props.props.PublicKey);
    this.SeriesNamesList = [];
    this.setState({ ItemGridShowTraits: false });
    this.setState({ ItemGridShowDesoCurrentInfo: false });
  }

  shouldComponentUpdate(nextProps: IState.IState, nextState: INFTImageGridState)
  {
    // https://www.freecodecamp.org/news/how-to-identify-and-resolve-wasted-renders-in-react-cc4b1e910d10/
    const PropsChanged: boolean = this.props !== nextProps;
    const StateChanged: boolean = this.state !== nextState;
    const NFTItemGridDisplayListChanged: boolean = this.props.props.NFTItemGridDisplayList !== nextProps.props.NFTItemGridDisplayList;
    DFConsts.gConsoleLog('NFTImageGrid.shouldComponentUpdate - PropsChanged: ' + PropsChanged + ', StateChanged: ' + StateChanged + ', NFTItemGridDisplayListChanged: ' + NFTItemGridDisplayListChanged);
    return PropsChanged || StateChanged || NFTItemGridDisplayListChanged;
  }

  SeriesNamesList: string[];

  componentDidUpdate () {
    DFConsts.gConsoleLog('NFTImageGrid.componentDidUpdate - mode: ' + this.props.props.BodyMode);

    if (this.props.props.NFTItemGridDisplayList === undefined)
    {
      this.componentDidMount();
    }
  }
  componentDidMount () {
    DFConsts.gConsoleLog('NFTImageGrid.componentDidMount - mode: ' + this.props.props.BodyMode);

    if (this.props.props.BodyMode === DFConsts.BodyMode_ProjectNFTList)
    {
      fetch(DFConsts.GenerativAPIURL_NFTItemList + '?SeriesUuidToList=' + this.props.props.NFTListSeriesSelected?.uuid, {mode: 'cors'})
        .then(response => {
          if (response.status === 200) { return response.json(); }
          DFConsts.gConsoleLog('NFTImageGrid.componentDidMount - response.status: ' + response.status);
        })
        .then(jsonData => {
          const ResponseJson: INFTItemList.INFTItemListResponse = jsonData;

          if (IGenerativAPIError.HandleGenerativAPIFail('Getting image list failed.', this.props, ResponseJson)) { return; };

          DFConsts.gConsoleLog('NFTImageGrid.componentDidMount - got image list, count: ' + ResponseJson.nftItemLst.length);
    
          const UpdatedState: IState.IState = DFConsts.CloneState(this.props);
          UpdatedState.props.NFTItemGridDisplayList = JSON.parse(JSON.stringify(ResponseJson.nftItemLst));
          UpdatedState.props.NFTItemGridDisplayList?.sort((a, b) => a.metadata.rarityIndex - b.metadata.rarityIndex); // default sorting - rarest first
          UpdatedState.props.NFTItemGridDisplayList?.forEach(item => {
            item.isVisibleByStatus = true; 
            item.isVisibleBySeries = true;
          });
          window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
        })
        .catch(err => {
          DFConsts.gConsoleLog('NFTImageGrid.componentDidMount - project NFTs list - error: ' + err);
          if (IGenerativAPIError.HandleGenerativAPIFail('Getting image list failed.', this.props, null)) { return; };
        });
    }
    else if (this.props.props.BodyMode === DFConsts.BodyMode_UserNFTList)
    {
      fetch(DFConsts.GenerativAPIURL_NFTItemListForUser + '?UserPublicKeyToList=' + this.props.props.PublicKey, {mode: 'cors'})
      .then(response => {
        if (response.status === 200) { return response.json(); }
        DFConsts.gConsoleLog('NFTImageGrid.componentDidMount - response.status: ' + response.status);
      })
      .then(jsonData => {
        const ResponseJson: INFTItemList.INFTItemListResponse = jsonData;

        if (IGenerativAPIError.HandleGenerativAPIFail('Getting user image list failed.', this.props, ResponseJson)) { return; };

        DFConsts.gConsoleLog('NFTImageGrid.componentDidMount - got user image list, count: ' + ResponseJson.nftItemLst.length);

        const UpdatedState: IState.IState = DFConsts.CloneState(this.props);
        UpdatedState.props.NFTItemGridDisplayList = JSON.parse(JSON.stringify(ResponseJson.nftItemLst));
        UpdatedState.props.NFTItemGridDisplayList?.sort((a, b) => a.metadata.rarityIndex - b.metadata.rarityIndex); // default sorting - rarest first
        UpdatedState.props.NFTItemGridDisplayList?.forEach(item => {
          item.isVisibleByStatus = true; 
          item.isVisibleBySeries = true;
          if (!this.SeriesNamesList.includes(item.metadata.seriesName))
          {
            this.SeriesNamesList.push(item.metadata.seriesName);
          }
        });
        window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
      })
      .catch(err => {
        DFConsts.gConsoleLog('NFTImageGrid.componentDidMount - user NFTs list - error: ' + err);
        if (IGenerativAPIError.HandleGenerativAPIFail('Getting user image list failed.', this.props, null)) { return; };
      });
    }
    else
    {
      throw new Error('NFTImageGrid.componentDidMount - invalid mode.');
    }
  }

  render() {
    DFConsts.gConsoleLog('NFTImageGrid.render - mode: ' + this.props.props.BodyMode);

    switch(this.props.props.BodyMode)
    {
      case DFConsts.BodyMode_ProjectNFTList:
      case DFConsts.BodyMode_UserNFTList:
        return ( 
          <>
            <div className="nft-item-grid">
              <div className="nft-item-grid-gradient">
                <br/>
              </div>
              <br/>
              <div style={{fontSize: "calc(6px + 1vmin)", marginBottom: "20px" }}>
                <i>✋ List below shows state at moment of minting - it is not (yet) synchronized with DeSo.</i>
              </div>
              <div className="user-container-wrapping">
                <div className="user-container-internal" style={{fontSize: 'calc(12px + 1vmin)'}}>
                  <div>
                    NFT Sorting: 
                    <select id="ItemGridSortingMember" name="ItemGridSortingMember" className="NFTSortingOrFilteringCombo" 
                    onChange={() => {
                      const NFTListUpdatedState: IState.IState = DFConsts.CloneState(this.props);
                        switch((document.getElementById("ItemGridSortingMember") as HTMLSelectElement).selectedOptions[0].value)
                        {
                          case "ItemGridSortingMember_Rarity":
                            NFTListUpdatedState.props.NFTItemGridDisplayList?.sort((a, b) => a.metadata.rarityIndex - b.metadata.rarityIndex);
                            break;
                          case "ItemGridSortingMember_RarityReverse":
                            NFTListUpdatedState.props.NFTItemGridDisplayList?.sort((a, b) => b.metadata.rarityIndex - a.metadata.rarityIndex);
                            break;
                          case "ItemGridSortingMember_Number":
                            NFTListUpdatedState.props.NFTItemGridDisplayList?.sort((a, b) => a.numberInSeries - b.numberInSeries);
                            break;
                          case "ItemGridSortingMember_NumberReverse":
                            NFTListUpdatedState.props.NFTItemGridDisplayList?.sort((a, b) => b.numberInSeries - a.numberInSeries);
                            break;
                        }
                        window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(NFTListUpdatedState) }));
                      }}
                      >
                      <option id="ItemGridSortingMember_Rarity" value="ItemGridSortingMember_Rarity" >Rarest First</option>
                      <option id="ItemGridSortingMember_RarityReverse" value="ItemGridSortingMember_RarityReverse" >Rarest Last</option>
                      <option id="ItemGridSortingMember_Number" value="ItemGridSortingMember_Number" >#Edition ↗</option>
                      <option id="ItemGridSortingMember_NumberReverse" value="ItemGridSortingMember_NumberReverse" >#Edition ↘</option>
                    </select>
                  </div>
                </div>
                &nbsp;&nbsp;
                {
                  this.props.props.BodyMode === DFConsts.BodyMode_UserNFTList &&
                    <div className="user-container-internal" style={{fontSize: 'calc(12px + 1vmin)'}}>
                      <div>
                        Series filter: 
                        <select id="ItemGridUserSeriesFilter" name="ItemGridUserSeriesFilter" className="NFTSortingOrFilteringCombo" 
                          onChange={() => {
                            const NFTListUpdatedState: IState.IState = DFConsts.CloneState(this.props);
                            NFTListUpdatedState.props.NFTItemGridDisplayList?.forEach(item => 
                              (document.getElementById("ItemGridUserSeriesFilter") as HTMLSelectElement).selectedOptions[0].value === (document.getElementById("ItemGridUserSeriesFilter_All") as HTMLOptionElement).value ? 
                                item.isVisibleBySeries = true
                                : item.metadata.seriesName === (document.getElementById("ItemGridUserSeriesFilter") as HTMLSelectElement).selectedOptions[0].value ? 
                                    item.isVisibleBySeries = true 
                                    : item.isVisibleBySeries = false);
                            window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(NFTListUpdatedState) }));
                          }}
                          >
                          <option id="ItemGridUserSeriesFilter_All" value="ItemGridUserSeriesFilter_All" >All</option>
                        {
                          this.SeriesNamesList.map((itemSeries, i) => <option id={itemSeries} value={itemSeries} key={itemSeries} >{itemSeries}</option>)
                        }
                        </select>
                      </div>
                    </div>
                }
                &nbsp;&nbsp;
                <div className="user-container-internal" style={{fontSize: 'calc(12px + 1vmin)'}}>
                  <div>
                    Status filter: 
                    <select id="ItemGridStatusFilter" name="ItemGridStatusFilter" className="NFTSortingOrFilteringCombo" 
                      onChange={() => {
                        const NFTListUpdatedState: IState.IState = DFConsts.CloneState(this.props);
                        if ((document.getElementById("ItemGridStatusFilter") as HTMLSelectElement).selectedOptions[0].value === "ItemGridStatusFilter_All")
                        {
                          NFTListUpdatedState.props.NFTItemGridDisplayList?.forEach(item => item.isVisibleByStatus = true);
                        }
                        else
                        {
                          NFTListUpdatedState.props.NFTItemGridDisplayList?.forEach(item => item.mintingStatus.toString() === (document.getElementById("ItemGridStatusFilter") as HTMLSelectElement).selectedOptions[0].value
                            ? item.isVisibleByStatus = true : item.isVisibleByStatus = false);
                        }
                        window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(NFTListUpdatedState) }));
                      }} 
                    >
                      <option value="ItemGridStatusFilter_All" >All</option>
                      <option value={INFTItemStatus.NFTItemStatus.EndStatus_TransferredOrBidAccepted} >{INFTItemStatus.getNFTItemStatusPrettyString(INFTItemStatus.NFTItemStatus.EndStatus_TransferredOrBidAccepted)}</option>
                      {this.props.props.BodyMode === DFConsts.BodyMode_UserNFTList && <option value={INFTItemStatus.NFTItemStatus.InProgress_Confirmed_WaitingForPost} >{INFTItemStatus.getNFTItemStatusPrettyString(INFTItemStatus.NFTItemStatus.InProgress_Confirmed_WaitingForPost)}</option>}
                      {this.props.props.BodyMode === DFConsts.BodyMode_UserNFTList && <option value={INFTItemStatus.NFTItemStatus.InProgress_PostSubmitted_WaitingForMint} >{INFTItemStatus.getNFTItemStatusPrettyString(INFTItemStatus.NFTItemStatus.InProgress_PostSubmitted_WaitingForMint)}</option>}
                      <option value={INFTItemStatus.NFTItemStatus.InProgress_Minted_WaitingForTransfer} >{INFTItemStatus.getNFTItemStatusPrettyString(INFTItemStatus.NFTItemStatus.InProgress_Minted_WaitingForTransfer)}</option>
                      <option value={INFTItemStatus.NFTItemStatus.InProgress_Minted_WaitingForBid} >{INFTItemStatus.getNFTItemStatusPrettyString(INFTItemStatus.NFTItemStatus.InProgress_Minted_WaitingForBid)}</option>
                      <option value={INFTItemStatus.NFTItemStatus.InProgress_MintedAndBidPlaced_WaitingForAcceptBid} >{INFTItemStatus.getNFTItemStatusPrettyString(INFTItemStatus.NFTItemStatus.InProgress_MintedAndBidPlaced_WaitingForAcceptBid)}</option>
                      {this.props.props.BodyMode === DFConsts.BodyMode_UserNFTList && <option value={INFTItemStatus.NFTItemStatus.New_WaitingForConfirmOrSkip} >{INFTItemStatus.getNFTItemStatusPrettyString(INFTItemStatus.NFTItemStatus.New_WaitingForConfirmOrSkip)}</option>}
                      {this.props.props.BodyMode === DFConsts.BodyMode_UserNFTList && <option value={INFTItemStatus.NFTItemStatus.New_MergeMint_WaitingForSourceTransfers} >{INFTItemStatus.getNFTItemStatusPrettyString(INFTItemStatus.NFTItemStatus.New_MergeMint_WaitingForSourceTransfers)}</option>}
                      {this.props.props.BodyMode === DFConsts.BodyMode_UserNFTList && <option value={INFTItemStatus.NFTItemStatus.EndStatus_Skipped} >{INFTItemStatus.getNFTItemStatusPrettyString(INFTItemStatus.NFTItemStatus.EndStatus_Skipped)}</option>}
                      {this.props.props.BodyMode === DFConsts.BodyMode_UserNFTList && <option value={INFTItemStatus.NFTItemStatus.EndStatus_SourcesNotTransferred} >{INFTItemStatus.getNFTItemStatusPrettyString(INFTItemStatus.NFTItemStatus.EndStatus_SourcesNotTransferred)}</option>}
                      <option value={INFTItemStatus.NFTItemStatus.EndStatus_Burned} >{INFTItemStatus.getNFTItemStatusPrettyString(INFTItemStatus.NFTItemStatus.EndStatus_Burned)}</option>
                    </select>
                  </div>
                </div>
                &nbsp;&nbsp;
                <div className="user-container-internal" style={{fontSize: 'calc(12px + 1vmin)'}}>
                  <div>
                    Display scores:&nbsp;
                    <input type="checkbox" id="ItemGridShowTraitsCheckbox" 
                      defaultChecked={false} 
                      disabled={false} 
                      onChange={() => { 
                        this.setState({ 
                          ItemGridShowTraits: (document.getElementById("ItemGridShowTraitsCheckbox") as HTMLInputElement).checked, 
                          ItemGridShowDesoCurrentInfo: (document.getElementById("ItemGridShowDesoCurrentInfoCheckbox") as HTMLInputElement).checked, 
                        })
                      }}
                    />
                  </div>
                </div>
                &nbsp;&nbsp;
                <div className="user-container-internal" style={{fontSize: 'calc(12px + 1vmin)'}}>
                  <div>
                    Display DeSo Info:&nbsp;
                    <input type="checkbox" id="ItemGridShowDesoCurrentInfoCheckbox" 
                      defaultChecked={false} 
                      disabled={false} 
                      onChange={() => { 
                        this.setState({ 
                          ItemGridShowTraits: (document.getElementById("ItemGridShowTraitsCheckbox") as HTMLInputElement).checked,
                          ItemGridShowDesoCurrentInfo: (document.getElementById("ItemGridShowDesoCurrentInfoCheckbox") as HTMLInputElement).checked, 
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="ImageContainer">
                {
                  this.props.props.NFTItemGridDisplayList?.map((item, i) => (item.isVisibleByStatus && item.isVisibleBySeries) && 
                    <NFTItemData itemAsProp={
                      {
                        item: item, 
                        showTraits: this.state === null ? false : this.state.ItemGridShowTraits, 
                        showDesoCurrentInfo: this.state === null ? false : this.state.ItemGridShowDesoCurrentInfo, 
                        desoDataLoadDelayMs: Math.random() * 1000 * (this.props.props.NFTItemGridDisplayList?.length! / 20), // divisor shows allowed number of queries to deso per second (remember there are 2 calls - nft bids & user info)
                        state: this.props, 
                      }} 
                      key={item.uuid} 
                    />) 
                }
              </div>
              <br/>
            </div>
          </> 
        );
      default:
        throw new Error('NFTImageGrid.render - invalid mode: ' + this.props.props.BodyMode);
    }
  }
}
