import React from 'react';
import '../../styles/App.css';
import Landing from './Landing';
import Columns from './Columns';
import * as IState from '../../classes/IState'
import * as DFConsts from '../DFConsts'

export class Body extends React.Component<IState.IState, IState.IState> {
  constructor (props: IState.IState) {
    super(props);
    DFConsts.gConsoleLog('Body.constructor - PublicKey: ' + props.props.PublicKey);
  }

render() {
  DFConsts.gConsoleLog('Body.render - BodyMode: ' + this.props.props.BodyMode);
  DFConsts.gConsoleLogEx('BodyMode: ' + this.props.props.BodyMode);

  switch(this.props.props.BodyMode)
  {
    case DFConsts.BodyMode_Landing:
      return (  
        <div>
          <Landing props={this.props.props} />
        </div>
      );
    case DFConsts.BodyMode_ProjectNFTList:
    case DFConsts.BodyMode_Mint:
    case DFConsts.BodyMode_Mate:
    case DFConsts.BodyMode_UserNFTList:
      return (  
        <div>
          <Columns props={this.props.props} />
        </div>
      );
    default:
      throw new Error('Body.render - unknown ColumnCenterMode: ' + this.props.props.BodyMode);
    }
  }
}

export default Body;
