import React from 'react';
import '../../styles/App.css';
import * as IState from '../../classes/IState'
import * as DFConsts from '../DFConsts'

export class ColumnRight extends React.Component<IState.IState, IState.IState> {
  constructor (props: IState.IState) {
    super(props);
    DFConsts.gConsoleLog('ColumnRight.constructor - PublicKey: ' + props.props.PublicKey);
  }

render() {
    return (  
      <div className="ColumnRight">
      <p>
      </p>
      <div className="user-container"> 
      </div>
    </div>
    );
  }
}

export default ColumnRight;
