import React from 'react';
import '../../styles/App.css';
import ColumnCenter from './ColumnCenter';
import ColumnRight from './ColumnRight';
import * as IState from '../../classes/IState'
import * as DFConsts from '../DFConsts'

export class Columns extends React.Component<IState.IState, IState.IState> {
  constructor (props: IState.IState) {
    super(props);
    DFConsts.gConsoleLog('Columns.constructor - PublicKey: ' + props.props.PublicKey);
  }

render() {
    return (  
      <div style={{ display: "grid", width: '100%', gridTemplateColumns: "1fr 8fr 1fr", gridGap: 0 }}>
        <div/>
        <ColumnCenter props={this.props.props} />
        <ColumnRight props={this.props.props} />
      </div>
      );
  }
}

export default Columns;
