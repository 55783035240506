import * as React from "react";
import * as INFTItem from '../../classes/NFTItemOps/INFTItem'
import * as IMergeMintingOps from '../../classes/MergeMinting/IMergeMintingOps'
import * as DFConsts from '../DFConsts';
import * as IState from "../../classes/IState";
import * as Button from "../_CustomComponents/CustomButtonComponent";
import * as IGenerativAPIError from '../../classes/Security/IGenerativAPIError'
import * as IAuthorizeDerivedKey from '../../classes/Security/IAuthorizeDerivedKey'
import Deso from 'deso-protocol';
import 'deso-protocol';
import { IdentityDeriveParams, DerivedPrivateUserInfo } from 'deso-protocol-types';
import { TransactionSpendingLimitResponse, TransactionType, NFTOperationLimitMap, NFTLimitOperationString, } from 'deso-protocol-types';

export class NFTMergeMintItemData extends React.Component<INFTItem.INFTMintMergeSingleItemProp, IState.IState> {
  constructor (props: INFTItem.INFTMintMergeSingleItemProp) {
    super(props);
    DFConsts.gConsoleLog('NFTMergeMintItemData.constructor - item PostHashHex: ' + props.key);
  }

  shouldComponentUpdate(nextProps: INFTItem.INFTMintMergeSingleItemProp, nextState: IState.IState)
  {
    // https://www.freecodecamp.org/news/how-to-identify-and-resolve-wasted-renders-in-react-cc4b1e910d10/
    const PropsChanged: boolean = this.props !== nextProps;
    const StateChanged: boolean = this.state !== nextState;
    const InfoMessageChanged: boolean = this.props.itemAsProp.state.props.InfoMessage !== nextProps.itemAsProp.state.props.InfoMessage;
    DFConsts.gConsoleLog('NFTMergeMintItemData.shouldComponentUpdate - PropsChanged: ' + PropsChanged + ', StateChanged: ' + StateChanged + ', InfoMessageChanged: ' + InfoMessageChanged);
    return PropsChanged || StateChanged || InfoMessageChanged;
  }

  selectNFT(PostHashHex: string) {
    DFConsts.gConsoleLog('NFTMergeMintItemData.selectNFT, PostHashHex: ' + PostHashHex);

    const MintMergeNFTSelected: IState.IState = DFConsts.CloneState(this.props.itemAsProp.state);

    switch(this.props.itemAsProp.mode)
    {
      case 'left':
        if (MintMergeNFTSelected.props.NFTMergeMintDictAllForUser_Left !== undefined)
        {
          if (DFConsts.IsPostHashHexValid(MintMergeNFTSelected.props.NFTMergeMintDictAllForUser_Left.selected))
          {
            MintMergeNFTSelected.props.NFTMergeMintDictAllForUser_Left.selected = undefined;
            MintMergeNFTSelected.props.NFTMergeMintBestMatchesList = undefined;
          }
          else
          {
            MintMergeNFTSelected.props.NFTMergeMintDictAllForUser_Left.selected = PostHashHex;
          }
        }
        break;
      case 'right':
        if (MintMergeNFTSelected.props.NFTMergeMintDictAllForUser_Right !== undefined)  
        {
          if (DFConsts.IsPostHashHexValid(MintMergeNFTSelected.props.NFTMergeMintDictAllForUser_Right.selected))
          {
            MintMergeNFTSelected.props.NFTMergeMintDictAllForUser_Right.selected = undefined;
            MintMergeNFTSelected.props.NFTMergeMintBestMatchesList = undefined;
          }
          else
          {
            MintMergeNFTSelected.props.NFTMergeMintDictAllForUser_Right.selected = PostHashHex;
          }
        }
        break;
      default:
        throw new Error('NFTMergeMintItemData.selectNFT - invalid mode: ' + this.props.itemAsProp.mode);
    }

    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(MintMergeNFTSelected) }));
  }

  confirmMergeMinting = async (buttonName: string) => {
    DFConsts.gConsoleLog('NFTMergeMintItemData.confirmMergeMinting');

    const RetAuthBrowser: boolean = await IAuthorizeDerivedKey.authorizeDerivedKey_AuthorizeBrowser_IfNeeded(this.props.itemAsProp.state);
    if (RetAuthBrowser === false) { return; }

    DFConsts.gConsoleLog('NFTMergeMintItemData.confirmMergeMinting - authorizing derived key for transferring of source NFTs, from PK: ' + this.props.itemAsProp.state.props.PublicKey);

    DFConsts.showBusyIndicator(this.props.itemAsProp.state); 

    //const BasicTransferTransactionsNumber: number = 1; // TODO; will be needed when introducing paid merge mints - and will depend on mint process type (+Generativ royalties if applicable)
    const NFTTransferTransactionsNumber: number = 2; // TODO; making one time transfer of source NFTs (assumes 2 source NFTs for a merge mint!)
    const NFTBidTransactionsNumber: number = 1;  // TODO; will be needed when introducing paid merge mints - and will depend on mint process type (+Generativ royalties if applicable) -> needed already for bid/accept process, as I assume 1 nano there...

    // TODO add merge mint price here
    const GlobalDESOLimit: number = Math.max((((IAuthorizeDerivedKey.AuthorizeDerivedKeyTransactionsNumber_MinRequired + NFTTransferTransactionsNumber) * DFConsts.DerivedKeySigningMinFeeRateNanosPerKB)), IAuthorizeDerivedKey.MinGlobalDESOLimitNanos_VisibleInIdentity);

    const requestNFTOperationLimitMap: NFTOperationLimitMap = {      
      '': { // only unknown PostHashHex - we will be minting them, unknown at start
        0: { // serial number one - we only allow minting 1/1 NFTs
          [NFTLimitOperationString.TRANSFER]: Math.round(NFTTransferTransactionsNumber),
          [NFTLimitOperationString.BID]: Math.round(NFTBidTransactionsNumber),
        }
      }      
    };
    const requestPartTransactionSpendingLimit: TransactionSpendingLimitResponse = {
      GlobalDESOLimit: Math.round(GlobalDESOLimit),
      TransactionCountLimitMap: {
        [TransactionType.AuthorizeDerivedKey]: Math.round(IAuthorizeDerivedKey.AuthorizeDerivedKeyTransactionsNumber_MinRequired),
        //[TransactionType.BasicTransfer]: Math.round(BasicTransferTransactionsNumber), // TODO see above
        [TransactionType.NFTTransfer]: Math.round(NFTTransferTransactionsNumber),
        [TransactionType.NFTBid]: Math.round(NFTBidTransactionsNumber),
      },
      CreatorCoinOperationLimitMap: {},
      DAOCoinOperationLimitMap: {},
      NFTOperationLimitMap: requestNFTOperationLimitMap,
      DAOCoinLimitOrderLimitMap: {},
    };
    const requestDerivedKey:IdentityDeriveParams = {
      callback: '',
      webview: undefined,
      publicKey: this.props.itemAsProp.state.props.PublicKey,
      transactionSpendingLimitResponse: requestPartTransactionSpendingLimit,
      derivedPublicKey: '',    
    };
    const deso = new Deso();
    const jsonResponseDerive: DerivedPrivateUserInfo = await deso.identity.derive(requestDerivedKey);

    DFConsts.gConsoleLog('NFTMergeMintItemData.confirmMergeMinting - identity derive success, need to authorize in backend');

    const requestJson: IMergeMintingOps.IMergeMintConfirmMergeMintingRequest = {
      PosthHashHexLeft: this.props.itemAsProp.state.props.NFTMergeMintDictAllForUser_Left?.selected!,
      PosthHashHexRight: this.props.itemAsProp.state.props.NFTMergeMintDictAllForUser_Right?.selected!,
      IdentityDeriveRet: jsonResponseDerive,

      UpdaterPublicKey: this.props.itemAsProp.state.props.PublicKey!, 
      SeriesUuid: this.props.itemAsProp.state.props.NFTListSeriesSelected?.uuid!, 
      SessionKey: DFConsts.GetUserSessionKey(this.props.itemAsProp.state.props.PublicKey!)!,
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', accept: 'application/json', }, 
      RequestMode: 'cors',
      body: JSON.stringify(requestJson),
    } as RequestInit;

    fetch(DFConsts.GenerativAPIURL_MergeMintConfirmMergeMinting, requestOptions)
    .then(response => {
      if (response.status === 200) { return response.json() }
      DFConsts.gConsoleLog('NFTMergeMintItemData.confirmMergeMinting - response.status: ' + response.status);
    })
    .then(jsonData  => {
      const ResponseJson: INFTItem.INFTItem = jsonData;

      if (IGenerativAPIError.HandleGenerativAPIFail('Confirming merge minting failed - please try again in a few seconds.', this.props.itemAsProp.state, ResponseJson)) { return; };

      DFConsts.gConsoleLog('NFTMergeMintItemData.confirmMergeMinting - MergeMintConfirmMergeMinting success.');

      if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = false; }

      const NFTMergeMintingProcessStartedState: IState.IState = DFConsts.CloneState(this.props.itemAsProp.state);
      NFTMergeMintingProcessStartedState.props.BodyMode = DFConsts.BodyMode_Mint;
      NFTMergeMintingProcessStartedState.props.NFTItemGridDisplayList = undefined;
      NFTMergeMintingProcessStartedState.props.InfoMessage = undefined;
      NFTMergeMintingProcessStartedState.props.NFTItemInProcessing = JSON.parse(JSON.stringify(ResponseJson));
      NFTMergeMintingProcessStartedState.props.NFTMergeMintDictAllForUser_Left = undefined;
      NFTMergeMintingProcessStartedState.props.NFTMergeMintDictAllForUser_Right = undefined;
      NFTMergeMintingProcessStartedState.props.NFTMergeMintDictAllForUser_Merged = undefined;
      NFTMergeMintingProcessStartedState.props.ShowBusyIndicator = false;
      window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(NFTMergeMintingProcessStartedState) }));
    })
    .catch(err => {
      DFConsts.gConsoleLog('NFTMergeMintItemData.confirmMergeMinting - error: ' + err);
      if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = false; }
      if (IGenerativAPIError.HandleGenerativAPIFail('Confirming merge minting failed - please try again in a few seconds.', this.props.itemAsProp.state, null)) { return; };
    });
  };

  narrowViewTest(): JSX.Element {

    let ImgSrc: string = '';
    if (this.props.itemAsProp.item.PostEntryResponse?.ImageURLs !== undefined && this.props.itemAsProp.item.PostEntryResponse?.ImageURLs !== null)
    {
      ImgSrc = this.props.itemAsProp.item.PostEntryResponse?.ImageURLs[0];
    }

    const TdImgToMergeItems: JSX.Element = (
      <td className="TraitsTableMintMergeImage" align="center" colSpan={3}>
        <img height="80" src={ImgSrc} alt={this.props.key} />
      </td>
    );
    const TdEditionAndCollectionToMergeItems: JSX.Element = (
      <td className="TraitsTableMintMergeTable" align="center" colSpan={3}>
        <b>#{this.props.itemAsProp.item.PostEntryResponse?.PostExtraData['Edition']}</b> (
          {
            /*this.props.itemAsProp.item.PostEntryResponse?.PostExtraData['Collection Name'] !== undefined ?
              this.props.itemAsProp.item.PostEntryResponse?.PostExtraData['Collection Name']
            :*/ this.props.itemAsProp.item.PostEntryResponse?.PostExtraData['SeriesName']
          }
          )
        { this.props.itemAsProp.isSelected === true && ' ✅'}
      </td>
    );
    const TrSex: JSX.Element = (
      <tr className="TraitsTableMintMergeData">
        <td className="TraitsTableMintMergeData" align="center" colSpan={3}>
          <b>Sex: {this.props.itemAsProp.traits?.nftItemTraits.find(x => x.traitName === 'Sex')?.traitValue }</b>
        </td>
      </tr>
    );
    const TrTitleRow: JSX.Element | boolean = (
      this.props.itemAsProp.isSelected === true &&
      <tr className="TraitsTableMintMergeData">
        <td className="TraitsTableMintMergeData" align="center"><b>Trait name</b></td>
        <td className="TraitsTableMintMergeData" align="center"><b>Trait value</b></td>
        <td className="TraitsTableMintMergeData" align="center"><b>Probability</b></td>
      </tr>
    );
    const TrTraitRows: JSX.Element[] | boolean = (
      this.props.itemAsProp.isSelected === true && this.props.itemAsProp.traits !== undefined &&
      this.props.itemAsProp.traits.nftItemTraits.map((key) =>
        <tr className="TraitsTableMintMergeData" key={key.traitName}>
          <td className="TraitsTableMintMergeData">{key.traitName}</td>
          <td className="TraitsTableMintMergeData">{key.traitValue}</td>
          <td className="TraitsTableMintMergeData" align="right">{new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(key.traitValueProbabilityPercent)} %</td>
        </tr>
      )
    );
    const TrRarityScore: JSX.Element = (
      <tr className="TraitsTableMintMergeData">
      <td className="TraitsTableMintMergeData" colSpan={3} align="center" ><b>Rarity score: {new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 4}).format(this.props.itemAsProp.traits?.rarityIndex || 0)}</b></td>
    </tr>
    );

    switch(this.props.itemAsProp.mode)
    {
      case 'left':
        return (  
          <div className="ImageMintMergeItemLeft" onClick={() => this.selectNFT(this.props.itemAsProp.item.PostEntryResponse?.PostHashHex || '')}>
            <table className="TraitsTableMintMergeTable" >
              <tbody className="TraitsTableMintMergeData" >
                <tr className="TraitsTableMintMergeData">
                  {TdImgToMergeItems}
                </tr>
                <tr className="TraitsTableMintMergeData">
                  {TdEditionAndCollectionToMergeItems}
                </tr>
                {TrSex}
                {TrTitleRow}
                {TrTraitRows}
                {TrRarityScore}
              </tbody>
            </table>
            <br/>
          </div>
        );
      case 'right':
        return (  
          <div className="ImageMintMergeItemRight" onClick={() => this.selectNFT(this.props.itemAsProp.item.PostEntryResponse?.PostHashHex || '')} >
            <table className="TraitsTableMintMergeTable" >
              <tbody className="TraitsTableMintMergeData" >
                <tr className="TraitsTableMintMergeData">
                  {TdImgToMergeItems}
                </tr>
                <tr className="TraitsTableMintMergeData">
                  {TdEditionAndCollectionToMergeItems}
                </tr>
                {TrSex}
                {TrTitleRow}
                {TrTraitRows}
                {TrRarityScore}
              </tbody>
            </table>
            <br/>
          </div>
        );
      case 'merged':
        return (
          <div className="ImageMintMergeItemLeft" >
            <table className="TraitsTableMintMergeTable" >
              <tbody className="TraitsTableMintMergeData" >
                <tr className="TraitsTableMintMergeData">
                  <td className="TraitsTableMintMergeImage" align="center" colSpan={3}>
                    <img width="200" src={DFConsts.GenerativAPIURL_MergeMintGetMergedProposal + '?PosthHashHexLeft=' + this.props.itemAsProp.state.props.NFTMergeMintDictAllForUser_Left?.selected + 
                      '&PosthHashHexRight=' + this.props.itemAsProp.state.props.NFTMergeMintDictAllForUser_Right?.selected + '&SeriesUuid=' + this.props.itemAsProp.state.props.NFTListSeriesSelected?.uuid} alt={this.props.key} /> 
                  </td>
                </tr>
                <tr className="TraitsTableMintMergeData">
                  <td className="TraitsTableMintMergeTable" align="center" colSpan={3}>
                    <b>{this.props.itemAsProp.state.props.NFTListSeriesSelected?.name/* TODO this is in-series merge - need to parametrize if it's multi generation merge */}</b>
                  </td>
                </tr>
                {TrSex}
                {TrTitleRow}
                {TrTraitRows}
                {TrRarityScore}
              </tbody>
            </table>
            <br/>
            <section className="MergeMintNotesList">
            <b>Notes - please read:</b>
            <ul>
              <li>Rarity score of merged NFT assumes worst case scenario for "random" sex trait. Keep your fingers crossed for favourable randomness when you confirm!</li> {/* TODO parametrize for random traits; sex is OctoPosse specific */}
              <li>We will wait for your transfers for 10 minutes; during this time you won't be able to use chosen NFTs for other merges.</li>
              <li>After the time is up, and we don't receive transfers from you - your merge-mint order will be cancelled and your NFTs will return to available pool.</li>
            </ul>
            </section>
            <br/>
            <Button.Button id="confirm_mergemint_nft_minting_button"  
              onClick={() => {
                (document.getElementById('confirm_mergemint_nft_minting_button') as HTMLButtonElement).disabled = true; 
                this.confirmMergeMinting('confirm_mergemint_nft_minting_button'); 
              }} children = "Confirm Merged NFT For Minting" />
          </div>
        );
      default:
        throw new Error('NFTMergeMintItemData.render - invalid mode: ' + this.props.itemAsProp.mode);
    }
  }

  render() {
    DFConsts.gConsoleLog('NFTMergeMintItemData.render');

    if (window.innerWidth < 800)
    {
      return this.narrowViewTest(); // TODO - merge into one (most probably)
    }

    let ImgSrc: string = '';
    if (this.props.itemAsProp.item.PostEntryResponse?.ImageURLs !== undefined && this.props.itemAsProp.item.PostEntryResponse?.ImageURLs !== null)
    {
      ImgSrc = this.props.itemAsProp.item.PostEntryResponse?.ImageURLs[0];
    }

    const TdImgToMergeItems: JSX.Element = (
      <td className="TraitsTableMintMergeImage" align="center" rowSpan={this.props.itemAsProp.isSelected === true ? (this.props.itemAsProp.traits !== undefined ? this.props.itemAsProp.traits.nftItemTraits.length + 4 : 3) : 3 }>
        <img height="80" src={ImgSrc} alt={this.props.key} />
      </td>
    );
    const TdEditionAndCollectionToMergeItems: JSX.Element = (
      <td className="TraitsTableMintMergeTable" align="center" colSpan={3}>
        <b>#{this.props.itemAsProp.item.PostEntryResponse?.PostExtraData['Edition']}</b> (
          {
            /*this.props.itemAsProp.item.PostEntryResponse?.PostExtraData['Collection Name'] !== undefined ?
              this.props.itemAsProp.item.PostEntryResponse?.PostExtraData['Collection Name']
            :*/ this.props.itemAsProp.item.PostEntryResponse?.PostExtraData['SeriesName']
          }
          )
        { this.props.itemAsProp.isSelected === true && ' ✅'}
      </td>
    );
    const TrSex: JSX.Element = (
      <tr className="TraitsTableMintMergeData">
        <td className="TraitsTableMintMergeData" align="center" colSpan={3}>
          <b>Sex: {this.props.itemAsProp.traits?.nftItemTraits.find(x => x.traitName === 'Sex')?.traitValue }</b>
        </td>
      </tr>
    );
    const TrTitleRow: JSX.Element | boolean = (
      this.props.itemAsProp.isSelected === true &&
      <tr className="TraitsTableMintMergeData">
        <td className="TraitsTableMintMergeData" align="center"><b>Trait name</b></td>
        <td className="TraitsTableMintMergeData" align="center"><b>Trait value</b></td>
        <td className="TraitsTableMintMergeData" align="center"><b>Probability</b></td>
      </tr>
    );
    const TrTraitRows: JSX.Element[] | boolean = (
      this.props.itemAsProp.isSelected === true && this.props.itemAsProp.traits !== undefined &&
      this.props.itemAsProp.traits.nftItemTraits.map((key) =>
        <tr className="TraitsTableMintMergeData" key={key.traitName}>
          <td className="TraitsTableMintMergeData">{key.traitName}</td>
          <td className="TraitsTableMintMergeData">{key.traitValue}</td>
          <td className="TraitsTableMintMergeData" align="right">{new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(key.traitValueProbabilityPercent)} %</td>
        </tr>
      )
    );
    const TrRarityScore: JSX.Element = (
      <tr className="TraitsTableMintMergeData">
      <td className="TraitsTableMintMergeData" colSpan={3} align="center" ><b>Rarity score: {new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 4}).format(this.props.itemAsProp.traits?.rarityIndex || 0)}</b></td>
    </tr>
    );

    switch(this.props.itemAsProp.mode)
    {
      case 'left':
        return (  
          <div className="ImageMintMergeItemLeft" onClick={() => this.selectNFT(this.props.itemAsProp.item.PostEntryResponse?.PostHashHex || '')}>
            <table className="TraitsTableMintMergeTable" >
              <tbody className="TraitsTableMintMergeData" >
                <tr className="TraitsTableMintMergeData">
                  {TdEditionAndCollectionToMergeItems}
                  {TdImgToMergeItems}
                </tr>
                {TrSex}
                {TrTitleRow}
                {TrTraitRows}
                {TrRarityScore}
              </tbody>
            </table>
            <br/>
          </div>
        );
      case 'right':
        return (  
          <div className="ImageMintMergeItemRight" onClick={() => this.selectNFT(this.props.itemAsProp.item.PostEntryResponse?.PostHashHex || '')} >
            <table className="TraitsTableMintMergeTable" >
              <tbody className="TraitsTableMintMergeData" >
                <tr className="TraitsTableMintMergeData">
                  {TdImgToMergeItems}
                  {TdEditionAndCollectionToMergeItems}
                </tr>
                {TrSex}
                {TrTitleRow}
                {TrTraitRows}
                {TrRarityScore}
              </tbody>
            </table>
            <br/>
          </div>
        );
      case 'merged':
        return (
          <div className="ImageMintMergeItemLeft" >
            <table className="TraitsTableMintMergeTable" >
              <tbody className="TraitsTableMintMergeData" >
                <tr className="TraitsTableMintMergeData">
                  <td className="TraitsTableMintMergeTable" align="center" colSpan={3}>
                    <b>{this.props.itemAsProp.state.props.NFTListSeriesSelected?.name/* TODO this is in-series merge - need to parametrize if it's multi generation merge */}</b>
                  </td>
                  <td className="TraitsTableMintMergeImage" align="center" rowSpan={ this.props.itemAsProp.traits !== undefined ? this.props.itemAsProp.traits?.nftItemTraits.length + 4 : 1 }>
                    <img width="200" src={DFConsts.GenerativAPIURL_MergeMintGetMergedProposal + '?PosthHashHexLeft=' + this.props.itemAsProp.state.props.NFTMergeMintDictAllForUser_Left?.selected + 
                      '&PosthHashHexRight=' + this.props.itemAsProp.state.props.NFTMergeMintDictAllForUser_Right?.selected + '&SeriesUuid=' + this.props.itemAsProp.state.props.NFTListSeriesSelected?.uuid} alt={this.props.key} /> 
                  </td>
                </tr>
                {TrSex}
                {TrTitleRow}
                {TrTraitRows}
                {TrRarityScore}
              </tbody>
            </table>
            <br/>
            <section className="MergeMintNotesList">
            <b>Notes - please read:</b>
            <ul>
              <li>Rarity score of merged NFT assumes worst case scenario for "random" sex trait. Keep your fingers crossed for favourable randomness when you confirm!</li> {/* TODO parametrize for random traits; sex is OctoPosse specific */}
              <li>We will wait for your transfers for 10 minutes; during this time you won't be able to use chosen NFTs for other merges.</li>
              <li>After the time is up, and we don't receive transfers from you - your merge-mint order will be cancelled and your NFTs will return to available pool.</li>
            </ul>
            </section>
            <br/>
            <Button.Button id="confirm_mergemint_nft_minting_button"  
              onClick={() => {
                (document.getElementById('confirm_mergemint_nft_minting_button') as HTMLButtonElement).disabled = true; 
                this.confirmMergeMinting('confirm_mergemint_nft_minting_button'); 
              }} children = "Confirm Merged NFT For Minting" />
          </div>
        );
      default:
        throw new Error('NFTMergeMintItemData.render - invalid mode: ' + this.props.itemAsProp.mode);
    }
  }
}