import React from 'react';
import '../../styles/App.css';
import * as Button from "../_CustomComponents/CustomButtonComponent";
import * as InputText from "../_CustomComponents/CustomInputText";
import * as IState from '../../classes/IState'
import * as DFConsts from '../DFConsts'
import Modal from 'react-modal';
import * as ISeries from '../../classes/ProjectMgmt/ISeries'
import * as IGenerativAPIError from '../../classes/Security/IGenerativAPIError'

export interface UpdateSeriesModalArgs {
  itemAsProp: {
    state: IState.IState;
    addButtonWidth: string;
  },
}

export function UpdateSeriesModal(props: UpdateSeriesModalArgs): JSX.Element {

// https://github.com/reactjs/react-modal
// https://stackoverflow.com/questions/48269381/warning-react-modal-app-element-is-not-defined-please-use-modal-setappeleme

// npm install --save react-modal
// npm i --save-dev @types/react-modal

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      background: 'var(--modal-windows-background-color)',
    },
  };

  let subtitle:HTMLHeadingElement | null;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle!.style.color = '#ffffff';
  }

  function closeModal() {
    setIsOpen(false);
  }

  async function updateSeriesFromForm() {
    DFConsts.gConsoleLog('UpdateSeriesModal.updateSeries');

    const UpdatedDescription: string = (document.getElementById('update_series_description_textarea') as HTMLTextAreaElement).value;
    const UpdatedTopPageBelowMintButtonBanner: string = (document.getElementById('inmodal_updateseries_toppage_undermintbutton_banner') as HTMLTextAreaElement).value;
    const UpdatedSeriesSeriesPageComponents: ISeries.ISeriesPageComponents = { topPageBelowMintButtonBanner: UpdatedTopPageBelowMintButtonBanner};
    const IsMintingAllowed: boolean = (document.getElementById("inmodal_updateseries_is_minting_allowed") as HTMLInputElement).checked;

    if (UpdatedDescription === undefined || UpdatedDescription === null || UpdatedDescription.length === 0)
    {
      DFConsts.gConsoleLog('UpdateSeriesModal.updateSeries, new description invalid');
      return;
    }

    let formData: FormData = new FormData();
      formData.append("NewSeriesDescription", UpdatedDescription);
      formData.append("NewSeriesPageComponents", JSON.stringify(UpdatedSeriesSeriesPageComponents));
      formData.append("IsMintingAllowed", String(IsMintingAllowed));
      formData.append("UpdaterPublicKey", props.itemAsProp.state.props.PublicKey!);
      formData.append("SeriesUuid", props.itemAsProp.state.props.NFTListSeriesSelected!.uuid);
      formData.append("SessionKey", DFConsts.GetCreatorInSeriesSessionKey(props.itemAsProp.state.props.PublicKey!, props.itemAsProp.state.props.NFTListSeriesSelected!.uuid)!);
      if (fileSelected)
      {
        formData.append("SeriesBanner", fileSelected, fileSelected.name);
      }

    const requestOptions = {
      method: 'POST',
      headers: { accept: 'application/json', }, // un-fucking-believable: https://stackoverflow.com/questions/39280438/fetch-missing-boundary-in-multipart-form-data-post
      RequestMode: 'cors',
      body: formData,
    } as RequestInit;

    fetch(DFConsts.GenerativAPIURL_SeriesMgmt, requestOptions)
      .then(response => {
        if (response.status === 200) { return response.json(); }
        DFConsts.gConsoleLog('UpdateSeriesModal.updateSeries - response.status: ' + response.status);
      })
      .then(jsonData  => {
        const ResponseJson: ISeries.ISeriesMgmtResponse = jsonData;

        if (IGenerativAPIError.HandleGenerativAPIFail('Updating Series failed.', props.itemAsProp.state, ResponseJson)) 
        {
          (document.getElementById('inmodal_updateseries_infomessage_label') as HTMLLabelElement).textContent = 'Update Series Error: ' + ResponseJson.error;
           return;
        };

        (document.getElementById('inmodal_updateseries_infomessage_label') as HTMLLabelElement).textContent = 'Updated Series: ' + ResponseJson.updatedSeries.name;

        const UpdatedState: IState.IState = DFConsts.CloneState(props.itemAsProp.state);
        UpdatedState.props.NFTListSeriesSelected = ResponseJson.updatedSeries;
        window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
      })
      .catch(err => {
        DFConsts.gConsoleLog('UpdateSeriesModal.updateSeries error: ' + err);
        if (IGenerativAPIError.HandleGenerativAPIFail('Updating Series failed.', props.itemAsProp.state, null)) 
        {
          (document.getElementById('inmodal_updateseries_infomessage_label') as HTMLLabelElement).textContent = 'Update Series Error: ' + err;
          return;
        };
      });
  }

const [fileSelected, setFileSelected] = React.useState<File>() // also tried <string | Blob>

const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (!fileList) return;
    
    setFileSelected(fileList[0]);
  };

  return (
    <div className="user-container-internal"> 
      <Button.Button id="update_series_open_modal_button" onClick={(event) => { openModal(); }} width = {props.itemAsProp.addButtonWidth} children = "Update Series" />

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Update Series Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Update Series</h2>
        <div>Description: </div>

        <div>
          <textarea id="update_series_description_textarea" maxLength={5000} defaultValue={props.itemAsProp.state.props.NFTListSeriesSelected?.description} 
            disabled={false} 
            style={{textAlign:'left', fontSize:'calc(4px + 1vmin)', color:'#000000', border:"1px solid", borderColor:"#eeeeee", height:"calc(10px + 1vmin)", borderRadius:'1%', minHeight:'100px', minWidth:'500px', }} />
          <br/><br/>
        </div>
        <div>
          <label  id="inmodal_updateseries_choosefile_label" >Series Banner image: </label>
          <input accept="image/*" style={{  }} id="photo" name="photo" type="file" multiple={false} onChange={handleImageChange} />
        </div>
        <InputText.InputText id="inmodal_updateseries_toppage_undermintbutton_banner" size={65} maxLength={60} 
          defaultValue={props.itemAsProp.state.props.NFTListSeriesSelected?.seriesPageComponents.topPageBelowMintButtonBanner!}
          border="1px solid" color="#eeeeee" height = "calc(10px + 1vmin)" 
          hidden={false}
          onClick={() => {}} onChange={() => {}} width = "" labelText="Banner below mint button:" labelFontSize="calc(7px + 1vmin)"/>
        <div>
          <label  id="inmodal_updateseries_is_minting_allowed_label" >Is Minting Allowed: </label>
          <input type="checkbox" id="inmodal_updateseries_is_minting_allowed" onChange={() => {}} 
            defaultChecked={props.itemAsProp.state.props.NFTListSeriesSelected?.isMintingAllowed} 
            disabled={false} 
            onFocus={() => { }}
          />
        </div>

        <br/>
        <label  id="inmodal_updateseries_infomessage_label" ></label>
        <br/><br/>
        <Button.Button id="update_series_exec_button" 
            onClick={() => { updateSeriesFromForm(); }} children = "Update Series" />
        &nbsp;&nbsp;
        <Button.Button id="update_series_close_button" 
            onClick={() => { closeModal(); DFConsts.hideSlidingLeftMenuBar(props.itemAsProp.state); }} children = "Close" />
      </Modal>
    </div>
  );
}
