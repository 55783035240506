import React from 'react';
import '../../styles/App.css';
import * as Button from "../_CustomComponents/CustomButtonComponent";
import * as IState from '../../classes/IState'
import * as IMintPass from '../../classes/MintPass/IMintPass'
import * as DFConsts from '../DFConsts'
import Modal from 'react-modal';
import * as MintPassAddModal from './MintPassAddModal'
import * as IGenerativAPIError from '../../classes/Security/IGenerativAPIError'

export class MintPassListModal extends React.Component<IState.IState, IState.IState> {
  constructor (props: IState.IState) {
    super(props);
    DFConsts.gConsoleLog('MintPassListModal.constructor - PublicKey: ' + props.props.PublicKey);
  }

  shouldComponentUpdate(nextProps: IState.IState, nextState: IState.IState)
  {
    // https://www.freecodecamp.org/news/how-to-identify-and-resolve-wasted-renders-in-react-cc4b1e910d10/
    const PropsChanged: boolean = this.props !== nextProps;
    const StateChanged: boolean = this.state !== nextState;
    const MintPassListChanged: boolean = this.props.props.CreatorMintPassDisplayList !== nextProps.props.CreatorMintPassDisplayList;
    //DFConsts.gConsoleLog('MintPassListModal.shouldComponentUpdate - PropsChanged: ' + PropsChanged + ', StateChanged: ' + StateChanged + ', MintPassListChanged: ' + MintPassListChanged);
    return PropsChanged || StateChanged || MintPassListChanged;
  }
  
// https://github.com/reactjs/react-modal
// https://stackoverflow.com/questions/48269381/warning-react-modal-app-element-is-not-defined-please-use-modal-setappeleme

// npm install --save react-modal
// npm i --save-dev @types/react-modal

  customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: '10%',
      bottom: '-40%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      background: 'var(--modal-windows-background-color)',
    },
  };

  IsModalOpen: boolean = false;

  openModal() {
    this.IsModalOpen = true;
    const UpdatedState: IState.IState = DFConsts.CloneState(this.props);
    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
  }

  closeModal() {
    this.IsModalOpen = false;
    const UpdatedState: IState.IState = DFConsts.CloneState(this.props);
    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
  }

  componentDidMount() {
    DFConsts.gConsoleLog('MintPassListModal.getMintPassList');

    const requestJson: IMintPass.IGetMintPassesRequest = {
      isCreator: true, 
      projectUuid: this.props.props.NFTListProjectSelected?.uuid!, 
      getCancelled: true, 
      getUsed: true, 
      getAvailableForUse: true, 

      UpdaterPublicKey: this.props.props.NFTListSeriesSelected?.mintingPublicKey!, 
      SeriesUuid: this.props.props.NFTListSeriesSelected?.uuid!, 
      SessionKey: DFConsts.GetCreatorInSeriesSessionKey(this.props.props.PublicKey!, this.props.props.NFTListSeriesSelected!.uuid)!,
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', accept: 'application/json', }, 
      RequestMode: 'cors',
      body: JSON.stringify(requestJson),
    } as RequestInit;

    fetch(DFConsts.GenerativAPIURL_MintPassGetMintPasses, requestOptions)
      .then(response => {
        if (response.status === 200) { return response.json(); }
        DFConsts.gConsoleLog('MintPassListModal.getMintPassList - response.status: ' + response.status);
      })
      .then(jsonData  => {
        const ResponseJson: IMintPass.IGetMintPassesResponse = jsonData;

        if (IGenerativAPIError.HandleGenerativAPIFail('Getting MintPass list failed.', this.props, ResponseJson)) 
        {
          (document.getElementById('inmodal_mintpasslist_infomessage_label') as HTMLLabelElement).textContent = 'Getting MintPass list failed: ' + ResponseJson.error;
          return;
        }

        const MintPassListUpdatedState: IState.IState = DFConsts.CloneState(this.props);
        MintPassListUpdatedState.props.CreatorMintPassDisplayList = JSON.parse(JSON.stringify(ResponseJson.mintPassItemLst));
        window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(MintPassListUpdatedState) }));  
      })
      .catch(err => {
        DFConsts.gConsoleLog('MintPassListModal.getMintPassList error: ' + err);
        (document.getElementById('inmodal_mintpasslist_infomessage_label') as HTMLLabelElement).textContent = 'Getting MintPass list failed: ' + err;
      });
  }

  getMintPassListTried: boolean = false;

  render() {
    DFConsts.gConsoleLog('MintPassListModal.render, item count: ' + this.props.props.CreatorMintPassDisplayList?.length);

    const AddModalProps: MintPassAddModal.MintPassAddModalArgs = { itemAsProp: { state: this.props, addButtonWidth: "calc(140px + 4vmin)"}};

    return (
      <div className="user-container-internal" > 
        <Button.Button id="get_mint_pass_list_open_modal_button" onClick={(event) => { this.openModal(); }} width = "80%" children = "MintPass List" />

        <Modal
          isOpen={this.IsModalOpen}
          onRequestClose={() => this.closeModal()}
          style={this.customStyles}
          contentLabel="MintPass List Modal"
        >
          <h2>List of MintPasses you issued</h2>
          {
            <MintPassListTable itemAsProp={{items: this.props.props.CreatorMintPassDisplayList, label: 'MintPass Items List'}} />
          }
          <label  id="inmodal_mintpasslist_infomessage_label" ></label>
          <div className="user-container-leftaligned"> 
            <Button.Button id="list_mint_pass_refresh_button" 
                onClick={() => { this.componentDidMount(); }} children = "Refresh List" />
            &nbsp;&nbsp;
            <MintPassAddModal.MintPassAddModal {...AddModalProps} />
            &nbsp;&nbsp;
            <Button.Button id="list_mint_pass_close_button" 
                onClick={() => { this.closeModal(); DFConsts.hideSlidingLeftMenuBar(this.props); }} children = "Close" />
          </div>
        </Modal>
      </div>
    );
  }
}

export class MintPassListTable extends React.Component<IMintPass.IMintPassItemsProp, IState.IState> {
    constructor (props: IMintPass.IMintPassItemsProp) {
      super(props);
      DFConsts.gConsoleLog('MintPassListTable.constructor - number of MintPass items: ' + (props.itemAsProp.items !== undefined ? props.itemAsProp.items.length : 'not refreshed'));
    }

  render() {
    DFConsts.gConsoleLog('MintPassListTable.render');

    return (
      <div>
        {
          this.props.itemAsProp.items !== undefined && this.props.itemAsProp.items.length > 0 && 
          <div>
            <label >MintPass list in this Series:</label>
            <br/><br/>
            <div className="user-container"> 
              <table className="MintPassTable" >
                <thead className="MintPassTable" >
                  <tr className="MintPassTable" >
                    <td className="MintPassTable" align="center" ><b>MintPass name</b></td>
                    <td className="MintPassTable" align="center" ><b>Created by</b></td>
                    <td className="MintPassTable" align="center" ><b>Owner</b></td>
                    <td className="MintPassTable" align="center" ><b>Status</b></td>
                    <td className="MintPassTable" align="center" ><b>Used for</b></td>
                    <td className="MintPassTable" align="center" ><b>MintPass uuid</b></td>
                  </tr>
                </thead>
                <tbody className="MintPassTable" >
                  {
                    this.props.itemAsProp.items.map((item, key) => 
                      <tr key={item.uuid} className="MintPassTable" >
                        <td className="MintPassTable" >{item.name}</td>
                        <td className="MintPassTable" >{DFConsts.getDeSocialWorldProfileLink(item.createdByUsername)}</td>
                        <td className="MintPassTable" >{DFConsts.getDeSocialWorldProfileLink(item.ownerUsername)}</td>
                        <td className="MintPassTable" >{item.isAvailableForUse ? 'available for use' : item.isUsed ? 'used' : item.isCancelled ? 'cancelled' : 'this should never be displayed :-)' }</td>
                        <td className="MintPassTable" >{item.isUsed ? item.usedForNFTPostHashHex !== undefined && item.usedForNFTPostHashHex !== null ? DFConsts.getDeSocialWorldNFTLink(item.usedForNFTPostHashHex, 'NFT serial number: ' + item.usedForNFTSerialNumber) : 'in progress' : 'not used'}</td>
                        <td className="MintPassTable" >{item.uuid}</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
            <br/>
          </div>
        }
        {
          this.props.itemAsProp.items === undefined ?
          'Please refresh MintPass list.'
          : <label >MintPass count: {this.props.itemAsProp.items.length}</label>
        }
        <br/><br/>
      </div>
    );
  }
}
