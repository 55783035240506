import * as INFTItem from './NFTItemOps/INFTItem'
import * as IProject from './ProjectMgmt/IProject'
import * as ISeries from './ProjectMgmt/ISeries'
import * as IFrontendTheme from './ProjectMgmt/IFrontendTheme'
import * as IMintPass from './MintPass/IMintPass'
import * as DFConsts from '../components/DFConsts';

export interface IState {
    props: {
        // GenerativAPI Metadata
        IsMetadataLoaded: boolean;
        DefaultFrontendTheme: IFrontendTheme.IFrontendTheme | undefined;

        // user data
        Username: string | undefined;
        PublicKey: string | undefined;
        DeSoBalance: number | undefined;
        IsLoggedIn: boolean;

        // navigation control
        BodyMode: string;
        IsLeftSlidingMenuBarVisible: boolean;

        // NFT project/series navigation
        NFTListProjectSelected: IProject.IProject | undefined;
        NFTListSeriesSelected: ISeries.ISeries | undefined;

        // NFT minting
        NFTItemInProcessing : INFTItem.INFTItem | undefined;

        // NFT display
        NFTItemGridDisplayList: INFTItem.INFTItem[] | undefined;
        ProjectDisplayList: IProject.IProject[] | undefined;

        // MintPass display
        CreatorMintPassDisplayList: IMintPass.IMintPass[] | undefined;
        UserMintPassDisplayList: IMintPass.IMintPass[] | undefined;

        // general utils
        InfoMessage: string | undefined;
        ShowBusyIndicator: boolean;

        // NFT merge mint
        NFTMergeMintDictAllForUser_Left: INFTItem.INFTMintMergeItemsProp | undefined;
        NFTMergeMintDictAllForUser_Right: INFTItem.INFTMintMergeItemsProp | undefined;
        NFTMergeMintDictAllForUser_Merged: INFTItem.INFTMintMergeItemsProp | undefined;
        NFTMergeMintBestMatchesList: INFTItem.INFTItem[] | undefined;

        // NFTItemData
        NFTItemDataBigImageToNotClean: string | undefined;
    }
}

export function getInitialState(): IState {
    const NewInitialState: IState = {
        props: {
            IsMetadataLoaded: true, // assume loaded, and clear on metadata fail; maybe better name would be IsInMaintenanceMode and reversed bools?
            DefaultFrontendTheme: undefined,

            Username: undefined,
            PublicKey: undefined,
            DeSoBalance: undefined,
            IsLoggedIn: false,
            
            BodyMode: DFConsts.BodyMode_Landing,
            IsLeftSlidingMenuBarVisible: false,
            
            NFTListProjectSelected: undefined,
            NFTListSeriesSelected: undefined,
        
            NFTItemInProcessing: undefined,

            NFTItemGridDisplayList: undefined,
            ProjectDisplayList: undefined,

            CreatorMintPassDisplayList: undefined,
            UserMintPassDisplayList: undefined,

            InfoMessage: undefined,
            ShowBusyIndicator: false,
            
            NFTMergeMintDictAllForUser_Left: undefined,
            NFTMergeMintDictAllForUser_Right: undefined,
            NFTMergeMintDictAllForUser_Merged: undefined,
            NFTMergeMintBestMatchesList: undefined,

            NFTItemDataBigImageToNotClean: undefined,
        }
    }

    return NewInitialState;
}

export function clearStateOnLoginStatusChange(stateToClean: IState): IState {
    const UpdatedState: IState = getInitialState();
    UpdatedState.props.IsMetadataLoaded = stateToClean.props.IsMetadataLoaded;
    UpdatedState.props.DefaultFrontendTheme = stateToClean.props.DefaultFrontendTheme;
    return UpdatedState;
}
