import * as IGenerativAPIError from './IGenerativAPIError'
import * as DFConsts from '../../components/DFConsts'
import * as IState from '../IState';
import Deso from 'deso-protocol';
import 'deso-protocol';
import { IdentityDeriveParams, DerivedPrivateUserInfo } from 'deso-protocol-types';
import { TransactionSpendingLimitResponse, TransactionType } from 'deso-protocol-types';

// ItsAditya: https://adityagamedev.hashnode.dev/derived-keys-of-desoprotocol-explained-with-proper-workflow

export interface IAuthorizeDerivedKeyRequest
{
    SeriesUuid: string;
    PublicKey: string;
    IdentityDeriveRet: DerivedPrivateUserInfo;
    AuthorizeMode: AuthorizeDerivedKeyMode;

    RequestSpecificParams: IRequestSpecificParams;
}
export interface IRequestSpecificParams
{
}
export interface IAuthorizeDerivedKeyResponse extends IGenerativAPIError.IGenerativAPIError
{
  publicKey: string;
}

export enum AuthorizeDerivedKeyMode
{
  UNDEFINED = 0,
  Creator = 10,
  User = 20,
  User_NewInitialNFT = 30,
  User_ExistingNFT = 40,
  User_SkipNFT = 50,
  User_ConfirmMergeMintingNFT = 60,
}

export const MinGlobalDESOLimitNanos_VisibleInIdentity: number = 10000000; // otherwise 0.00 visible in Authorize Derived Key windows
export const AuthorizeDerivedKeyTransactionsNumber_MinRequired: number = 2; // 2 is minimum required to confirm derived key authorization in backend

export async function authorizeDerivedKey_AuthorizeBrowser_IfNeeded(
  state: IState.IState,
  ): Promise<boolean> {

  DFConsts.gConsoleLog('authorizeDerivedKey_AuthorizeBrowser_IfNeeded for PK: ' + state.props.PublicKey);

  const initSession: string | null = DFConsts.GetUserSessionKey(state.props.PublicKey!);

  if (initSession === null || initSession.length === 0)
  {
    DFConsts.gConsoleLog('authorizeDerivedKey_AuthorizeBrowser_IfNeeded for PK: ' + state.props.PublicKey + ' - executing.');
    window.alert('We found this browser is not authorized to use Generativ - we need you to authorize your derived key to confirm your deso identity. It will not be used to perform operations on DeSo.');
    return await authorizeDerivedKey_AuthorizeBrowser(state);
  }      

  DFConsts.gConsoleLog('authorizeDerivedKey_AuthorizeBrowser_IfNeeded for PK: ' + state.props.PublicKey + ' - not needed.');

  return true;
}

export async function authorizeDerivedKey_AuthorizeBrowser(
  state: IState.IState,
  ): Promise<boolean> {

  DFConsts.gConsoleLog('authorizeDerivedKey_AuthorizeBrowser for PK: ' + state.props.PublicKey);

  const TransactionCountLimitMap: {[key: string]: number;} = {
    [TransactionType.AuthorizeDerivedKey]: AuthorizeDerivedKeyTransactionsNumber_MinRequired,
  };

  return await authorizeDerivedKeyCore(state, AuthorizeDerivedKeyMode.User, MinGlobalDESOLimitNanos_VisibleInIdentity, TransactionCountLimitMap, {});
}

export async function authorizeDerivedKey_Creator_ForMinting(
  state: IState.IState,
  mode: AuthorizeDerivedKeyMode,
  GlobalDESOLimit_Deso: number, 
  TransactionCountLimitMap: { [key: string]: number; }, 
  NFTOperationLimitMap: { [key: string]: {[key: number]: {[key: string]: number;};}; }, 
  RequestSpecificParams: IRequestSpecificParams = {},
  ): Promise<boolean> {

  DFConsts.gConsoleLog('authorizeDerivedKey_Creator_ForMinting for PK: ' + state.props.PublicKey);

  return await authorizeDerivedKeyCore(state, mode, GlobalDESOLimit_Deso * DFConsts.NanosInDeso, TransactionCountLimitMap, RequestSpecificParams, {}, {}, NFTOperationLimitMap);
}

async function authorizeDerivedKeyCore (
  state: IState.IState,
  mode: AuthorizeDerivedKeyMode,
  GlobalDESOLimit: number, 
  TransactionCountLimitMap: {[key: string]: number;},  
  RequestSpecificParamsIn: IRequestSpecificParams,
  CreatorCoinOperationLimitMap: {[key: string]: {[key: string]: number;};} = {}, 
  DAOCoinOperationLimitMap: {[key: string]: {[key: string]: number;};} = {}, 
  NFTOperationLimitMap: {[key: string]: {[key: number]: {[key: string]: number;};};} = {}, 
  DAOCoinLimitOrderLimitMap: {[key: string]: {[key: string]: number;};} = {},
  ) : Promise<boolean> {
        
  DFConsts.gConsoleLog('authorizeDerivedKeyCore for PK: ' + state.props.PublicKey);

  if (!DFConsts.IsPublicKeyValid(state.props.PublicKey))
  {
    DFConsts.gConsoleLog('authorizeDerivedKeyCore - invalid PK: ' + state.props.PublicKey);
    return false;
  }
  if (mode === AuthorizeDerivedKeyMode.UNDEFINED)
  {
    DFConsts.gConsoleLog('authorizeDerivedKeyCore - invalid mode: ' + mode);
    return false;
  }
  if (GlobalDESOLimit === 0)
  {
    DFConsts.gConsoleLog('authorizeDerivedKeyCore - GlobalDESOLimit iz zero: ' + GlobalDESOLimit);
    return false;
  }
  if (TransactionCountLimitMap[TransactionType.AuthorizeDerivedKey] === undefined || TransactionCountLimitMap[TransactionType.AuthorizeDerivedKey] === null || TransactionCountLimitMap[TransactionType.AuthorizeDerivedKey] < AuthorizeDerivedKeyTransactionsNumber_MinRequired)
  {
    DFConsts.gConsoleLog('authorizeDerivedKeyCore - invalid TransactionCountLimitMap, must include at least ' + AuthorizeDerivedKeyTransactionsNumber_MinRequired + ' for: ' + TransactionType.AuthorizeDerivedKey);
    return false;
  }

  const requestPartTransactionSpendingLimit: TransactionSpendingLimitResponse = {
    GlobalDESOLimit: GlobalDESOLimit,
    TransactionCountLimitMap: TransactionCountLimitMap,
    CreatorCoinOperationLimitMap: CreatorCoinOperationLimitMap,
    DAOCoinOperationLimitMap: DAOCoinOperationLimitMap,
    NFTOperationLimitMap: NFTOperationLimitMap,
    DAOCoinLimitOrderLimitMap: DAOCoinLimitOrderLimitMap,
    };
  const requestDerivedKey: IdentityDeriveParams = {
    callback: '',
    webview: undefined,
    publicKey: state.props.PublicKey,
    transactionSpendingLimitResponse: requestPartTransactionSpendingLimit,
    derivedPublicKey: '',    
    };
  const deso = new Deso();
  const jsonResponseDerive: DerivedPrivateUserInfo = await deso.identity.derive(requestDerivedKey);
  DFConsts.gConsoleLog('authorizeDerivedKeyCore - derive success');

  const RequestJsonDSWAPI: IAuthorizeDerivedKeyRequest =
  {
    SeriesUuid: state.props.NFTListSeriesSelected?.uuid!,
    PublicKey: state.props.PublicKey!,
    IdentityDeriveRet: jsonResponseDerive,
    AuthorizeMode: mode,

    RequestSpecificParams: RequestSpecificParamsIn,
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', accept: 'application/json', }, 
    RequestMode: 'cors', 
    body: JSON.stringify(RequestJsonDSWAPI),
    } as RequestInit;

  const response = await fetch(DFConsts.GenerativAPIURL_AuthorizeDerivedKey, requestOptions);
  if (response.status !== 200)
  {
    DFConsts.gConsoleLog('authorizeDerivedKeyCore - response.status: ' + response.status);

    const AuthorizeKeyFailed: IState.IState = DFConsts.CloneState(state);
    AuthorizeKeyFailed.props.InfoMessage = 'Failed to derive key, actual PK: ' + state.props.PublicKey + ', error: ' + response.status;
    AuthorizeKeyFailed.props.ShowBusyIndicator = false;
    AuthorizeKeyFailed.props.IsLeftSlidingMenuBarVisible = false;
    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(AuthorizeKeyFailed) }));

    return false;
  }
  const responseAuthorizeDerivedKeyJSONified: IAuthorizeDerivedKeyResponse = await response.json();

  if (!IGenerativAPIError.HasError(responseAuthorizeDerivedKeyJSONified))
  {
    DFConsts.gConsoleLog('authorizeDerivedKeyCore - AuthorizeDerivedKey success, actual/derived PK: ' + responseAuthorizeDerivedKeyJSONified.publicKey + ' / ' + jsonResponseDerive.derivedPublicKeyBase58Check);

    DFConsts.StoreUserSessionKey(jsonResponseDerive.derivedJwt, state.props.PublicKey!);

    const AuthorizeKeySuccess: IState.IState = DFConsts.CloneState(state);
    if(mode === AuthorizeDerivedKeyMode.Creator)
    {
      DFConsts.StoreCreatorInSeriesSessionKey(jsonResponseDerive.derivedJwt, state.props.PublicKey!, state.props.NFTListSeriesSelected!.uuid);
      AuthorizeKeySuccess.props.NFTListSeriesSelected!.mintingDerivedPublicKey = jsonResponseDerive.derivedPublicKeyBase58Check;
    }
    AuthorizeKeySuccess.props.InfoMessage = 'Successfully confirmed derived key.';
    AuthorizeKeySuccess.props.ShowBusyIndicator = false;
    AuthorizeKeySuccess.props.IsLeftSlidingMenuBarVisible = false;
    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(AuthorizeKeySuccess) }));

    return true;
  }
  else
  {
    DFConsts.gConsoleLog('authorizeDerivedKeyCore - AuthorizeDerivedKey failed, actual PK: ' + responseAuthorizeDerivedKeyJSONified.publicKey + ', error: ' + responseAuthorizeDerivedKeyJSONified.error);

    const AuthorizeKeyFailed: IState.IState = DFConsts.CloneState(state);
    AuthorizeKeyFailed.props.InfoMessage = 'Failed to confirm derived key.';
    AuthorizeKeyFailed.props.ShowBusyIndicator = false;
    AuthorizeKeyFailed.props.IsLeftSlidingMenuBarVisible = false;
    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(AuthorizeKeyFailed) }));

    return false;
  }
};
