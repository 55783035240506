import React from 'react';
import '../../styles/App.css';
import * as UserData from '../Security/UserData';
import Body from './Body';
import * as IState from '../../classes/IState'
import * as DFConsts from '../DFConsts'
import BusyIndicatorModal from '../_CustomComponents/BusyIndicatorModal';


// react app port changing: // https://stackoverflow.com/questions/40714583/how-to-specify-a-port-to-run-a-create-react-app-based-project

// https://www.smashingmagazine.com/2020/06/rest-api-react-fetch-axios/
// http://knowledge-cess.com/reactjs-server-side-rendering-calling-web-services-apis-from-server-side/

// https://stackoverflow.com/questions/58123398/when-to-use-jsx-element-vs-reactnode-vs-reactelement
// https://stackoverflow.com/questions/46987816/using-state-in-react-with-typescript
// https://dev.to/beccaliz/design-patterns-for-state-management-in-react-and-typescript-5da7
// https://reactjs.org/tutorial/tutorial.html

// azure deploy
// https://stackoverflow.com/questions/61582501/deploy-create-react-app-on-azure-app-services
// https://websitebeaver.com/deploy-create-react-app-to-azure-app-services
// https://stackoverflow.com/questions/69892360/how-to-deploy-react-to-azure-web-app-using-the-azure-app-service-plugin-in-visua
// https://medium.com/geekculture/easiest-way-to-host-your-react-app-azure-vs-code-8046f9f7fb0b

// identity - no approve problem: https://hub.deso.org/#/identity/identity-login

// azure DNS mapping: https://docs.microsoft.com/en-us/Azure/app-service/app-service-web-tutorial-custom-domain?tabs=a%2Cazurecli
// cert: https://github.com/shibayan/appservice-acmebot, https://docs.microsoft.com/en-us/Azure/app-service/configure-ssl-bindings
// https://func-letsgenerativ-tw3n.azurewebsites.net/add-certificate

// resolve.fallback (webpack < 5): https://stackoverflow.com/questions/54162297/module-not-found-error-cant-resolve-crypto - add <"browser": { "crypto": false }> in PACKAGEs package.json

export class App extends React.Component<any, IState.IState> {
  constructor (props: any) {
    super(props);
    DFConsts.setGenericTheme(undefined);
    this.state = IState.getInitialState();
  }
  async componentDidMount() {
    DFConsts.gConsoleLog('App.componentDidMount');
  }

  handleCustomEventCore(EventDetails: string, e: Event): void {
    e.stopPropagation();
    e.stopImmediatePropagation();
    const NewState: IState.IState = JSON.parse(EventDetails);
    this.setState(NewState);
  }
  handleStateChangeEvent(EventDetails: string, e: Event): void {
    this.handleCustomEventCore(EventDetails, e);
  }
  isCustomEvent(event: Event): event is CustomEvent {
    return 'detail' in event;
  }
  registerEvents(): void {
    // https://stackoverflow.com/questions/47166369/argument-of-type-e-customevent-void-is-not-assignable-to-parameter-of-ty
    window.addEventListener(DFConsts.CustomEvent_StateChange, (e: Event) => {
      if (!this.isCustomEvent(e)) { throw new Error('not a custom event'); }
      this.handleStateChangeEvent(e.detail, e);
    });
  }
  closeInfoMessageApp = () => {
    DFConsts.gConsoleLog('closeInfoMessage: ' + this.state.props.InfoMessage);

    this.InfomessageCloseTimeoutId = -1;

    const UpdatedState: IState.IState = DFConsts.CloneState(this.state);
    UpdatedState.props.InfoMessage = undefined;
    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
  }

  InfomessageCloseTimeoutId: number = -1;
  PrevInfoMessage: string | undefined = undefined;

  render(): JSX.Element {
    this.registerEvents();

    document.title = DFConsts.AppDocumentTitle;

    if (!DFConsts.IsGuidValid(this.state.props.InfoMessage)) // TODO hack not to close trait window.... separate info/error message from additional info display
    {
      if (this.state.props.InfoMessage !== undefined && this.state.props.InfoMessage !== this.PrevInfoMessage)
      {
        if (this.InfomessageCloseTimeoutId > -1 && this.state.props.InfoMessage !== this.PrevInfoMessage)
        {
          window.clearTimeout(this.InfomessageCloseTimeoutId);
          this.InfomessageCloseTimeoutId = -1;
          this.PrevInfoMessage = this.state.props.InfoMessage;
        }

        this.InfomessageCloseTimeoutId = window.setTimeout(() => {this.closeInfoMessageApp();}, 4000);
      }
      else if (this.state.props.InfoMessage === undefined && this.PrevInfoMessage !== undefined && this.InfomessageCloseTimeoutId === -1)
      {
        this.PrevInfoMessage = this.state.props.InfoMessage;
        this.InfomessageCloseTimeoutId = window.setTimeout(() => {this.closeInfoMessageApp();}, 4000);
      }
    }

    return (  
      <div id='MyApp' className="App" key={0} onClick={() => { if (this.state.props.IsLeftSlidingMenuBarVisible) { DFConsts.hideSlidingLeftMenuBar(this.state) } }}>
        <header className="App-header">
          <div style={{ display: "grid", width: '100vw', gridTemplateColumns: "2fr 1fr 8fr 3fr", gridGap: 1, alignItems:'center', minHeight: '100%' }}>

            <div style={{ minHeight: '100%', alignItems:'left', justifyContent:'left', display:'flex' }}>
              <div style={{ margin:"0px", paddingLeft:"20px", paddingRight:"10px", paddingTop:"10px", paddingBottom:"10px", border: "0px", height: "calc(50px + 2vmin)", width:"calc(50px + 2vmin)", }}
                onClick={() => { DFConsts.showSlidingLeftMenuBar(this.state); }}>
                <button style={{ fontSize:'calc(40px + 2vmin)', color:'white', margin:"0px", padding:"0px", border: "2px", backgroundColor:'var(--custom-button-component-background-color)', height: "calc(50px + 2vmin)", width:"calc(50px + 2vmin)", }} >
                  ≡
                </button>
              </div>
            </div>

            <div style={{ minHeight: '100%', alignItems:'center', justifyContent:'center', display:'flex' }}>
            </div>

            <div style={{ fontSize: 'calc(14px + 2vmin)', textAlign: 'center', minHeight: '100%', alignItems:'center', justifyContent:'center', display:'flex', }} >
              <span style={{ cursor: this.state.props.BodyMode !== DFConsts.BodyMode_Landing ? 'pointer' : 'default', }}
                onClick={() => { if (this.state.props.BodyMode !== DFConsts.BodyMode_Landing) { DFConsts.gotoLanding(this.state); } }} >
                  {DFConsts.AppHeaderLiteral}
              </span>
            </div>

            <div style={{ minHeight: '100%', alignItems:'center', justifyContent:'center', display:'flex', paddingRight:"1em", }}>
              <UserData.UserData props={this.state.props} />
            </div>

          </div>
        </header>
        <Body props={this.state.props} />
        <BusyIndicatorModal props={this.state.props} />
        {DFConsts.getSlidingLeftMenuBar(this.state)} 
        {DFConsts.showInfoMessage(this.state)} 
      </div>
    );
  }
}

export default App;
