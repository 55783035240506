import React from "react";

// https://www.twilio.com/blog/intro-custom-button-component-typescript-react

// calc widths/heights: https://stackoverflow.com/questions/38823863/use-css-jquery-calc-function-in-react-js

interface Props {
  id: string;

  type?: string;

  size: number;
  maxLength: number;
  defaultValue: string;
  textAlign?: string;
  fontsize?: string;
  border: string;
  color: string;
  height: string;
  onClick: () => void;
  onChange: () => void;
  radius?: string
  width: string;
  disabled?: boolean;
  hidden?: boolean;

  labelText: string;
  labelFontSize: string;
}

export const InputText: React.FC<Props> = ({ 
  id,

  type = "text",

  size,
  maxLength,
  defaultValue,
  textAlign = "left",
  fontsize = "calc(6px + 1vmin)",
  border,
  color,
  height,
  onClick, 
  onChange,
  radius = '5%',
  width,
  disabled = false,
  hidden = false,

  labelText,
  labelFontSize,
  }) => { 
  return (
    <div>
      {
        labelText !== undefined && labelText !== null && labelText !== "" &&
          <label  id={"label_" + id} style={{fontSize: labelFontSize,}} hidden={hidden}>{labelText} </label>
      }
      <input
        type={type}
        id={id} 
        name={id}
        size={size}
        maxLength={maxLength}
        defaultValue={defaultValue}
        disabled={disabled}
        onClick={onClick}
        onChange={onChange}
        hidden={hidden}
        style={{ 
          fontSize: fontsize,
          backgroundColor: color,
          border,
          borderRadius: radius, borderColor: disabled ? 'gray' : 'black', color: disabled ? 'gray' : 'black', padding: '1px', margin: '2px', 
          height,
          width,
          textAlign: textAlign as 'left', // what a hack! https://stackoverflow.com/questions/43121661/typescript-type-inference-issue-with-string-literal
        }}
      >
      </input>
    </div>
  );
}
