import '../../styles/App.css';
import * as IState from '../../classes/IState'
import Modal from 'react-modal';
import ProgressIndicator from '../../static/ProgressIndicator.png';

function BusyIndicatorModal(props: IState.IState): JSX.Element {

// https://github.com/reactjs/react-modal
// https://stackoverflow.com/questions/48269381/warning-react-modal-app-element-is-not-defined-please-use-modal-setappeleme

// npm install --save react-modal
// npm i --save-dev @types/react-modal

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      color: 'transparent',
      background:'var(--busy-indicator-modal-background-color)', 
      border: 'var(--busy-indicator-modal-border-settings)',
      borderRadius: '10px',
      padding: '40px',
    }, 
    overlay: {
      backgroundColor: 'transparent',
      zIndex: Number.MAX_SAFE_INTEGER,
    },
  };

  // apng from: https://icons8.com/preloaders/ 

  return (
    <div className="user-container-internal"> 

      <Modal
        isOpen={props.props.ShowBusyIndicator}
        style={customStyles}
        contentLabel="Busy Indicator Modal"
      >
        <div style={{ minHeight: '100%', alignItems:'center', justifyContent:'center', display:'flex' }}>
          <img src={ProgressIndicator} className="ProgressIndicator" alt="logo" />
        </div>
        <p style={{ fontSize: 'calc(8px + 1vmin)', color: 'white', justifyContent: 'center'}}>
          Working...
        </p>

      </Modal>
    </div>
  );
}

export default BusyIndicatorModal;
