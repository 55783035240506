import React from 'react';
import '../../styles/App.css';
import * as DFConsts from '../DFConsts';
import * as NFTImageGrid from '../NFTItemOps/NFTItemGrid'
import * as NFTMergeMint from '../MergeMinting/NFTMergeMint'
import NFTItemData from '../NFTItemOps/NFTItemData'
import * as IState from '../../classes/IState'
import * as Button from "../_CustomComponents/CustomButtonComponent";
import * as INFTItem from '../../classes/NFTItemOps/INFTItem';
import * as INFTItemList from '../../classes/NFTItemOps/INFTItemList';
import * as IMintPass from '../../classes/MintPass/IMintPass';
import Deso from 'deso-protocol';
import 'deso-protocol';
import { IdentityDeriveParams, DerivedPrivateUserInfo } from 'deso-protocol-types';
import { TransactionSpendingLimitResponse, TransactionType, NFTOperationLimitMap, NFTLimitOperationString } from 'deso-protocol-types';
import * as IAuthorizeDerivedKey from '../../classes/Security/IAuthorizeDerivedKey'
import * as ISeries from '../../classes/ProjectMgmt/ISeries'
import * as DeSoMetadata from '../DeSoMetadata'
import * as IGenerativAPIError from '../../classes/Security/IGenerativAPIError'
import * as INFTItemMetadata from "../../classes/NFTItemOps/INFTItemMetadata"
import * as INFTItemStatus from "../../classes/NFTItemOps/INFTItemStatus"
import * as INFTOps from "../../classes/NFTItemOps/INFTOps"
import * as IMergeMintingOps from '../../classes/MergeMinting/IMergeMintingOps'

export class ColumnCenter extends React.Component<IState.IState, IState.IState> {
  constructor (props: IState.IState) {
    super(props);
    DFConsts.gConsoleLog('ColumnCenter.constructor - PublicKey: ' + props.props.PublicKey);
  }

  shouldComponentUpdate(nextProps: IState.IState, nextState: IState.IState)
  {
    // https://www.freecodecamp.org/news/how-to-identify-and-resolve-wasted-renders-in-react-cc4b1e910d10/
    const PropsChanged: boolean = this.props !== nextProps;
    const StateChanged: boolean = this.state !== nextState;
    const InfoMessageChanged: boolean = this.props.props.InfoMessage !== nextProps.props.InfoMessage;
    //DFConsts.gConsoleLog('ColumnCenter.shouldComponentUpdate - PropsChanged: ' + PropsChanged + ', StateChanged: ' + StateChanged + ', InfoMessageChanged: ' + InfoMessageChanged);
    return PropsChanged || StateChanged || InfoMessageChanged;
  }

  async componentDidMount() {
    DFConsts.gConsoleLog('ColumnCenter.componentDidMount');

    await this.getMintPasses(this.props.props.NFTListSeriesSelected?.uuid!, true);
  }

  async getMintPasses(SeriesToGetMintPassesFor: string, SendStateUpdates: boolean) : Promise<IMintPass.IMintPass[] | undefined> {
    DFConsts.gConsoleLog('ColumnCenter.componentDidMount');

    if (this.props.props.PublicKey === undefined)
    {
      DFConsts.gConsoleLog('ColumnCenter.componentDidMount - undefined public key.');
      return undefined;
    }

    const requestJson: IMintPass.IGetMintPassesRequest = {
      isCreator: false, 
      projectUuid: this.props.props.NFTListProjectSelected?.uuid!, 
      getCancelled: false, 
      getUsed: false, 
      getAvailableForUse: true, 

      UpdaterPublicKey: this.props.props.PublicKey, 
      SeriesUuid: SeriesToGetMintPassesFor, 
      SessionKey: DFConsts.GetUserSessionKey(this.props.props.PublicKey)!,
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', accept: 'application/json', }, 
      RequestMode: 'cors',
      body: JSON.stringify(requestJson),
    } as RequestInit;

    return fetch(DFConsts.GenerativAPIURL_MintPassGetMintPasses, requestOptions)
      .then(response => {
        if (response.status === 200) { return response.json(); }
        DFConsts.gConsoleLog('ColumnCenter.componentDidMount - response.status: ' + response.status);
      })
      .then(jsonData  => {
        const ResponseJson: IMintPass.IGetMintPassesResponse = jsonData;

        if (IGenerativAPIError.HandleGenerativAPIFail('Getting MintPass list failed.', this.props, ResponseJson)) { return undefined; }

        DFConsts.gConsoleLog('ColumnCenter.componentDidMount got mint passes number: ' + ResponseJson.mintPassItemLst.length);

        if(SendStateUpdates === true)
        {
          const UpdatedState: IState.IState = DFConsts.CloneState(this.props);
          UpdatedState.props.UserMintPassDisplayList = JSON.parse(JSON.stringify(ResponseJson.mintPassItemLst));
          window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));  
          }
        else
        {
          return ResponseJson.mintPassItemLst;
        }
      })
      .catch(err => {
        DFConsts.gConsoleLog('ColumnCenter.componentDidMount error: ' + err);
        if (IGenerativAPIError.HandleGenerativAPIFail('Getting MintPass list failed.', this.props, null)) { return undefined; };
      });
  }

  async selectSeries(NFTListSeriesSelected: ISeries.ISeries) {
    DFConsts.gConsoleLog('ColumnCenter.selectSeries - SelectedSeries: ' + NFTListSeriesSelected.uuid);
    const UpdatedState: IState.IState = DFConsts.CloneState(this.props);
    UpdatedState.props.BodyMode = DFConsts.BodyMode_ProjectNFTList;
    UpdatedState.props.NFTListSeriesSelected = NFTListSeriesSelected;
    UpdatedState.props.NFTItemInProcessing = undefined;
    UpdatedState.props.NFTItemGridDisplayList = undefined;
    UpdatedState.props.CreatorMintPassDisplayList = undefined;
    UpdatedState.props.UserMintPassDisplayList = await this.getMintPasses(NFTListSeriesSelected.uuid, false);;
    UpdatedState.props.InfoMessage = undefined;
    UpdatedState.props.NFTMergeMintDictAllForUser_Left = undefined;
    UpdatedState.props.NFTMergeMintDictAllForUser_Right = undefined;
    UpdatedState.props.NFTMergeMintDictAllForUser_Merged = undefined;
    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
  }

  gotoStartNFTMintingProcess = async (buttonName: string) => {
    DFConsts.gConsoleLog('ColumnCenter.gotoStartNFTMintingProcess - going to minting process start');

    (document.getElementById(buttonName) as HTMLButtonElement).disabled = false;

    const UpdatedState: IState.IState = DFConsts.CloneState(this.props);
    UpdatedState.props.BodyMode = DFConsts.BodyMode_Mint;
    UpdatedState.props.NFTItemInProcessing = undefined;
    UpdatedState.props.NFTItemGridDisplayList = undefined;
    UpdatedState.props.CreatorMintPassDisplayList = undefined;
    UpdatedState.props.UserMintPassDisplayList = undefined;
    UpdatedState.props.InfoMessage = undefined;
    UpdatedState.props.NFTMergeMintDictAllForUser_Left = undefined;
    UpdatedState.props.NFTMergeMintDictAllForUser_Right = undefined;
    UpdatedState.props.NFTMergeMintDictAllForUser_Merged = undefined;
    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
  };

  mergeMintintNFT = async (buttonName: string) => {
    DFConsts.gConsoleLog('ColumnCenter.mergeMintintNFT');

    const UpdatedState: IState.IState = DFConsts.CloneState(this.props);
    UpdatedState.props.BodyMode = DFConsts.BodyMode_Mate;
    UpdatedState.props.NFTItemGridDisplayList = undefined;
    UpdatedState.props.CreatorMintPassDisplayList = undefined;
    UpdatedState.props.UserMintPassDisplayList = undefined;
    UpdatedState.props.InfoMessage = undefined;
    UpdatedState.props.NFTMergeMintDictAllForUser_Left = undefined;
    UpdatedState.props.NFTMergeMintDictAllForUser_Right = undefined;
    UpdatedState.props.NFTMergeMintDictAllForUser_Merged = undefined;
    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
  };

  mintNFT_CheckMintPasses = async(): Promise<string | undefined> => {
    DFConsts.gConsoleLog('ColumnCenter.mintNFT_CheckMintPasses');

    if (this.props.props.UserMintPassDisplayList === undefined)
    {
      DFConsts.gConsoleLog('ColumnCenter.mintNFT_CheckMintPasses - error in getting user mint passes for series.');
      return undefined;
    }
    else if (this.props.props.UserMintPassDisplayList.length === 0)
    {
      DFConsts.gConsoleLog('ColumnCenter.mintNFT_CheckMintPasses - no mint passes found for user and series.');
      return undefined;
    }
    else
    {
      const confirmText: string = this.props.props.UserMintPassDisplayList.length === 1 ? 
      'You have a MintPass available in this Series - do you want to use it?'
      : 'You have ' + this.props.props.UserMintPassDisplayList.length + ' MintPasses available in this Series - do you want to use one of them?';

      const MintPassToUse: string | undefined = window.confirm(confirmText) ? this.props.props.UserMintPassDisplayList[0].uuid : undefined;
      DFConsts.gConsoleLog('ColumnCenter.mintNFT_CheckMintPasses MintPassToUse: ' + MintPassToUse);
      return MintPassToUse;
    }
  }

  mintNFT = async (buttonName: string) => {
    DFConsts.gConsoleLog('ColumnCenter.mintNFT - mint or merge: ' + this.props.props.NFTListSeriesSelected?.parentGenerationUuid);
    if (DFConsts.IsGuidValid(this.props.props.NFTListSeriesSelected?.parentGenerationUuid))
    {
        return this.mergeMintintNFT(buttonName);
    }

    (document.getElementById(buttonName) as HTMLButtonElement).disabled = true; 
    
    DFConsts.showBusyIndicator(this.props);

    const RetAuthBrowser: boolean = await IAuthorizeDerivedKey.authorizeDerivedKey_AuthorizeBrowser_IfNeeded(this.props);
    if (RetAuthBrowser === false) 
    { 
      if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = false; }
      return;
    }

    const MintPassToUse: string | undefined = await this.mintNFT_CheckMintPasses();
    DFConsts.gConsoleLog('ColumnCenter.mintNFT MintPassToUse: ' + MintPassToUse);

    let jsonResponseDerive: DerivedPrivateUserInfo | undefined = undefined;
    if (MintPassToUse === undefined)
    {
      DFConsts.gConsoleLog('ColumnCenter.mintNFT - checking balance, for PK: ' + this.props.props.PublicKey);

      const UserDeSoNanos: number | null = await DeSoMetadata.getUserDeSoNanosBalance(this.props);
      if (UserDeSoNanos === null)
      {
        DFConsts.gConsoleLog('ColumnCenter.mintNFT - error in validating balance for user; assuming is OK.');
      }
      DFConsts.gConsoleLog('ColumnCenter.mintNFT - user DeSo nanos balance: ' + UserDeSoNanos + ', mint buy now cost: ' + this.props.props.NFTListSeriesSelected!.buyNowNanos + ', mint min bid cost: ' + this.props.props.NFTListSeriesSelected!.minBidNanos);

      DFConsts.gConsoleLog('ColumnCenter.mintNFT - authorizing derived key for payment for initial mint, from PK: ' + this.props.props.PublicKey);

      let requestPartTransactionSpendingLimit: TransactionSpendingLimitResponse;

      switch(this.props.props.NFTListSeriesSelected?.mintProcessType)
      {
        case ISeries.MintProcessType.MintAndTransfer:
          {
            const BasicTransferTransactionsNumber: number = 1; // one transfer needed for 1 NFT (TODO - this has to include Generative royalties if included)
            const GlobalDESOLimit: number = Math.max((((IAuthorizeDerivedKey.AuthorizeDerivedKeyTransactionsNumber_MinRequired + BasicTransferTransactionsNumber) * DFConsts.DerivedKeySigningMinFeeRateNanosPerKB) + this.props.props.NFTListSeriesSelected!.buyNowNanos), IAuthorizeDerivedKey.MinGlobalDESOLimitNanos_VisibleInIdentity);

            requestPartTransactionSpendingLimit = {
              GlobalDESOLimit: Math.round(GlobalDESOLimit),
              TransactionCountLimitMap: {
                [TransactionType.AuthorizeDerivedKey]: Math.round(IAuthorizeDerivedKey.AuthorizeDerivedKeyTransactionsNumber_MinRequired),
                [TransactionType.BasicTransfer]: Math.round(BasicTransferTransactionsNumber), // one transfer needed for 1 NFT (TODO - this has to include Generative royalties if included)
              },
              CreatorCoinOperationLimitMap: {},
              DAOCoinOperationLimitMap: {},
              NFTOperationLimitMap: {},
              DAOCoinLimitOrderLimitMap: {},
            };
          }
          break;
        case ISeries.MintProcessType.MintBidAcceptBid:
          {
            const NFTBidTransactionsNumber: number = 1; // one bid needed for 1 NFT
            const GlobalDESOLimit: number = Math.max((((IAuthorizeDerivedKey.AuthorizeDerivedKeyTransactionsNumber_MinRequired + NFTBidTransactionsNumber) * DFConsts.DerivedKeySigningMinFeeRateNanosPerKB) + this.props.props.NFTListSeriesSelected!.buyNowNanos), IAuthorizeDerivedKey.MinGlobalDESOLimitNanos_VisibleInIdentity);

            const requestNFTOperationLimitMap:NFTOperationLimitMap = {      
              '': { // only unknown PostHashHex - we will be minting them, unknown at start
                0: { // serial number one - we only allow minting 1/1 NFTs
                  [NFTLimitOperationString.BID]: Math.round(NFTBidTransactionsNumber),
                }
              }      
            };

            requestPartTransactionSpendingLimit = {
              GlobalDESOLimit: Math.round(GlobalDESOLimit),
              TransactionCountLimitMap: {
                [TransactionType.AuthorizeDerivedKey]: Math.round(IAuthorizeDerivedKey.AuthorizeDerivedKeyTransactionsNumber_MinRequired),
                [TransactionType.NFTBid]: Math.round(NFTBidTransactionsNumber),
              },
              CreatorCoinOperationLimitMap: {},
              DAOCoinOperationLimitMap: {},
              NFTOperationLimitMap: requestNFTOperationLimitMap,
              DAOCoinLimitOrderLimitMap: {},
            };
          }
          break;
        case ISeries.MintProcessType.UNDEFINED:
        default:
          DFConsts.gConsoleLog('ColumnCenter.mintNFT - ivalid MintProcessType: ' + this.props.props.NFTListSeriesSelected?.mintProcessType);
          if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = false; }
          return;
      }

      if (UserDeSoNanos !== null && UserDeSoNanos < requestPartTransactionSpendingLimit.GlobalDESOLimit!)
      {
        DFConsts.gConsoleLog('ColumnCenter.mintNFT - deso balance for user (' + UserDeSoNanos + ') less than required GlobalDESOLimit (' + requestPartTransactionSpendingLimit.GlobalDESOLimit + ').');

        const UpdatedState: IState.IState = DFConsts.CloneState(this.props);
        UpdatedState.props.ShowBusyIndicator = false;
        UpdatedState.props.InfoMessage = 'Your DeSo balance (' + new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 4}).format(UserDeSoNanos / DFConsts.NanosInDeso) + ') is too low to mint this NFT.';
        UpdatedState.props.DeSoBalance = UserDeSoNanos;
        window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));

        if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = false; }

        return;
      }

      const requestDerivedKey: IdentityDeriveParams = {
        callback: '',
        webview: undefined,
        publicKey: this.props.props.PublicKey,
        transactionSpendingLimitResponse: requestPartTransactionSpendingLimit,
        derivedPublicKey: '',    
      };

      const deso = new Deso();
      jsonResponseDerive = await deso.identity.derive(requestDerivedKey);
      DFConsts.gConsoleLog('ColumnCenter.mintNFT - identity derive success, need to authorize in backend');
    }

    let GuessedTraitItemName: string | undefined = undefined;
    if (this.props.props.NFTListSeriesSelected?.hasGuessedTrait === true)
    {
      GuessedTraitItemName = this.handleGuessedTraitItemName();
    }


    DFConsts.gConsoleLog('ColumnCenter.mintNFT - generating NFT');

    const requestJsonMintInitial: INFTOps.INFTItemMintInitialRequest = {
      SeriesUuid: this.props.props.NFTListSeriesSelected?.uuid!, 
      IsMintPassMint: MintPassToUse === undefined ? false : true,
      MintPassToUseUuid: MintPassToUse === undefined ? DFConsts.GuidDefault : MintPassToUse,
      GuessedTraitItemName: GuessedTraitItemName!,
      IdentityDeriveRet: MintPassToUse === undefined ? jsonResponseDerive : undefined,

      UpdaterPublicKey: this.props.props.PublicKey!, 
      SessionKey: DFConsts.GetUserSessionKey(this.props.props.PublicKey!)!,
    };

    const requestOptionsMintInitial = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', accept: 'application/json', }, 
      RequestMode: 'cors',
      body: JSON.stringify(requestJsonMintInitial),
    } as RequestInit;

    fetch(DFConsts.GenerativAPIURL_NFTItemMintInitial, requestOptionsMintInitial)
    .then(response => {
      if (response.status === 200) { return response.json(); }
      DFConsts.gConsoleLog('ColumnCenter.mintNFT - response.status: ' + response.status);
    })
    .then(jsonData => {
      const ResponseJson: INFTItem.INFTItem = jsonData;

      if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = false; }

      if (IGenerativAPIError.HandleGenerativAPIFail('Generating NFT failed.', this.props, ResponseJson)) { return; };
      
      DFConsts.gConsoleLog('ColumnCenter.mintNFT NFTItemMintInitial image generated: ' + jsonData.uuid);

      const UpdatedState: IState.IState = DFConsts.CloneState(this.props);
      UpdatedState.props.NFTItemInProcessing = JSON.parse(JSON.stringify(ResponseJson));
      UpdatedState.props.ShowBusyIndicator = false;
      UpdatedState.props.InfoMessage = 'Generating NFT succeeded.';
      window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
    })
    .catch(error => {
      DFConsts.gConsoleLog('ColumnCenter.mintNFT NFTItemMintInitial exception: ' + error);
      if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = false; }
      if (IGenerativAPIError.HandleGenerativAPIFail('Generating NFT failed.', this.props, null)) { return; };
    })
    .finally(() => {
    });
  };

  handleGuessedTraitItemName(): string  {
    DFConsts.gConsoleLog('ColumnCenter.handleGuessedTraitItemName');
    const GuessedTraitItem: string = (document.getElementById("TraitGuessingSeriesTraitItemChooser") as HTMLSelectElement).value
    DFConsts.gConsoleLog('ColumnCenter.handleGuessedTraitItemName - found guessed trait item: ' + GuessedTraitItem);
    return GuessedTraitItem;
  }

  confirmNFT = async (buttonName: string) => {
    DFConsts.gConsoleLog('ColumnCenter.confirmNFT');

    (document.getElementById(buttonName) as HTMLButtonElement).disabled = true; 

    DFConsts.showBusyIndicator(this.props); 

    const RetAuthBrowser: boolean = await IAuthorizeDerivedKey.authorizeDerivedKey_AuthorizeBrowser_IfNeeded(this.props);
    if (RetAuthBrowser === false) 
    { 
      if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = false; }
      return; 
    }

    const requestJson: INFTOps.INFTItemConfirmForMintingRequest = {
      NFTItemUuidToConfirmMinting:  this.props.props.NFTItemInProcessing?.uuid!, 
      SeriesUuid: this.props.props.NFTListSeriesSelected?.uuid!, 

      UpdaterPublicKey: this.props.props.PublicKey!, 
      SessionKey: DFConsts.GetUserSessionKey(this.props.props.PublicKey!)!,
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', accept: 'application/json', }, 
      RequestMode: 'cors',
      body: JSON.stringify(requestJson),
    } as RequestInit;

    fetch(DFConsts.GenerativAPIURL_NFTItemConfirmForMinting, requestOptions)
    .then(response => {
      if (response.status === 200) { return response.json(); }
      DFConsts.gConsoleLog('ColumnCenter.confirmNFT - response.status: ' + response.status);
    })
    .then(jsonData => {
      const ResponseJson: INFTItem.INFTItem = jsonData;

      if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = false; }

      if (IGenerativAPIError.HandleGenerativAPIFail('Confirming NFT failed.', this.props, ResponseJson)) { return; };

      const NFTMintingProcessAfterConfirmState: IState.IState = DFConsts.CloneState(this.props);
      NFTMintingProcessAfterConfirmState.props.NFTItemInProcessing = JSON.parse(JSON.stringify(ResponseJson));
      window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(NFTMintingProcessAfterConfirmState) }));

      this.getNFTProductionStatus("get_nft_production_status_button");
    })
    .catch(error => {
      DFConsts.gConsoleLog('ColumnCenter.confirmNFT NFTItemMintInitial exception: ' + error);
      if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = false; }
      if (IGenerativAPIError.HandleGenerativAPIFail('Confirming NFT failed.', this.props, null)) { return; };
    })
    .finally(() =>  {
    });
  };

  skipNFT = async (buttonName: string) => {
    DFConsts.gConsoleLog('ColumnCenter.skipNFT - requesting payment');
    if (this.props.props.PublicKey === undefined)
    {
      throw new Error('ColumnCenter.skipNFT - undefined user PublicKey');
    }

    const RetAuthBrowser: boolean = await IAuthorizeDerivedKey.authorizeDerivedKey_AuthorizeBrowser_IfNeeded(this.props);
    if (RetAuthBrowser === false) 
    {
      if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = false; }
      return; 
    }


    DFConsts.gConsoleLog('ColumnCenter.skipNFT - skip price: ' + this.props.props.NFTListSeriesSelected!.skipMintingNanos);


    let jsonResponseDerive: DerivedPrivateUserInfo | null;

    if(this.props.props.NFTListSeriesSelected!.skipMintingNanos > 0)
    {
      DFConsts.gConsoleLog('ColumnCenter.skipNFT - authorizing derived key for payment for skipping mint, from PK: ' + this.props.props.PublicKey);

      const BasicTransferTransactionsNumber: number = 1; // one transfer needed for 1 NFT (TODO - this has to include Generative royalties if included)
      const GlobalDESOLimit: number = Math.max((((IAuthorizeDerivedKey.AuthorizeDerivedKeyTransactionsNumber_MinRequired + BasicTransferTransactionsNumber) * DFConsts.DerivedKeySigningMinFeeRateNanosPerKB) + this.props.props.NFTListSeriesSelected!.skipMintingNanos), IAuthorizeDerivedKey.MinGlobalDESOLimitNanos_VisibleInIdentity);

      const requestPartTransactionSpendingLimit:TransactionSpendingLimitResponse = {
        GlobalDESOLimit: Math.round(GlobalDESOLimit),
        TransactionCountLimitMap: {
          [TransactionType.AuthorizeDerivedKey]: Math.round(IAuthorizeDerivedKey.AuthorizeDerivedKeyTransactionsNumber_MinRequired),
          [TransactionType.BasicTransfer]: Math.round(BasicTransferTransactionsNumber), // one transfer needed for 1 NFT (TODO - this has to include Generative royalties if included)
        },
        CreatorCoinOperationLimitMap: {},
        DAOCoinOperationLimitMap: {},
        NFTOperationLimitMap: {},
        DAOCoinLimitOrderLimitMap: {},
      };
      const requestDerivedKey:IdentityDeriveParams = {
        callback: '',
        webview: undefined,
        publicKey: this.props.props.PublicKey,
        transactionSpendingLimitResponse: requestPartTransactionSpendingLimit,
        derivedPublicKey: '',    
      };
      const deso = new Deso();
      jsonResponseDerive = await deso.identity.derive(requestDerivedKey);
      DFConsts.gConsoleLog('ColumnCenter.skipNFT - identity derive success, need to authorize in backend');
    }
    else
    {
      jsonResponseDerive = null;
    }

    let GuessedTraitItemName: string | undefined = undefined;
    if (this.props.props.NFTListSeriesSelected?.hasGuessedTrait === true)
    {
      GuessedTraitItemName = this.handleGuessedTraitItemName();
    }


    DFConsts.gConsoleLog('ColumnCenter.skipNFT - skipping current and generating new NFT');

    const requestJsonSkipAndRegenerate: INFTOps.INFTItemSkipAndRegenerateRequest = {
      NFTItemUuidToSkipMinting: this.props.props.NFTItemInProcessing?.uuid!,
      SeriesUuid: this.props.props.NFTListSeriesSelected?.uuid!, 
      GuessedTraitItemName: GuessedTraitItemName!,
      IdentityDeriveRet: jsonResponseDerive,

      UpdaterPublicKey: this.props.props.PublicKey, 
      SessionKey: DFConsts.GetUserSessionKey(this.props.props.PublicKey!)!,
    };

    const requestOptionsSkipAndRegenerate = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', accept: 'application/json', }, 
      RequestMode: 'cors',
      body: JSON.stringify(requestJsonSkipAndRegenerate),
    } as RequestInit;

    fetch(DFConsts.GenerativAPIURL_NFTItemSkipAndRegenerate, requestOptionsSkipAndRegenerate)
    .then(response => { 
      if (response.status === 200) { return response.json(); }
      DFConsts.gConsoleLog('ColumnCenter.skipNFT - response.status: ' + response.status);
    })
    .then(jsonData => {
      const ResponseJson: INFTItem.INFTItem = jsonData;

      if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = false; }

      if (IGenerativAPIError.HandleGenerativAPIFail('Skipping NFT failed.', this.props, ResponseJson)) { return; };

      const UpdatedState: IState.IState = DFConsts.CloneState(this.props);
      UpdatedState.props.NFTItemInProcessing = JSON.parse(JSON.stringify(ResponseJson));
      UpdatedState.props.ShowBusyIndicator = false;
      UpdatedState.props.InfoMessage = 'Skipping NFT succeeded.';
      window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
    })
    .catch(error => {
      DFConsts.gConsoleLog('ColumnCenter.skipNFT NFTItemMintInitial exception: ' + error);
      if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = false; }
      if (IGenerativAPIError.HandleGenerativAPIFail('Skipping NFT failed.', this.props, null)) { return; };
    })
    .finally(() => {
    });
  };

  getJSONStream = (responseBody: ReadableStream<Uint8Array>) => {
    // https://www.reddit.com/r/learnreactjs/comments/lx7hvu/how_do_you_fetch_a_stream_of_data_in_a_react_app/
    // https://www.bitovi.com/blog/faster-page-loads-how-to-use-ndjson-to-stream-api-responses
    // https://github.com/canjs/can-ndjson-stream/blob/588f44ec9b1ab6f8e6a3d09cac4054516fdcdc5a/can-ndjson-stream.js
    
    // https://developer.mozilla.org/en-US/docs/Web/API/Streams_API
    // https://github.com/mdn/dom-examples/blob/main/streams/simple-random-stream/index.html

    // For cancellation
    var is_reader: ReadableStreamDefaultReader<Uint8Array>, cancellationRequest: boolean = false;
    return new ReadableStream({
      start: function(controller: ReadableStreamDefaultController<any>) {
        var reader: ReadableStreamDefaultReader<Uint8Array> = responseBody.getReader();
        is_reader = reader;
        var decoder: TextDecoder = new TextDecoder();
        var data_buf: string = "";

        reader.read().then(function processResult(result: ReadableStreamReadResult<Uint8Array>): Promise<string | undefined> | undefined {
          if (result.done) {
            if (cancellationRequest) {
              // Immediately exit
              return;
            }

            data_buf = data_buf.trim();
            if (data_buf.length !== 0) {
              try {
                var data_l = JSON.parse(data_buf);
                controller.enqueue(data_l);
              } catch(e) {
                controller.error(e);
                return;
              }
            }
            controller.close();
            return;
          }

          var data: string = decoder.decode(result.value, {stream: true});
          data_buf += data;
          var lines: string[] = data_buf.split("\n");
          for(var i: number = 0; i < lines.length - 1; ++i) {
            var l: string = lines[i].trim();
            if (l.length > 0) {
              try {
                var data_line = JSON.parse(l);
                controller.enqueue(data_line);
              } 
              catch(e) {
                controller.error(e);
                cancellationRequest = true;
                reader.cancel();
                return;
              }
            }
          }
          data_buf = lines[lines.length-1];

          return reader.read().then(processResult);
        });

      },
      cancel: function(reason) {
        console.log("Cancel registered due to ", reason);
        cancellationRequest = true;
        is_reader.cancel();
      }
    });  
  }

  getNFTProductionStatus = async (buttonName: string, MaxIterations: number = 20) => {
    DFConsts.gConsoleLog('ColumnCenter.getNFTProductionStatus');

    while(document.getElementById(buttonName) as HTMLButtonElement === null)
    {
      await DFConsts.delay(10); // WTHack?
    }

    const BaseText: string = 'Checking your NFT ';

    //(document.getElementById(buttonName) as HTMLButtonElement).disabled = true; // TODO why doesn't it get unblocked after 'while', and blocks differently named button on next page???
    (document.getElementById(buttonName) as HTMLButtonElement).textContent = BaseText;

    DFConsts.showBusyIndicator(this.props);

    const ProductionStatusCheckIterationSleepMs: number = 1000;
    let CurrentIterations: number = 0;

    while(true)
    {
      DFConsts.gConsoleLog('ColumnCenter.getNFTProductionStatus - CurrentIterations: ' + CurrentIterations + ' of MaxIterations: ' + MaxIterations);

      if(CurrentIterations > 0)
      {
        await DFConsts.delay(ProductionStatusCheckIterationSleepMs);
      }

      if(CurrentIterations >= MaxIterations)
      {
        DFConsts.gConsoleLog('ColumnCenter.getNFTProductionStatus - reached max iterations whle waiting for NFT minting, CurrentIterations: ' + CurrentIterations + ' of MaxIterations: ' + MaxIterations);
        break;
      }

      CurrentIterations++;

      if (this.props.props.NFTItemInProcessing !== undefined && INFTItemStatus.statusIsTerminal(this.props.props.NFTItemInProcessing.mintingStatus))
      {
        DFConsts.gConsoleLog('ColumnCenter.getNFTProductionStatus - reached terminal status: ' + this.props.props.NFTItemInProcessing.mintingStatus);
        break;
      }

      if(document.getElementById(buttonName) as HTMLButtonElement !== null)
      {
        const StatusNum: number = CurrentIterations % 4;
        let StatusTxt: string;
        switch(StatusNum)
        {
          case 1: StatusTxt = '–'; break;
          case 2: StatusTxt = '\\'; break;
          case 3: StatusTxt = '|'; break;
          case 0: StatusTxt = '/'; break;
          default: StatusTxt = ' '; break;
        }

        (document.getElementById(buttonName) as HTMLButtonElement).textContent = BaseText + StatusTxt;
      }

      fetch(DFConsts.GenerativAPIURL_NFTItemListForUser + '?UserPublicKeyToList=' + this.props.props.PublicKey + '&NFTItemToGetUuid=' + this.props.props.NFTItemInProcessing?.uuid, {mode: 'cors'})
      .then(response => {
        if (response.status === 200) { return response.json(); }
        DFConsts.gConsoleLog('ColumnCenter.getNFTProductionStatus - response.status: ' + response.status);
      })
      .then(jsonData => {
        const ResponseJson: INFTItemList.INFTItemListResponse = jsonData;

        if (IGenerativAPIError.HandleGenerativAPIFail('Getting user image uuid: ' + this.props.props.NFTItemInProcessing?.uuid + ' failed.', this.props, ResponseJson)) { return; };

        DFConsts.gConsoleLog('Getting user image uuid: ' + this.props.props.NFTItemInProcessing?.uuid + ' failed.');

        if(ResponseJson.nftItemLst === undefined || ResponseJson.nftItemLst === null || ResponseJson.nftItemLst.length !== 1)
        {
          const NFTProductionProcessStatusState: IState.IState = DFConsts.CloneState(this.props);
          NFTProductionProcessStatusState.props.NFTItemInProcessing = undefined;
          window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(NFTProductionProcessStatusState) }));

          return;
        }

        const NFTProductionProcessStatusState: IState.IState = DFConsts.CloneState(this.props);
        NFTProductionProcessStatusState.props.NFTItemInProcessing = JSON.parse(JSON.stringify(ResponseJson.nftItemLst[0]));
        window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(NFTProductionProcessStatusState) }));
      })
      .catch(err => {
        DFConsts.gConsoleLog('ColumnCenter.getNFTProductionStatus - user NFTs list - error: ' + err);
        if (IGenerativAPIError.HandleGenerativAPIFail('Getting user image uuid: ' + this.props.props.NFTItemInProcessing?.uuid + ' failed.', this.props, null)) { return; };
      });

    }

    if(document.getElementById(buttonName) as HTMLButtonElement !== null)
    {
      (document.getElementById(buttonName) as HTMLButtonElement).disabled = false;
      (document.getElementById(buttonName) as HTMLButtonElement).textContent = 'Check Minting Status';
    }

    DFConsts.hideBusyIndicator(this.props);
  };

  stopMintingInSeries = async (buttonName: string) => {
    DFConsts.gConsoleLog('ColumnCenter.stopMintingInSeries');

    (document.getElementById(buttonName) as HTMLButtonElement).disabled = false;

    DFConsts.gotoLanding(this.props);
  };

  transferSourceNFTToMinter = async (buttonName: string, otherButtonName: string, buttonObj: INFTItemMetadata.INFTItemMergeMintedSourcePost | undefined, otherButtonObj: INFTItemMetadata.INFTItemMergeMintedSourcePost | undefined) => {
    DFConsts.gConsoleLog('ColumnCenter.transferNFT');

    if (buttonObj === undefined || otherButtonObj === undefined)
    {
      return;
    }

    const RetAuthBrowser: boolean = await IAuthorizeDerivedKey.authorizeDerivedKey_AuthorizeBrowser_IfNeeded(this.props);
    if (RetAuthBrowser === false) { return; }

    DFConsts.gConsoleLog('ColumnCenter.transferNFT - transferring source NFT: ' + buttonObj.postHashHex);

    const requestJson: IMergeMintingOps.ITransferSourceNFTRequest = {
      SenderPublicKeyBase58Check: this.props.props.PublicKey!,
      ReceiverPublicKeyBase58Check: this.props.props.NFTListSeriesSelected!.mintingPublicKey, 
      NFTPostHashHex: buttonObj.postHashHex, 
      SerialNumber: 1, 
      EncryptedUnlockableText: '', 
      MergeMintedNFTInProcessingUuid: this.props.props.NFTItemInProcessing?.uuid!,

      UpdaterPublicKey: this.props.props.PublicKey!, 
      SeriesUuid: this.props.props.NFTListSeriesSelected?.uuid!, 
      SessionKey: DFConsts.GetUserSessionKey(this.props.props.PublicKey!)!,
    };

    const requestOptionsTransferSourceNFT = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', accept: 'application/json', }, 
      RequestMode: 'cors',
      body: JSON.stringify(requestJson),
    } as RequestInit;

    fetch(DFConsts.GenerativAPIURL_MergeMintTransferSourceNFT, requestOptionsTransferSourceNFT)
    .then(response => {
      if (response.status === 200) { return response.json(); }
      DFConsts.gConsoleLog('ColumnCenter.transferNFT - response.status: ' + response.status);
      if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = false; }
    })
    .then(jsonData => {
      const ResponseJson: IMergeMintingOps.ITransferSourceNFTResponse = jsonData;

      if (IGenerativAPIError.HandleGenerativAPIFail('Transferring NFT failed.', this.props, ResponseJson)) { return; };

      if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = true; }
      
      const NFTTransferredState: IState.IState = DFConsts.CloneState(this.props);
      NFTTransferredState.props.InfoMessage = 'NFT transfer succeded.';
      window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(NFTTransferredState) }));

      setTimeout(() => { this.getNFTProductionStatus("get_nft_production_status_mergeminted_button", 5); }, 10);
    })
    .catch(error => {
      DFConsts.gConsoleLog('ColumnCenter.skipNFT NFTItemMintInitial exception: ' + error);
      if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = false; }
      if (IGenerativAPIError.HandleGenerativAPIFail('Transferring NFT failed.', this.props, null)) { return; };
    })
    .finally(() => {
      if ((document.getElementById(otherButtonName) as HTMLButtonElement) !== null) { (document.getElementById(otherButtonName) as HTMLButtonElement).disabled = otherButtonObj.isTransferredAndAccepted; }
    });
  };

  render(): JSX.Element {
    DFConsts.gConsoleLog('ColumnCenter.render - mode: ' + this.props.props.BodyMode);

    let TraitGuessingSeriesElement: JSX.Element = (<div></div>);
    if (this.props.props.NFTListSeriesSelected?.hasGuessedTrait === true)
    {
      TraitGuessingSeriesElement = (
        <div className='PromptTextTraitGuessingSeries'>
          This series requires you to guess the {this.props.props.NFTListSeriesSelected.guessedTraitName} trait. Please choose a value from the combo box below on new mint or skip.
          <br/>
            <select id="TraitGuessingSeriesTraitItemChooser" name="TraitGuessingSeriesTraitItemChooser" className="TraitGuessingChooserCombo" onChange={() => { }} >
            {
              this.props.props.NFTListSeriesSelected.guessedTraitItemNameArr.map((itemTrait, i) => 
                itemTrait !== 'None' &&
                  <option id={itemTrait} value={itemTrait} key={itemTrait} >{itemTrait}</option>
              )
            }
            </select>
          <br/><br/>
        </div>
      );
    }

    let UserMintPassesInformation: JSX.Element = (<span></span>);
    if (this.props.props.UserMintPassDisplayList !== undefined && this.props.props.UserMintPassDisplayList.length > 0)
    {
      UserMintPassesInformation = (
        <div>
          {
            this.props.props.UserMintPassDisplayList.length === 1 ? 
              '🛂 You have a MintPass available, start minting to use. 🛂'
              : '🛂 You have ' + this.props.props.UserMintPassDisplayList.length + ' MintPasses available, start minting to use. 🛂'
          }
          <br/><br/>
        </div>
      )
    }

    if (this.props.props.BodyMode === DFConsts.BodyMode_ProjectNFTList)
    {
      let AuthorizeMessage: JSX.Element = (<div></div>);

      if (this.props.props.PublicKey === this.props.props.NFTListSeriesSelected?.mintingPublicKey)
      {
        if (DFConsts.IsPublicKeyValid(this.props.props.NFTListSeriesSelected?.mintingDerivedPublicKey))
        {
          AuthorizeMessage = (
            <div className='ColumnCenterAuthorizeMessageDone'>
              You have already authorized Generativ to mint on your behalf (derived public key: {this.props.props.NFTListSeriesSelected?.mintingDerivedPublicKey}).
            </div>
          )
        }
        else
        {
          AuthorizeMessage = (
            <div className='ColumnCenterAuthorizeMessageNOTDone'>
              You have not yet authorized Generativ to mint on your behalf - please do so before opening minting to the public.
            </div>
          )
        }
      }

      let SeriesBanner: JSX.Element = (<div></div>);
      
      if(this.props.props.NFTListSeriesSelected?.seriesBannerImageMimeType === "video/mp4")
      {
        SeriesBanner = (
          <video controls autoPlay>
            <source src={DFConsts.GenerativAPIURL_GetBanner + '?SeriesGuid=' + this.props.props.NFTListSeriesSelected?.uuid + '&nocachehack=' + (new Date().getMilliseconds()) } type="video/mp4" />
          </video>            
        )
      }
      else if(this.props.props.NFTListSeriesSelected?.seriesBannerImageMimeType === "image/jpeg" || this.props.props.NFTListSeriesSelected?.seriesBannerImageMimeType === "image/gif")
      {
        SeriesBanner = (
          <img width="80%" src={DFConsts.GenerativAPIURL_GetBanner + '?SeriesGuid=' + this.props.props.NFTListSeriesSelected?.uuid + '&nocachehack=' + (new Date().getMilliseconds()) } alt={this.props.props.NFTListSeriesSelected?.name}/> 
        )
      }

      return (  
        <div className="ColumnCenter">
          <p>
            <b>{this.props.props.NFTListProjectSelected?.name}</b>
          </p>
          {
            this.props.props.NFTListProjectSelected!.seriesLst!.length > 1 &&
              <div>
                <div className='SeriesList'>
                {
                  this.props.props.NFTListProjectSelected?.seriesLst.map((item, key) => 
                    <div key={item.uuid} style={{width:'25%'}}>
                      <a className="App-link" rel="noopener noreferrer" key={item.uuid} onClick={() => {this.selectSeries(item);}} href='/#'>
                        <img width="98%" src={DFConsts.GenerativAPIURL_GetBanner + '?SeriesGuid=' + item.uuid + '&nocachehack=' + (new Date().getMilliseconds()) } alt={item.name} /> 
                      </a>
                    </div>)
                }
                </div>
                <br/>
            </div>
          }
          {
            this.props.props.NFTListSeriesSelected?.inSeriesMergeMintingAllowed === true ? 
              <div className='ColumnCenterProjectSeries'>
                <b>Series: </b> {this.props.props.NFTListSeriesSelected?.name}
                <br/><br/>
                {
                  this.props.props.NFTListSeriesSelected?.isMintingAllowed === false ?
                    <span><b>✋ Minting in this series is currently not allowed. Check back soon!</b><br/><br/></span>
                    :
                      <span> 
                        <Button.Button id="column_left_mint_nft_button" disabled={this.props.props.IsLoggedIn ? false : true} 
                          onClick={(event) => {DFConsts.mintNFT(this.props); }} children = "Mint NFT" />
                        <br/><br/>
                      </span>
                }
                { UserMintPassesInformation }
                {
                  this.props.props.NFTListSeriesSelected?.seriesPageComponents.topPageBelowMintButtonBanner !== undefined && this.props.props.NFTListSeriesSelected?.seriesPageComponents.topPageBelowMintButtonBanner !== null &&
                  this.props.props.NFTListSeriesSelected?.seriesPageComponents.topPageBelowMintButtonBanner !== '' &&
                    <span>
                      <b>{this.props.props.NFTListSeriesSelected?.seriesPageComponents.topPageBelowMintButtonBanner}</b>
                      <br/><br/>
                    </span>
                }
                <b>{this.props.props.NFTListSeriesSelected?.currentInitialMintedCount}</b> NFTs (not counting merge-mints) are already minted of a total of <b>{this.props.props.NFTListSeriesSelected?.maxCount}</b> planned in this series.
                <br/>
                Mint price: {new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 9}).format(this.props.props.NFTListSeriesSelected!.buyNowNanos / DFConsts.NanosInDeso)} DeSo, 
                Skip price: {this.props.props.NFTListSeriesSelected!.skipMintingNanos === 0 ? 'Free' : new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 9}).format(this.props.props.NFTListSeriesSelected!.skipMintingNanos / DFConsts.NanosInDeso) + 'DeSo'}, 
                Merge-mint price: Free
              </div>
              : 
                <div className='ColumnCenterProjectSeries'>
                  <b>Series: </b> {this.props.props.NFTListSeriesSelected?.name}
                  <br/><br/>
                  {
                    this.props.props.NFTListSeriesSelected?.isMintingAllowed === false ?
                      <span><b>✋ Minting in this series is currently not allowed. Check back soon!</b><br/><br/></span>
                      :
                      <span> 
                        <Button.Button id="column_left_mint_nft_button" disabled={this.props.props.IsLoggedIn ? false : true} 
                          onClick={(event) => {DFConsts.mintNFT(this.props); }} children = "Mint NFT" />
                        <br/><br/>
                      </span>
                  }
                  { UserMintPassesInformation }
                  {
                    this.props.props.NFTListSeriesSelected?.seriesPageComponents.topPageBelowMintButtonBanner !== undefined && this.props.props.NFTListSeriesSelected?.seriesPageComponents.topPageBelowMintButtonBanner !== null &&
                    this.props.props.NFTListSeriesSelected?.seriesPageComponents.topPageBelowMintButtonBanner !== '' &&
                      <span>
                        <b>{this.props.props.NFTListSeriesSelected?.seriesPageComponents.topPageBelowMintButtonBanner}</b>
                        <br/><br/>
                      </span>
                  }
                  <b>{this.props.props.NFTListSeriesSelected?.currentInitialMintedCount}</b> NFTs are already minted of a total of <b>{this.props.props.NFTListSeriesSelected?.maxCount}</b> planned in this series.
                  <br/>
                  Mint price: {new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 9}).format(this.props.props.NFTListSeriesSelected!.buyNowNanos / DFConsts.NanosInDeso)} DeSo, 
                  Skip price: {this.props.props.NFTListSeriesSelected!.skipMintingNanos === 0 ? 'Free' : new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 9}).format(this.props.props.NFTListSeriesSelected!.skipMintingNanos / DFConsts.NanosInDeso) + ' DeSo'}
                </div>
          }
          <div>
            { AuthorizeMessage }
          </div>
          <div style={{ minWidth: '100%', paddingTop: '10px' }}>
            { SeriesBanner }
          </div>
          {DFConsts.displayTextAreaField(this.props.props.NFTListSeriesSelected?.description)}
          <div>
            { <NFTImageGrid.NFTImageGrid props={this.props.props} /> }
          </div>
        </div>
      );
    }
    else if (this.props.props.BodyMode === DFConsts.BodyMode_UserNFTList)
    {
      return (  
        <div className="ColumnCenter">
          <p>
            <b>{this.props.props.Username}'s NFTs</b>
          </p>
          <div>
            { <NFTImageGrid.NFTImageGrid props={this.props.props} /> }
          </div>
        </div>
      );
    }
    else if (this.props.props.BodyMode === DFConsts.BodyMode_Mint && this.props.props.NFTItemInProcessing === undefined)
    {
      DFConsts.gConsoleLog('ColumnCenter.render - NFTListParentGenerationUuid: ' + this.props.props.NFTListSeriesSelected?.parentGenerationUuid);
      
      let MultiGenerationMergeMintingSeriesMessage: JSX.Element = (<div></div>);
      if (DFConsts.IsGuidValid(this.props.props.NFTListSeriesSelected?.parentGenerationUuid))
      {
        MultiGenerationMergeMintingSeriesMessage = (
          <div className='PromptTextMergeMinting'>
            This series is a "child" generation; minting NFTs in this series requires merging two NFTs in a parent genration according to a series-specific process.
            <br/>
            You'll receive instructions after starting the minting process.
            <br/><br/>
          </div>
        );
      }

      let InSeriesMergeMintingSeriesMessage: JSX.Element = (<div></div>);
      if (this.props.props.NFTListSeriesSelected?.inSeriesMergeMintingAllowed === true)
      {
        InSeriesMergeMintingSeriesMessage = (
          <div className='PromptTextMergeMinting'>
            This series allows in-series merge minting - you can either mint a new NFT in the sereis, or merge two NFTs to create a new one, inheriting the best traits.
            <br/>
            You'll receive instructions after starting the minting process.
            <br/><br/>
          </div>
        );
      }

      return (  
        <div className="ColumnCenter">
          <p>
            <b>NFT Minting Process - start</b>
          </p>
          <div className='ColumnCenterProjectSeries'>
            Project: {this.props.props.NFTListProjectSelected?.name}, Series: {this.props.props.NFTListSeriesSelected?.name}
            <br/>
            <br/>
          </div>
          <div>
            <div className="PromptText">
              If you're requested to accept payment for the NFT - to continue NFT minting, please confirm when "DeSo identity" window is displayed.
              <br/>
              <br/>
            </div>
            {MultiGenerationMergeMintingSeriesMessage}
            {InSeriesMergeMintingSeriesMessage}
            {TraitGuessingSeriesElement}
            <div>
              {
                this.props.props.NFTListSeriesSelected?.inSeriesMergeMintingAllowed === true ?
                <div>
                  <Button.Button id="start_new_mint_ininseries_nft_button" 
                    onClick={() => { this.mintNFT('start_new_mint_ininseries_nft_button'); }} children = "New NFT in Series" />
                  &nbsp;&nbsp;
                  <Button.Button id="start_inseries_mergemint_nft_button" 
                    onClick={() => {(document.getElementById('start_inseries_mergemint_nft_button') as HTMLButtonElement).disabled = true; this.mergeMintintNFT('start_inseries_mergemint_nft_button'); }} children = "Merge-mint in Series" />
                </div>
                : <Button.Button id="start_mint_nft_button" 
                    onClick={() => {(document.getElementById('start_mint_nft_button') as HTMLButtonElement).disabled = true; DFConsts.showBusyIndicator(this.props); this.mintNFT('start_mint_nft_button'); }} children = "Start Minting in Series" />
              }
            </div>
          </div>
          <br/>
        </div>
      );
    }
    else if (this.props.props.BodyMode === DFConsts.BodyMode_Mint && this.props.props.NFTItemInProcessing !== undefined)
    {
      if (this.props.props.NFTItemInProcessing === undefined) 
      {
        throw new Error('_NFTItemInProcessing undefined when it should not be.');
      }

      let ProcessContent: JSX.Element = (<div></div>);
      const SkipButtonText: string = "Skip for " + (this.props.props.NFTListSeriesSelected!.skipMintingNanos === 0 ? 'Free' : (new Intl.NumberFormat('en-us', {minimumFractionDigits: 1, maximumFractionDigits: 2}).format(this.props.props.NFTListSeriesSelected!.skipMintingNanos / DFConsts.NanosInDeso) + " DeSo"));

      switch(this.props.props.NFTItemInProcessing?.mintingStatus)
      {
        case INFTItemStatus.NFTItemStatus.New_WaitingForConfirmOrSkip:
          if (this.props.props.NFTItemInProcessing !== undefined) 
          {
            ProcessContent = (
              <div>
                <NFTItemData itemAsProp={{ item: this.props.props.NFTItemInProcessing, showTraits: false, showDesoCurrentInfo: false, desoDataLoadDelayMs: 0, state: this.props, }} key={this.props.props.NFTItemInProcessing?.uuid} />
                <br/>
                {TraitGuessingSeriesElement}
                <Button.Button id="confirm_nft_minting_button" 
                  onClick={() => { this.confirmNFT('confirm_nft_minting_button');}} children = "Confirm for Minting" />
                &nbsp;&nbsp;
                <Button.Button id="skip_nft_button" 
                  onClick={() => {(document.getElementById('skip_nft_button') as HTMLButtonElement).disabled = true; DFConsts.showBusyIndicator(this.props); this.skipNFT('skip_nft_button'); }} children = {SkipButtonText} />
                &nbsp;&nbsp;
                <Button.Button id="stop_minting_in_this_series_nft_button" 
                  onClick={() => {(document.getElementById('stop_minting_in_this_series_nft_button') as HTMLButtonElement).disabled = true; this.stopMintingInSeries('stop_minting_in_this_series_nft_button'); }} children = "Return Home" />
              </div>
              )
            }
          break;
        case INFTItemStatus.NFTItemStatus.New_MergeMint_WaitingForSourceTransfers:
          const Sources: INFTItemMetadata.INFTItemMergeMintedSource | undefined = this.props.props.NFTItemInProcessing.metadata.nftItemMergeMintedSource;
          const TransferLeft: JSX.Element = (
            this.props.props.NFTItemInProcessing.metadata.nftItemMergeMintedSource !== undefined ?
              <b>
                <Button.Button id="transfer_leftnft_button" disabled={this.props.props.NFTItemInProcessing.metadata.nftItemMergeMintedSource.nftItemMergeMintedSourcePostLst[0].isTransferredAndAccepted} 
                  onClick={() => 
                    {
                      (document.getElementById('transfer_leftnft_button') as HTMLButtonElement).disabled = true; // this button
                      (document.getElementById('transfer_rightnft_button') as HTMLButtonElement).disabled = true; // other button
                      DFConsts.showBusyIndicator(this.props); 
                      this.transferSourceNFTToMinter('transfer_leftnft_button', 'transfer_rightnft_button', 
                        Sources !== undefined ? Sources.nftItemMergeMintedSourcePostLst[0] : undefined, 
                        Sources !== undefined ? Sources.nftItemMergeMintedSourcePostLst[1] : undefined); 
                    }} children = "Transfer 'left' NFT" />
                &nbsp;&nbsp;
              </b>
            : <b></b>
          );
          const TransferRight: JSX.Element = (
            this.props.props.NFTItemInProcessing.metadata.nftItemMergeMintedSource !== undefined ?
              <b> 
                <Button.Button id="transfer_rightnft_button" disabled={this.props.props.NFTItemInProcessing.metadata.nftItemMergeMintedSource.nftItemMergeMintedSourcePostLst[1].isTransferredAndAccepted} 
                  onClick={() => 
                  {
                    (document.getElementById('transfer_rightnft_button') as HTMLButtonElement).disabled = true; // this button
                    (document.getElementById('transfer_leftnft_button') as HTMLButtonElement).disabled = true; // other button
                    DFConsts.showBusyIndicator(this.props); 
                    this.transferSourceNFTToMinter('transfer_rightnft_button', 'transfer_leftnft_button', 
                    Sources !== undefined ? Sources.nftItemMergeMintedSourcePostLst[1] : undefined, 
                    Sources !== undefined ? Sources.nftItemMergeMintedSourcePostLst[0] : undefined);
                  }} children = "Transfer 'right' NFT" />
                &nbsp;&nbsp;
              </b>
            : <b></b>
          );      

          if (this.props.props.NFTItemInProcessing !== undefined) 
          {
            ProcessContent = (
              <div>
                <NFTItemData itemAsProp={{ item: this.props.props.NFTItemInProcessing, showTraits: false, showDesoCurrentInfo: false, desoDataLoadDelayMs: 0, state: this.props, }} key={this.props.props.NFTItemInProcessing?.uuid} />
                <br/>
                {TransferLeft}
                {TransferRight}
                <Button.Button id="get_nft_production_status_mergeminted_button" onClick={() => { this.getNFTProductionStatus("get_nft_production_status_mergeminted_button", 3);}} children = "Check Minting Status" />
                &nbsp;&nbsp;
                <Button.Button id="mint_next_nft_button" onClick={() => {(document.getElementById('mint_next_nft_button') as HTMLButtonElement).disabled = true; this.gotoStartNFTMintingProcess('mint_next_nft_button'); }} children = "Mint Next in Series" />
                &nbsp;&nbsp;
                <Button.Button id="stop_minting_in_this_series_nft_button" 
                  onClick={() => {(document.getElementById('stop_minting_in_this_series_nft_button') as HTMLButtonElement).disabled = true; this.stopMintingInSeries('stop_minting_in_this_series_nft_button'); }} children = "Return Home" />
              </div>
              )
            }
          break;
        case INFTItemStatus.NFTItemStatus.InProgress_Confirmed_WaitingForPost:
        case INFTItemStatus.NFTItemStatus.InProgress_PostSubmitted_WaitingForMint:
        case INFTItemStatus.NFTItemStatus.InProgress_Minted_WaitingForTransfer:
        case INFTItemStatus.NFTItemStatus.InProgress_Minted_WaitingForBid:
        case INFTItemStatus.NFTItemStatus.InProgress_MintedAndBidPlaced_WaitingForAcceptBid:
          if (this.props.props.NFTItemInProcessing !== undefined) 
          {
            ProcessContent = (
              <div>
                <NFTItemData itemAsProp={{ item: this.props.props.NFTItemInProcessing, showTraits: false, showDesoCurrentInfo: false, desoDataLoadDelayMs: 0, state: this.props, }} key={this.props.props.NFTItemInProcessing?.uuid} />
              <br/>
              <Button.Button id="get_nft_production_status_button" onClick={() => { this.getNFTProductionStatus("get_nft_production_status_button", 3);}} children = "Check Minting Status" />
              &nbsp;&nbsp;
              <Button.Button id="mint_next_nft_button" onClick={() => {(document.getElementById('mint_next_nft_button') as HTMLButtonElement).disabled = true; this.gotoStartNFTMintingProcess('mint_next_nft_button'); }} children = "Mint Next in Series" />
              &nbsp;&nbsp;
              <Button.Button id="stop_minting_in_this_series_nft_button" 
                onClick={() => {(document.getElementById('stop_minting_in_this_series_nft_button') as HTMLButtonElement).disabled = true; this.stopMintingInSeries('stop_minting_in_this_series_nft_button'); }} children = "Return Home" />
              </div>
            )
          }
          break;
        case INFTItemStatus.NFTItemStatus.EndStatus_Skipped:
        case INFTItemStatus.NFTItemStatus.EndStatus_TransferredOrBidAccepted:
        case INFTItemStatus.NFTItemStatus.EndStatus_SourcesNotTransferred:
        case INFTItemStatus.NFTItemStatus.EndStatus_Burned:
          if (this.props.props.NFTItemInProcessing !== undefined) 
          {
            ProcessContent = (
              <div>
                <NFTItemData itemAsProp={{ item: this.props.props.NFTItemInProcessing, showTraits: false, showDesoCurrentInfo: false, desoDataLoadDelayMs: 0, state: this.props, }} key={this.props.props.NFTItemInProcessing?.uuid} />
              <br/>
              <Button.Button id="mint_next_nft_button" onClick={() => {(document.getElementById('mint_next_nft_button') as HTMLButtonElement).disabled = true; this.gotoStartNFTMintingProcess('mint_next_nft_button'); }} children = "Mint Next In Series" />
              &nbsp;&nbsp;
              <Button.Button id="stop_minting_in_this_series_nft_button" 
                onClick={() => {(document.getElementById('stop_minting_in_this_series_nft_button') as HTMLButtonElement).disabled = true; this.stopMintingInSeries('stop_minting_in_this_series_nft_button'); }} children = "Return Home" />
              </div>
            )
          }
          break;
        case INFTItemStatus.NFTItemStatus.Undefined:
        default:
          throw new Error('ColumnCenter.render - unknown NFTItemStatus status: ' + this.props.props.NFTItemInProcessing?.mintingStatus);
      }

      return (  
        <div className="ColumnCenter">
          <p>
            <b>NFT Minting Process - in progress</b>
          </p>
          <div className='ColumnCenterProjectSeries'>
            <b>Project: </b> {this.props.props.NFTListProjectSelected?.name}, <b>Series: </b> {this.props.props.NFTListSeriesSelected?.name}
            <br/><br/>
          </div>
          <div>
            {ProcessContent}
          </div>
          <br/>
        </div>
      );
    }
    else if (this.props.props.BodyMode === DFConsts.BodyMode_Mate)
    {
      return (  
        <div className="ColumnCenter">
          <p>
            <b>Merge minting</b>
          </p>
          <div className='ColumnCenterProjectSeries'>
            <b>Project: </b> {this.props.props.NFTListProjectSelected?.name}, <b>Series: </b> {this.props.props.NFTListSeriesSelected?.name}
            <br/><br/>
            Choose one of each kind of NFTs to preview the merged version below. Confirm minting when you're satisfied with the result.
            <br/><br/>
          </div>
          <div>
            { <NFTMergeMint.NFTMergeMint props={this.props.props} /> }
          </div>
          <br/>
        </div>
      );
    }
    else
    {
        throw new Error('ColumnCenter.render - unknown ColumnCenterMode: ' + this.props.props.BodyMode);
    }
  }
}

export default ColumnCenter;
