import * as React from "react";
import * as DFConsts from '../DFConsts';
import * as IState from "../../classes/IState";
import * as NFTMergeMintItemData from './NFTMergeMintItemData'
import Deso from 'deso-protocol';
import 'deso-protocol';
import { GetNFTsForUserRequest, GetNFTsForUserResponse, NFTEntryAndPostEntryResponse } from 'deso-protocol-types';
import * as Button from "../_CustomComponents/CustomButtonComponent";
import * as IAuthorizeDerivedKey from '../../classes/Security/IAuthorizeDerivedKey'
import * as IGenerativAPIError from '../../classes/Security/IGenerativAPIError'
import * as INFTItemMetadata from "../../classes/NFTItemOps/INFTItemMetadata"
import * as IMergeMintingOps from '../../classes/MergeMinting/IMergeMintingOps'

export interface GetNFTsForUserResponseCorrected {
  data: GetNFTsForUserResponse
  };

export class NFTMergeMint extends React.Component<IState.IState, IState.IState> {
  constructor (props: IState.IState) {
    super(props);
    DFConsts.gConsoleLog('NFTMergeMint.constructor - PublicKey: ' + props.props.PublicKey);
  }

  shouldComponentUpdate(nextProps: IState.IState, nextState: IState.IState)
  {
    // https://www.freecodecamp.org/news/how-to-identify-and-resolve-wasted-renders-in-react-cc4b1e910d10/
    const PropsChanged: boolean = this.props !== nextProps;
    const StateChanged: boolean = this.state !== nextState;
    const InfoMessageChanged: boolean = this.props.props.InfoMessage !== nextProps.props.InfoMessage;
    DFConsts.gConsoleLog('NFTMergeMintItemData.shouldComponentUpdate - PropsChanged: ' + PropsChanged + ', StateChanged: ' + StateChanged + ', InfoMessageChanged: ' + InfoMessageChanged);
    return PropsChanged || StateChanged || InfoMessageChanged;
  }

  async componentDidMount () {
    DFConsts.gConsoleLog('NFTMergeMint.componentDidMount - PublicKey: ' + this.props.props.PublicKey);
    DFConsts.gConsoleLog('NFTMergeMint.componentDidMount - NFTListSeriesMintingPublicKey: ' + this.props.props.NFTListSeriesSelected?.mintingPublicKey);

    if (!DFConsts.IsPublicKeyValid(this.props.props.PublicKey) || this.props.props.PublicKey === undefined)
    {
      return;
    }

    DFConsts.showBusyIndicator(this.props);

    const deso = new Deso();
    const requestGetNFTsForUser: GetNFTsForUserRequest = {
      UserPublicKeyBase58Check: this.props.props.PublicKey,
      ReaderPublicKeyBase58Check: this.props.props.PublicKey,
      IsForSale: null,
      IsPending: null,
    };
    const jsonResponseGetNFTsForUser: GetNFTsForUserResponse = await deso.nft.getNftsForUser(requestGetNFTsForUser);
    //const jsonResponseGetNFTsForUserCorrected: GetNFTsForUserResponseCorrected = JSON.parse(JSON.stringify(jsonResponseGetNFTsForUser));

    let NFTDictAllForUser: Map<string, NFTEntryAndPostEntryResponse> = new Map();
    
    Object.values(jsonResponseGetNFTsForUser.NFTsMap).map((nft) => {
      if (nft.PostEntryResponse !== undefined && nft.PostEntryResponse !== null && nft.PostEntryResponse?.PostHashHex !== undefined)
      {
        NFTDictAllForUser.set(nft.PostEntryResponse?.PostHashHex, nft);
      }
      DFConsts.hideBusyIndicator(this.props); 
      return (<div></div>);
    });
    DFConsts.gConsoleLog('NFTMergeMint.componentDidMount - NFTDictAllForUser count: ' + NFTDictAllForUser.size);

    // filter out user in progress source NFTs
    const responseGetSourceInProgress = await fetch(DFConsts.GenerativAPIURL_MergeMintGetInProgressSourceNFTsForUser + '?PublicKey=' + this.props.props.PublicKey);
    const responseGetSourceInProgressLst: string[] = await responseGetSourceInProgress.json();
    const NFTMergeMintDictAllForUser_WithoutInProgress: NFTEntryAndPostEntryResponse[] = [...NFTDictAllForUser.values()].filter((item: NFTEntryAndPostEntryResponse) => responseGetSourceInProgressLst.filter(x => x === item.PostEntryResponse?.PostHashHex).length === 0);
    DFConsts.gConsoleLog('NFTMergeMint.componentDidMount - NFTMergeMintDictAllForUser_WithoutInProgress count: ' + NFTMergeMintDictAllForUser_WithoutInProgress.length);

    // filter out only NFTs by current minter (series owner/minter)
    const NFTMergeMintDictAllForUser_ChosenMinterPK: NFTEntryAndPostEntryResponse[] = [...NFTMergeMintDictAllForUser_WithoutInProgress.values()].filter((item: NFTEntryAndPostEntryResponse) => item.PostEntryResponse?.PosterPublicKeyBase58Check === this.props.props.NFTListSeriesSelected?.mintingPublicKey);
    DFConsts.gConsoleLog('NFTMergeMint.componentDidMount - NFTMergeMintDictAllForUser_ChosenMinterPK count: ' + NFTMergeMintDictAllForUser_ChosenMinterPK.length);

    // TODO filter by series basing on metadata - maybe should by post hash hex? Problematic when previous series not minted with Genrativ
    // TODO hardcode for Octopose for now; in future - parametrize
    //const OctoPossePK: string = 'BC1YLgPjftkjxTxypvqjeUdMgFd6ZWfjpYBsjciFWtHE3hGnHoyY2TS'; -> wow, found it just before launch of 22nd August 2022; TBD, now leaving
    const NFTMergeMintDictAllForUser_SpecificSeries: NFTEntryAndPostEntryResponse[] = [...NFTMergeMintDictAllForUser_ChosenMinterPK.values()].filter((item: NFTEntryAndPostEntryResponse) => item.PostEntryResponse?.PostExtraData['SeriesName'] === this.props.props.NFTListSeriesSelected?.name);;
    DFConsts.gConsoleLog('NFTMergeMint.componentDidMount - NFTMergeMintDictAllForUser_SpecificSeries count: ' + NFTMergeMintDictAllForUser_SpecificSeries.length);
    /*if (this.props.props.NFTListSeriesSelected?.mintingPublicKey === OctoPossePK)
    {
      NFTMergeMintDictAllForUser_SpecificSeries = [...NFTMergeMintDictAllForUser_ChosenMinterPK.values()].filter((item: NFTEntryAndPostEntryResponse) => item.PostEntryResponse?.PostExtraData['Collection Name'].includes('S1G4'));
    }
    else
    {
      NFTMergeMintDictAllForUser_SpecificSeries = [...NFTMergeMintDictAllForUser_ChosenMinterPK.values()].filter((item: NFTEntryAndPostEntryResponse) => item.PostEntryResponse?.PostExtraData['SeriesName'] === this.props.props.NFTListSeriesSelected?.name);
    }*/

    // TODO hardcodes for Octopose traits for now; in future - parametrize
    this.props.props.NFTMergeMintDictAllForUser_Left = { itemAsProp: { items: [], label: 'Male', traits: {}, }, selected: undefined };
    this.props.props.NFTMergeMintDictAllForUser_Left.itemAsProp.items = [...NFTMergeMintDictAllForUser_SpecificSeries.values()].filter((item: NFTEntryAndPostEntryResponse) => 
      (item.PostEntryResponse?.PostExtraData['Sex'] === 'Intersex' || item.PostEntryResponse?.PostExtraData['Sex'] === 'Male'));

    this.props.props.NFTMergeMintDictAllForUser_Right = { itemAsProp: { items: [], label: 'Female', traits: {}, }, selected: undefined };
    this.props.props.NFTMergeMintDictAllForUser_Right.itemAsProp.items = [...NFTMergeMintDictAllForUser_SpecificSeries.values()].filter((item: NFTEntryAndPostEntryResponse) => 
      (item.PostEntryResponse?.PostExtraData['Sex'] === 'Intersex' || item.PostEntryResponse?.PostExtraData['Sex'] === 'Female'));

    // this.props.props.NFTMergeMintDictAllForUser_Left.itemAsProp.items = NFTMergeMintDictAllForUser_SpecificSeries; // test of merge minting when I need more than current series
    // this.props.props.NFTMergeMintDictAllForUser_Right.itemAsProp.items = NFTMergeMintDictAllForUser_SpecificSeries; // test of merge minting when I need more than current series

    DFConsts.gConsoleLog('NFTMergeMint.componentDidMount - NFTDictAllForUser_Left count: ' + this.props.props.NFTMergeMintDictAllForUser_Left.itemAsProp.items.length);
    DFConsts.gConsoleLog('NFTMergeMint.componentDidMount - NFTDictAllForUser_Right count: ' + this.props.props.NFTMergeMintDictAllForUser_Right.itemAsProp.items.length);

    // TODO - this is getting traits for a series to merge, which wasn't minted with Generativ - will I need it in the future?
    let LeftNumProcessed: number = 0;
    let RightNumProcessed: number = 0;

    this.props.props.NFTMergeMintDictAllForUser_Left.itemAsProp.items.forEach(async(item) => {
      const PostHashHexToGet: string | undefined = item.PostEntryResponse?.PostHashHex;

      const requestJsonLeft: IMergeMintingOps.IMergeMintGetTraitProbabilitiesRequest = {
        NFTPosthHashHex: PostHashHexToGet!,

        UpdaterPublicKey: this.props.props.PublicKey!, 
        SeriesUuid: this.props.props.NFTListSeriesSelected?.uuid!,
        SessionKey: DFConsts.GetUserSessionKey(this.props.props.PublicKey!)!,
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', accept: 'application/json', }, 
        RequestMode: 'cors', 
        body: JSON.stringify(requestJsonLeft),
      } as RequestInit;
  
      const responseGetTraitProbabilities = await fetch(DFConsts.GenerativAPIURL_MergeMintGetTraitProbabilities, requestOptions);
      const responseGetTraitProbabilitiesJson:  INFTItemMetadata.INFTItemMetadata = await responseGetTraitProbabilities.json();
      
      if (IGenerativAPIError.HasError(responseGetTraitProbabilitiesJson))
      {
        DFConsts.gConsoleLog('NFTMergeMint.componentDidMount - error in MergeMintGetTraitProbabilities for PostHashHexToGet (left): ' + PostHashHexToGet + ', err: ' + responseGetTraitProbabilitiesJson.error);
      }
      else
      {
        if (this.props.props.NFTMergeMintDictAllForUser_Left !== undefined && PostHashHexToGet !== undefined)
        {
          this.props.props.NFTMergeMintDictAllForUser_Left.itemAsProp.traits[PostHashHexToGet] = responseGetTraitProbabilitiesJson;
        }
      }

      DFConsts.gConsoleLog('NFTMergeMint.componentDidMount - GetTraitProbabilitiesForPostHashHex finished LEFT, count traits: ' + this.props.props.NFTMergeMintDictAllForUser_Left?.itemAsProp.traits.size);
      DFConsts.gConsoleLog('NFTMergeMint.componentDidMount - NFTDictAllForUser_Left count: ' + this.props.props.NFTMergeMintDictAllForUser_Left?.itemAsProp.items.length);
  
      LeftNumProcessed++;

      const MergeMintLeftListElemLoaded: IState.IState = DFConsts.CloneState(this.props);
      if (RightNumProcessed === this.props.props.NFTMergeMintDictAllForUser_Right?.itemAsProp.items.length && LeftNumProcessed === this.props.props.NFTMergeMintDictAllForUser_Left?.itemAsProp.items.length)
      {
        MergeMintLeftListElemLoaded.props.ShowBusyIndicator = false;
      }
      window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(MergeMintLeftListElemLoaded) }));
    })

    this.props.props.NFTMergeMintDictAllForUser_Right.itemAsProp.items.forEach(async(item) => {
      const PostHashHexToGet: string | undefined = item.PostEntryResponse?.PostHashHex;

      const requestJsonRight: IMergeMintingOps.IMergeMintGetTraitProbabilitiesRequest = {
        NFTPosthHashHex: PostHashHexToGet!,

        UpdaterPublicKey: this.props.props.PublicKey!, 
        SeriesUuid: this.props.props.NFTListSeriesSelected?.uuid!,
        SessionKey: DFConsts.GetUserSessionKey(this.props.props.PublicKey!)!,
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', accept: 'application/json', }, 
        RequestMode: 'cors', 
        body: JSON.stringify(requestJsonRight),
      } as RequestInit;
  
      const responseGetTraitProbabilities = await fetch(DFConsts.GenerativAPIURL_MergeMintGetTraitProbabilities, requestOptions);
      const responseGetTraitProbabilitiesJson: INFTItemMetadata.INFTItemMetadata = await responseGetTraitProbabilities.json();

      if (IGenerativAPIError.HasError(responseGetTraitProbabilitiesJson))
      {
        DFConsts.gConsoleLog('NFTMergeMint.componentDidMount - error in MergeMintGetTraitProbabilities for PostHashHexToGet (right): ' + PostHashHexToGet + ', err: ' + responseGetTraitProbabilitiesJson.error);
      }
      else
      {
        if (this.props.props.NFTMergeMintDictAllForUser_Right !== undefined && PostHashHexToGet !== undefined)
        {
          this.props.props.NFTMergeMintDictAllForUser_Right.itemAsProp.traits[PostHashHexToGet] = responseGetTraitProbabilitiesJson;
        }
      }

      DFConsts.gConsoleLog('NFTMergeMint.componentDidMount - GetTraitProbabilitiesForPostHashHex finished RIGHT, count traits: ' + this.props.props.NFTMergeMintDictAllForUser_Right?.itemAsProp.traits.size);
      DFConsts.gConsoleLog('NFTMergeMint.componentDidMount - NFTDictAllForUser_Right count: ' + this.props.props.NFTMergeMintDictAllForUser_Right?.itemAsProp.items.length);

      RightNumProcessed++;

      const MergeMintRightListElemLoaded: IState.IState = DFConsts.CloneState(this.props);
      if (RightNumProcessed === this.props.props.NFTMergeMintDictAllForUser_Right?.itemAsProp.items.length && LeftNumProcessed === this.props.props.NFTMergeMintDictAllForUser_Left?.itemAsProp.items.length)
      {
        MergeMintRightListElemLoaded.props.ShowBusyIndicator = false;
      }
      window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(MergeMintRightListElemLoaded) }));
    })
  }

  getBestMatches = async (buttonName: string) => {
    DFConsts.gConsoleLog('NFTMergeMint.getBestMatches - PublicKey: ' + this.props.props.PublicKey);

    const RetAuthBrowser: boolean = await IAuthorizeDerivedKey.authorizeDerivedKey_AuthorizeBrowser_IfNeeded(this.props);
    if (RetAuthBrowser === false) { return; }

    DFConsts.showBusyIndicator(this.props);

    const RightItmSelected: NFTEntryAndPostEntryResponse | undefined = this.props.props.NFTMergeMintDictAllForUser_Right?.itemAsProp.items.find(x => x.PostEntryResponse?.PostHashHex === this.props.props.NFTMergeMintDictAllForUser_Right?.selected);
    const LeftItmSelected: NFTEntryAndPostEntryResponse | undefined = this.props.props.NFTMergeMintDictAllForUser_Left?.itemAsProp.items.find(x => x.PostEntryResponse?.PostHashHex === this.props.props.NFTMergeMintDictAllForUser_Left?.selected);

    if (RightItmSelected === undefined && LeftItmSelected === undefined)
    {
      DFConsts.gConsoleLog('NFTMergeMint.getBestMatches - both selected items undefined.');
      return;
    }
    if (RightItmSelected !== undefined && LeftItmSelected !== undefined)
    {
      DFConsts.gConsoleLog('NFTMergeMint.getBestMatches - two items selected.');
      return;
    }

    const ItmSelected: string | undefined = RightItmSelected !== undefined  ? this.props.props.NFTMergeMintDictAllForUser_Right?.selected : this.props.props.NFTMergeMintDictAllForUser_Left?.selected;
    if (ItmSelected === undefined)
    {
      DFConsts.gConsoleLog('NFTMergeMint.getBestMatches - ItmSelected undefined - should never happen.');
      return;
    }

    const requestJson: IMergeMintingOps.IMergeMintGetBestMatchesRequest = {
      SeriesUuid: this.props.props.NFTListSeriesSelected?.uuid!, 
      NFTItemPostHashHexToGetBestMatchesFor: ItmSelected,

      UpdaterPublicKey: this.props.props.PublicKey!, 
      SessionKey: DFConsts.GetUserSessionKey(this.props.props.PublicKey!)!,
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', accept: 'application/json', }, 
      RequestMode: 'cors',
      body: JSON.stringify(requestJson),
    } as RequestInit;

    fetch(DFConsts.GenerativAPIURL_MergeMintGetBestMatches, requestOptions)
    .then(response => {
      if (response.status === 200) { return response.json(); }
      DFConsts.gConsoleLog('NFTMergeMint.getBestMatches - response.status: ' + response.status);
    })
    .then(jsonData => {
      const ResponseJson: IMergeMintingOps.IMergeMintGetBestMatchesResponse = jsonData;

      if (IGenerativAPIError.HandleGenerativAPIFail('Getting best matches failed.', this.props, ResponseJson)) { return; };

      const UpdatedState: IState.IState = DFConsts.CloneState(this.props);
      UpdatedState.props.NFTMergeMintBestMatchesList = ResponseJson.bestMatchesArr;
      UpdatedState.props.ShowBusyIndicator = false;
      window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
    })
    .catch(err => {
      DFConsts.gConsoleLog('NFTMergeMint.getBestMatches - best matches list - error: ' + err);
      if (IGenerativAPIError.HandleGenerativAPIFail('Getting best matches failed.', this.props, null)) { return; };
    })
    .finally(() => {
      if ((document.getElementById(buttonName) as HTMLButtonElement) !== null) { (document.getElementById(buttonName) as HTMLButtonElement).disabled = false; }
    });
  }

  render() {
    DFConsts.gConsoleLog('NFTMergeMint.render - PublicKey: ' + this.props.props.PublicKey);

    if (this.props.props.NFTMergeMintDictAllForUser_Left !== undefined && this.props.props.NFTMergeMintDictAllForUser_Right !== undefined)
    {
      return (  
        <div className="MergeMintContainer">
          <div>
            {
              <Button.Button id="mergemint_getbestmatches_button" 
                disabled={!((DFConsts.IsPostHashHexValid(this.props.props.NFTMergeMintDictAllForUser_Left.selected) && !DFConsts.IsPostHashHexValid(this.props.props.NFTMergeMintDictAllForUser_Right.selected)) ||
                  (!DFConsts.IsPostHashHexValid(this.props.props.NFTMergeMintDictAllForUser_Left.selected) && DFConsts.IsPostHashHexValid(this.props.props.NFTMergeMintDictAllForUser_Right.selected)))} 
                onClick={() => {
                  (document.getElementById('mergemint_getbestmatches_button') as HTMLButtonElement).disabled = true; 
                  this.getBestMatches('mergemint_getbestmatches_button'); 
                }} children = "Show Best Matches" />
            }
            <br/>
            <div id="mergemint_getbestmatches_results_div">
              {
                this.props.props.NFTMergeMintBestMatchesList === undefined ? 
                  <div>
                  </div>
                : this.props.props.NFTMergeMintBestMatchesList.length === 0 ? 
                    <div>
                      No matches found for your chosen OctoPosse.<br/><br/>
                    </div>
                  :
                  <div>
                    Best matches chosen from OctoPosse less rare than the one you selected:<br/>
                    {
                      this.props.props.NFTMergeMintBestMatchesList.map((item, key) => 
                      {
                         return (<div>- Match on {DFConsts.getDeSocialWorldNFTLink(item.assets.postURL, ' DeSocialWorld')} / {DFConsts.getOctoPosseNFTzNFTLink(item.assets.postURL, ' NFTz')} - Male rarity if merged: {new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 4}).format(item.metadata.rarityIndex)}</div>)
                      })
                    }
                    <br/>
                  </div>
              }
            </div>
          </div>
          <div style={{ display: "grid", width: '100%', gridTemplateColumns: "10fr 10fr", gridGap: 1 }}>
            <MergeMintLeft props={this.props.props} />
            <MergeMintRight props={this.props.props} />
          </div>
          {
            (DFConsts.IsPostHashHexValid(this.props.props.NFTMergeMintDictAllForUser_Left.selected) && DFConsts.IsPostHashHexValid(this.props.props.NFTMergeMintDictAllForUser_Right.selected)) &&
            <div>
              <MergeMintMerged props={this.props.props} />
            </div>
          }
        </div>
      );
    }
    else
    {
      return (  
        <div className="MergeMintContainer">
          Loading your NFTs from DeSo...
        </div>
      );
    }
  }
}

export class MergeMintLeft extends React.Component<IState.IState, IState.IState> {
  constructor (props: IState.IState) {
    super(props);
    DFConsts.gConsoleLog('MergeMintLeft.constructor - items number: ' + props.props.NFTMergeMintDictAllForUser_Left?.itemAsProp.items.length);
  }

render() {
  DFConsts.gConsoleLog('MergeMintLeft.render - PublicKey: ' + this.props.props.PublicKey);

  const RightItmSelected: NFTEntryAndPostEntryResponse | undefined = this.props.props.NFTMergeMintDictAllForUser_Right?.itemAsProp.items.find(x => x.PostEntryResponse?.PostHashHex === this.props.props.NFTMergeMintDictAllForUser_Right?.selected);
  const LeftItmSelected: NFTEntryAndPostEntryResponse | undefined = this.props.props.NFTMergeMintDictAllForUser_Left?.itemAsProp.items.find(x => x.PostEntryResponse?.PostHashHex === this.props.props.NFTMergeMintDictAllForUser_Left?.selected);
  return (  
    <div className="MergeMintContainer">
      <div>
        { this.props.props.NFTMergeMintDictAllForUser_Left?.itemAsProp.label }
        <br/><br/>
        {
          DFConsts.IsPostHashHexValid(this.props.props.NFTMergeMintDictAllForUser_Left?.selected) && LeftItmSelected !== undefined 
          ? <NFTMergeMintItemData.NFTMergeMintItemData itemAsProp={
            {
            item: LeftItmSelected, 
            state: this.props, 
            mode: 'left', 
            isSelected: (LeftItmSelected.PostEntryResponse?.PostHashHex === this.props.props.NFTMergeMintDictAllForUser_Left?.selected),
            traits: LeftItmSelected.PostEntryResponse?.PostHashHex !== undefined ? this.props.props.NFTMergeMintDictAllForUser_Left?.itemAsProp.traits[LeftItmSelected.PostEntryResponse?.PostHashHex] : undefined,
            }
          }
          key={LeftItmSelected.PostEntryResponse?.PostHashHex || ''}  />
          : this.props.props.NFTMergeMintDictAllForUser_Left?.itemAsProp.items.filter((item: NFTEntryAndPostEntryResponse) => (RightItmSelected?.PostEntryResponse?.PostHashHex !== item.PostEntryResponse?.PostHashHex))
              .map((item, i) => <NFTMergeMintItemData.NFTMergeMintItemData itemAsProp={
                {
                   item: item, 
                   state: this.props, 
                   mode: 'left', 
                   isSelected: (item.PostEntryResponse?.PostHashHex === this.props.props.NFTMergeMintDictAllForUser_Left?.selected),
                   traits: item.PostEntryResponse?.PostHashHex !== undefined ? this.props.props.NFTMergeMintDictAllForUser_Left?.itemAsProp.traits[item.PostEntryResponse?.PostHashHex] : undefined,
                }} key={item.PostEntryResponse?.PostHashHex || ''}  />) 
        }          
      </div>
    </div>
  );
  }
}

export class MergeMintRight extends React.Component<IState.IState, IState.IState> {
  constructor (props: IState.IState) {
    super(props);
    DFConsts.gConsoleLog('MergeMintRight.constructor - items number: ' + props.props.NFTMergeMintDictAllForUser_Right?.itemAsProp.items.length);
  }

render() {
  DFConsts.gConsoleLog('MergeMintRight.render - PublicKey: ' + this.props.props.PublicKey);

  const RightItmSelected: NFTEntryAndPostEntryResponse | undefined = this.props.props.NFTMergeMintDictAllForUser_Right?.itemAsProp.items.find(x => x.PostEntryResponse?.PostHashHex === this.props.props.NFTMergeMintDictAllForUser_Right?.selected);
  const LeftItmSelected: NFTEntryAndPostEntryResponse | undefined = this.props.props.NFTMergeMintDictAllForUser_Left?.itemAsProp.items.find(x => x.PostEntryResponse?.PostHashHex === this.props.props.NFTMergeMintDictAllForUser_Left?.selected);
  return (  
      <div className="MergeMintContainer">
        <div>
          { this.props.props.NFTMergeMintDictAllForUser_Right?.itemAsProp.label }
          <br/><br/>
          {
          DFConsts.IsPostHashHexValid(this.props.props.NFTMergeMintDictAllForUser_Right?.selected) && RightItmSelected !== undefined 
          ? <NFTMergeMintItemData.NFTMergeMintItemData itemAsProp={
            {
            item: RightItmSelected, 
            state: this.props, 
            mode: 'right', 
            isSelected: (RightItmSelected.PostEntryResponse?.PostHashHex === this.props.props.NFTMergeMintDictAllForUser_Right?.selected),
            traits: RightItmSelected.PostEntryResponse?.PostHashHex !== undefined ? this.props.props.NFTMergeMintDictAllForUser_Right?.itemAsProp.traits[RightItmSelected.PostEntryResponse?.PostHashHex] : undefined,
            }
          }
          key={RightItmSelected.PostEntryResponse?.PostHashHex || ''}  />
          : this.props.props.NFTMergeMintDictAllForUser_Right?.itemAsProp.items.filter((item: NFTEntryAndPostEntryResponse) => (LeftItmSelected?.PostEntryResponse?.PostHashHex !== item.PostEntryResponse?.PostHashHex))
              .map((item, i) => <NFTMergeMintItemData.NFTMergeMintItemData itemAsProp={
                { 
                  item: item, 
                  state: this.props, 
                  mode: 'right', 
                  isSelected: (item.PostEntryResponse?.PostHashHex === this.props.props.NFTMergeMintDictAllForUser_Right?.selected),
                  traits: item.PostEntryResponse?.PostHashHex !== undefined ? this.props.props.NFTMergeMintDictAllForUser_Right?.itemAsProp.traits[item.PostEntryResponse?.PostHashHex] : undefined,
                }} key={item.PostEntryResponse?.PostHashHex || ''}  />) 
        }          
        </div>
      </div>
    );
  }
}

export class MergeMintMerged extends React.Component<IState.IState, IState.IState> {
  constructor (props: IState.IState) {
    super(props);
    DFConsts.gConsoleLog('MergeMintMerged.constructor - PublicKey: ' + this.props.props.PublicKey);
  }

  async componentDidMount () {
    DFConsts.gConsoleLog('MergeMintMerged.componentDidMount - PublicKey: ' + this.props.props.PublicKey);

    DFConsts.showBusyIndicator(this.props);

    const requestJson: IMergeMintingOps.IMergeMintGetMergedProposalRequest = {
      PosthHashHexLeft: this.props.props.NFTMergeMintDictAllForUser_Left?.selected!,
      PosthHashHexRight: this.props.props.NFTMergeMintDictAllForUser_Right?.selected!,

      UpdaterPublicKey: this.props.props.PublicKey!, 
      SeriesUuid: this.props.props.NFTListSeriesSelected?.uuid!,
      SessionKey: DFConsts.GetUserSessionKey(this.props.props.PublicKey!)!,
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', accept: 'application/json', }, 
      RequestMode: 'cors', 
      body: JSON.stringify(requestJson),
    } as RequestInit;

    fetch(DFConsts.GenerativAPIURL_MergeMintGetMergedProposal, requestOptions)
    .then(response => {
      if (response.status === 200) { return response.json() }
      DFConsts.gConsoleLog('MergeMintMerged.componentDidMount - response.status: ' + response.status);
    })
    .then(jsonData  => {
      const ResponseJson: INFTItemMetadata.INFTItemMetadata = jsonData;

      if (IGenerativAPIError.HandleGenerativAPIFail('Getting merge minted simulation failed.', this.props, ResponseJson)) { return; };

      DFConsts.gConsoleLog('MergeMintMerged.componentDidMount - MergeMintGetMergedProposal success.');

      this.props.props.NFTMergeMintDictAllForUser_Merged = { itemAsProp: { items: [], label: 'Merged', traits: {}, }, selected: undefined };

      this.props.props.NFTMergeMintDictAllForUser_Merged.itemAsProp.traits['merged'] = ResponseJson;
      DFConsts.gConsoleLog('MergeMintMerged.componentDidMount - GetTraitProbabilitiesForPostHashHex finished MERGED, count traits: ' + this.props.props.NFTMergeMintDictAllForUser_Merged?.itemAsProp.traits.size);
  
      setTimeout(() => { DFConsts.hideBusyIndicator(this.props); }, 1000);
    })
    .catch(err => {
      DFConsts.gConsoleLog('MergeMintMerged.componentDidMount - error: ' + err);
      if (IGenerativAPIError.HandleGenerativAPIFail('Getting merge minted simulation failed.', this.props, null)) { return; };
    });
  }

  render() {
    DFConsts.gConsoleLog('MergeMintMerged.render - PublicKey: ' + this.props.props.PublicKey);

    const EmptyDupa: NFTEntryAndPostEntryResponse = { NFTEntryResponses: [], PostEntryResponse: null };

    return (  
      <div className="MergeMintContainer">
        <div>
          Merged Item
          <br/><br/>
          <NFTMergeMintItemData.NFTMergeMintItemData itemAsProp={
            {
            item: EmptyDupa, 
            state: this.props, 
            mode: 'merged', 
            isSelected: true,
            traits: this.props.props.NFTMergeMintDictAllForUser_Merged?.itemAsProp.traits['merged'],
            }
          }
          key={'merged'}  />
        </div>
      </div>
    );
  }
}
