import React from 'react';
import '../../styles/App.css';
import * as IState from '../../classes/IState'
import * as DFConsts from '../DFConsts'
import * as IProject from '../../classes/ProjectMgmt/IProject'
import * as ISeries from '../../classes/ProjectMgmt/ISeries'
import * as IGenerativAPIError from '../../classes/Security/IGenerativAPIError'
import * as GenerativAPICommon from "../GenerativAPICommon";
import * as UserData from '../Security/UserData';

export class Landing extends React.Component<IState.IState, IState.IState> {
  constructor (props: IState.IState) {
    super(props);
    DFConsts.gConsoleLog('Landing.constructor - PublicKey: ' + props.props.PublicKey);
  }

  async componentDidMount () {
    DFConsts.gConsoleLog('Landing.componentDidMount: ' + (this.props.props.ProjectDisplayList !== undefined ? this.props.props.ProjectDisplayList?.length : 'not loaded yet'));
    DFConsts.gConsoleLogEx((this.props.props.ProjectDisplayList !== undefined ? this.props.props.ProjectDisplayList?.length : 'not loaded yet'));

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', accept: 'application/json', }, 
      RequestMode: 'cors',
    } as RequestInit;

    let UpdatedStateLocal: IState.IState = await UserData.doLogin(this.props);
    UpdatedStateLocal = await GenerativAPICommon.getGenerativAPIMetadata(UpdatedStateLocal);

    fetch(DFConsts.GenerativAPIURL_ProjectList + '?IncludeInactiveProjects=false', requestOptions)
      .then(response => {
        if (response.status === 200) { return response.json(); }
        DFConsts.gConsoleLog('Landing.componentDidMount - response.status: ' + response.status);
      })
      .then(jsonData => {
        const ResponseJson: IProject.ProjectListResponse = jsonData;

        if (IGenerativAPIError.HandleGenerativAPIFail('Getting Project list failed.', this.props, ResponseJson)) { return; };

        const UpdatedState: IState.IState = DFConsts.CloneState(UpdatedStateLocal);
        UpdatedState.props.ProjectDisplayList = JSON.parse(JSON.stringify(ResponseJson.projectLst));

        if (UpdatedState.props.ProjectDisplayList !== undefined && UpdatedState.props.ProjectDisplayList.length >= 1 && UpdatedState.props.BodyMode === DFConsts.BodyMode_Landing)
        {
          UpdatedState.props.ProjectDisplayList.forEach(itemProj => {
            if (itemProj.subdomainURLs !== undefined && itemProj.subdomainURLs !== null && itemProj.subdomainURLs.subdomainURLsArr !== null)
            {
              itemProj.subdomainURLs.subdomainURLsArr.forEach(itemSub => {
                if (itemSub !== undefined && itemSub !== null && window.location.hostname === itemSub)
                {
                  UpdatedState.props.BodyMode = DFConsts.BodyMode_ProjectNFTList;
                  UpdatedState.props.NFTListProjectSelected = itemProj;
                  UpdatedState.props.NFTListSeriesSelected = itemProj.seriesLst[itemProj.seriesLst.length - 1];

                  DFConsts.setProjectTheme(UpdatedState.props.NFTListProjectSelected.frontendTheme, UpdatedState.props.DefaultFrontendTheme);
                }
              })
            }
          })
        } 

        window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
      })
      .catch(err => {
        DFConsts.gConsoleLog('Landing.componentDidMount - getting Project list failed - error: ' + err); 
        if (IGenerativAPIError.HandleGenerativAPIFail('Getting Project list failed.', this.props, null)) { return; };
    });
  }

  shouldComponentUpdate(nextProps: IState.IState, nextState: IState.IState)
  {
    // https://www.freecodecamp.org/news/how-to-identify-and-resolve-wasted-renders-in-react-cc4b1e910d10/
    const PropsChanged: boolean = this.props !== nextProps;
    const StateChanged: boolean = this.state !== nextState;
    DFConsts.gConsoleLog('Landing.shouldComponentUpdate - PropsChanged: ' + PropsChanged + ', StateChanged: ' + StateChanged);
    return PropsChanged || StateChanged;
  }

  componentDidUpdate(prevProps: Readonly<IState.IState>, prevState: Readonly<IState.IState>, snapshot?: any): void {
    if (this.props.props.ProjectDisplayList === undefined)
    {
      this.componentDidMount();
    }
  }

  render() {
    //DFConsts.gConsoleLog('Landing.render, BodyMode: ' + this.props.props.BodyMode); 

    const selectProject = (NFTListProjectSelected: IProject.IProject, NFTListSeriesSelected: ISeries.ISeries) => {

      DFConsts.setProjectTheme(NFTListProjectSelected.frontendTheme, this.props.props.DefaultFrontendTheme);

      DFConsts.gConsoleLog('Landing.SelectedProject - SelectedProject: ' + NFTListProjectSelected.uuid + ', SelectedSeries: ' + NFTListSeriesSelected.uuid);
      const GotoListState: IState.IState = DFConsts.CloneState(this.props);
      GotoListState.props.BodyMode = DFConsts.BodyMode_ProjectNFTList;
      GotoListState.props.NFTListProjectSelected = NFTListProjectSelected;
      GotoListState.props.NFTListSeriesSelected = NFTListSeriesSelected;
      window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(GotoListState) }));
    }
    
    if (this.props.props.ProjectDisplayList !== undefined)
    {
      DFConsts.gConsoleLog('Landing.render, BodyMode: ' + this.props.props.BodyMode + ' - project list display.'); 

      return (  
        <div className="Landing">
          <p>
            Projects available on Generativ:
          </p>
          <div className="ProjectList">
          {
            this.props.props.ProjectDisplayList?.map((item, i) => 
              <div className='ProjectListItem' key={item.uuid} >
                <a className="App-link" rel="noopener noreferrer" key={item.uuid} onClick={() => {selectProject(item, item.seriesLst[item.seriesLst.length - 1] );}} href='/#'>
                  <img className='LandingPageImageContainer' width="440" src={DFConsts.GenerativAPIURL_GetBanner + '?ProjectUuid=' + item.uuid} alt={item.name} /> 
                </a>
              </div>
              )
          }
          </div>
        </div>
      );
    }
    else
    {
      DFConsts.gConsoleLog('Landing.render, BodyMode: ' + this.props.props.BodyMode + ' - project list loading.'); 

      if (this.props.props.IsMetadataLoaded)
      {
        return (<div className="Landing"><p>Loading projects...</p></div>)
      }
      else
      {
        return (
        <div className="Landing">
          <br/>
          <p>🚧 Generativ is regenerating, revisit soon! 🚧</p>
          <p>
            In the meantime <a href='https://desocialworld.com/u/Generativ' target='_blank' rel="noopener noreferrer">meet us on DeSo</a>
          </p>
        </div>
        )
      }
    }
  }
}

export default Landing;
