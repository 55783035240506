import * as React from "react";
import * as INFTItem from '../../classes/NFTItemOps/INFTItem'
import * as DFConsts from '../DFConsts';
import * as IState from "../../classes/IState";
import * as Button from "../_CustomComponents/CustomButtonComponent";
import * as IProject from '../../classes/ProjectMgmt/IProject'
import * as ISeries from '../../classes/ProjectMgmt/ISeries'
import * as INFTItemStatus from "../../classes/NFTItemOps/INFTItemStatus"
import Deso from 'deso-protocol';
import 'deso-protocol';
import { GetNFTEntriesForPostHashRequest, GetNFTEntriesForPostHashResponse, GetUsersStatelessRequest, GetUsersResponse } from 'deso-protocol-types';

interface INFTItemDataState {
  ImgSrc: string;
  nftInfo: GetNFTEntriesForPostHashResponse;
  nftOwnerUsername: string;
}

export default class NFTItemData extends React.Component<INFTItem.INFTItemProp, INFTItemDataState> {
  constructor (props: INFTItem.INFTItemProp) {
    super(props);
    DFConsts.gConsoleLog('NFTItemData.constructor - item.uuid: ' + props.itemAsProp.item.uuid);
    this.setState({ ImgSrc: '' });
  }

  async componentDidMount() {
    setTimeout(async () => { await this.loadDeSoData() }, this.props.itemAsProp.desoDataLoadDelayMs);
  }

  shouldComponentUpdate(nextProps: INFTItem.INFTItemProp, nextState: INFTItemDataState)
  {
    // https://www.freecodecamp.org/news/how-to-identify-and-resolve-wasted-renders-in-react-cc4b1e910d10/
    const PropsChanged: boolean = this.props !== nextProps;
    const StateChanged: boolean = this.state !== nextState;
    const InfoMessageChanged: boolean = this.props.itemAsProp.state.props.InfoMessage !== nextProps.itemAsProp.state.props.InfoMessage;
    //DFConsts.gConsoleLog('NFTItemData.shouldComponentUpdate - PropsChanged: ' + PropsChanged + ', StateChanged: ' + StateChanged + ', InfoMessageChanged: ' + InfoMessageChanged);
    return PropsChanged || StateChanged || InfoMessageChanged;
  }

  displayTraitsInInfo(): JSX.Element | undefined {
    DFConsts.gConsoleLog('NFTItemData.displayTraitsInInfo: ' + this.props.itemAsProp.item.uuid);
    DFConsts.gConsoleLogEx('showTraits: ' + this.props.itemAsProp.showTraits);

    if (this.props.itemAsProp.item.metadata === undefined || this.props.itemAsProp.item.metadata === null)
    {
      return;
    }

    if (INFTItemStatus.statusAllowsShowingTraits(this.props.itemAsProp.item.mintingStatus) === false)
    {
      return;
    }

    if (this.props.itemAsProp.showTraits === false)
    {
      return;
    }

    return <div className="TraitsMessage">
              {
                this.props.itemAsProp.item.metadata.nftItemTraits.length > 0 ?
                  <table className="TraitsTable" >
                    <thead className="TraitsTable" >
                      <tr className="TraitsTable">
                        <td className="TraitsTable" align="center"><b>Trait name</b></td>
                        <td className="TraitsTable" align="center"><b>Trait value</b></td>
                        <td className="TraitsTable" align="center"><b>Probability</b></td>
                      </tr>
                    </thead>
                    <tbody className="TraitsTable" >
                      {
                        this.props.itemAsProp.item.metadata.nftItemTraits.map((item, i) => 
                          <tr className="TraitsTable" key={item.traitName}>
                            <td className="TraitsTable">{item.traitName}</td>
                            <td className="TraitsTable">{item.traitValue}</td>
                            <td className="TraitsTable" align="right">{new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(item.traitValueProbabilityPercent)} %</td>
                          </tr>)
                      }
                      <tr className="TraitsTable">
                        <td className="TraitsTable" colSpan={3} align="center" ><b>Rarity score: {new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 4}).format(this.props.itemAsProp.item.metadata.rarityIndex)}</b></td>
                      </tr>
                    </tbody>
                  </table>
                  : <div>
                      <b>NFT doesn't contain traits.</b>
                    </div>
              }
            </div>;
  }

  loadDeSoData = async () => {
    DFConsts.gConsoleLogEx('PostHashHex: ' + DFConsts.getPostHashHexFromPostUrl(this.props.itemAsProp.item.assets.postURL));
    const deso = new Deso();
    const requestDeso: GetNFTEntriesForPostHashRequest = { 
      PostHashHex: DFConsts.getPostHashHexFromPostUrl(this.props.itemAsProp.item.assets.postURL),
      ReaderPublicKeyBase58Check: this.props.itemAsProp.state.props.PublicKey!,
    };
    const jsonResponse: GetNFTEntriesForPostHashResponse = await deso.nft.getNftEntriesForPostHash(requestDeso);

    if(jsonResponse.NFTEntryResponses !== undefined && jsonResponse.NFTEntryResponses !== null && jsonResponse.NFTEntryResponses.length === 0)
    {
      this.setState({
        ImgSrc: this.state.ImgSrc,
        nftInfo: jsonResponse,
        nftOwnerUsername: '',
      });

      return;
    }

    const requestGetUsersStateless: GetUsersStatelessRequest = {
      PublicKeysBase58Check: [jsonResponse.NFTEntryResponses![0].OwnerPublicKeyBase58Check], 
      SkipForLeaderboard: false, 
      GetUnminedBalance: true, 
      IncludeBalance: true, 
    };
  
    const jsonResponseDataUserInfo: GetUsersResponse = await deso.user.getUserStateless(requestGetUsersStateless);
  
    if (jsonResponseDataUserInfo.UserList !== undefined && jsonResponseDataUserInfo.UserList !== null && jsonResponseDataUserInfo.UserList.length > 0 && jsonResponseDataUserInfo.UserList[0] !== null && 
      jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse !== null && 
      jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse.Username !== undefined && jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse.Username != null && jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse.Username.length > 0)
    {
      this.setState({
        ImgSrc: this.state.ImgSrc,
        nftInfo: jsonResponse,
        nftOwnerUsername: jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse.Username,
      });
      
      return;
    }

    this.setState({
      ImgSrc: this.state.ImgSrc,
      nftInfo: jsonResponse,
      nftOwnerUsername: '',
    });
  };

  displayDesoCurrentInfo(): JSX.Element | undefined {
    DFConsts.gConsoleLog('NFTItemData.displayDesoCurrentInfo: ' + this.props.itemAsProp.item.uuid);
    DFConsts.gConsoleLogEx('showDesoCurrentInfo: ' + this.props.itemAsProp.showDesoCurrentInfo);

    if (this.props.itemAsProp.item.metadata === undefined || this.props.itemAsProp.item.metadata === null)
    {
      return;
    }

    if (INFTItemStatus.statusIsMinted(this.props.itemAsProp.item.mintingStatus) === false)
    {
      return;
    }

    if (this.props.itemAsProp.showDesoCurrentInfo === false)
    {
      return;
    }

    if(this.state === null || this.state.nftInfo === undefined || this.state.nftInfo === null || this.state.nftInfo.NFTEntryResponses === undefined || this.state.nftInfo.NFTEntryResponses === null)
    {
      return <div className="TraitsMessage">
        <table className="TraitsTable" >
          <tbody className="TraitsTable" >
            <tr className="TraitsTable">
              <td className="TraitsTable" colSpan={2} align="center" ><b>Loading...</b></td>
            </tr>
          </tbody>
        </table>
      </div>;
    }

    if(this.state.nftInfo.NFTEntryResponses.length === 0)
    {
      return <div className="TraitsMessage">
        <table className="TraitsTable" >
          <tbody className="TraitsTable" >
            <tr className="TraitsTable">
              <td className="TraitsTable" colSpan={2} align="center" ><b>Item burned</b></td>
            </tr>
          </tbody>
        </table>
      </div>;
    }

    return <div className="TraitsMessage">
              <table className="TraitsTable" >
                <tbody className="TraitsTable" >
                  {
                    this.state.nftInfo.NFTEntryResponses[0].IsForSale === false ?
                      <>
                        <tr className="TraitsTable">
                          <td className="TraitsTable" colSpan={2} align="center" ><b>Not for sale</b></td>
                        </tr>
                        {
                          this.state.nftOwnerUsername !== '' &&
                            <tr className="TraitsTable">
                              <td className="TraitsTable">Current owner</td>
                              <td className="TraitsTable" align="center" >{DFConsts.getDeSocialWorldProfileLink(this.state.nftOwnerUsername)}</td>
                            </tr>
                        }
                        <tr className="TraitsTable">
                          <td className="TraitsTable" >Last price</td>
                          <td className="TraitsTable" align="center" >{new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 9}).format(this.state.nftInfo.NFTEntryResponses[0].LastAcceptedBidAmountNanos / DFConsts.NanosInDeso) + ' DeSo'}</td>
                        </tr>
                      </>
                    :
                    <>
                      <tr className="TraitsTable">
                        <td className="TraitsTable" colSpan={2} align="center" ><b>NFT is for sale!</b></td>
                      </tr>
                      {
                        this.state.nftOwnerUsername !== '' &&
                          <tr className="TraitsTable">
                            <td className="TraitsTable">Current owner</td>
                            <td className="TraitsTable" align="center" >{DFConsts.getDeSocialWorldProfileLink(this.state.nftOwnerUsername)}</td>
                          </tr>
                      }
                      <tr className="TraitsTable">
                        <td className="TraitsTable">Min bid</td>
                        <td className="TraitsTable" align="center" >{new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 4}).format(this.state.nftInfo.NFTEntryResponses[0].MinBidAmountNanos / DFConsts.NanosInDeso) + ' DeSo'}</td>
                      </tr>
                      <tr className="TraitsTable">
                        <td className="TraitsTable">Buy Now price</td>
                        <td className="TraitsTable" align="center" >{this.state.nftInfo.NFTEntryResponses[0].IsBuyNow === false ? 'not set' : new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 4}).format(this.state.nftInfo.NFTEntryResponses[0].BuyNowPriceNanos / DFConsts.NanosInDeso) + ' DeSo'}</td>
                      </tr>
                      <tr className="TraitsTable">
                        <td className="TraitsTable">Min bid placed</td>
                        <td className="TraitsTable" align="center" >{this.state.nftInfo.NFTEntryResponses[0].LowestBidAmountNanos === 0 ? 'no bid' : new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 4}).format(this.state.nftInfo.NFTEntryResponses[0].LowestBidAmountNanos / DFConsts.NanosInDeso) + ' DeSo'}</td>
                      </tr>
                      <tr className="TraitsTable">
                        <td className="TraitsTable">Max bid placed</td>
                        <td className="TraitsTable" align="center" >{this.state.nftInfo.NFTEntryResponses[0].HighestBidAmountNanos === 0 ? 'no bid' : new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 4}).format(this.state.nftInfo.NFTEntryResponses[0].HighestBidAmountNanos / DFConsts.NanosInDeso) + ' DeSo'}</td>
                      </tr>
                      <tr className="TraitsTable">
                        <td className="TraitsTable">Last price</td>
                        <td className="TraitsTable" align="center" >{new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 9}).format(this.state.nftInfo.NFTEntryResponses[0].LastAcceptedBidAmountNanos / DFConsts.NanosInDeso) + ' DeSo'}</td>
                      </tr>
                    </>
                  }
                </tbody>
              </table>
            </div>;
  }

  DisplayBigImageDisplayWidthToWindowFactor: number = 0.8;

  displayBigImageOnClick(): JSX.Element | undefined {
    DFConsts.gConsoleLog('NFTItemData.displayBigImageOnClick - ImgSrc: ' + this.state?.ImgSrc);

    if (this.state === undefined || this.state === null) { return; }
    if (this.state.ImgSrc === undefined || this.state.ImgSrc === '') { return; }

    const ImageWIdth: number = Math.min(window.innerHeight, window.innerWidth) * this.DisplayBigImageDisplayWidthToWindowFactor;

    return <div className="BigImageDisplay">
      <img height={ImageWIdth} src={this.state.ImgSrc} alt={'Goblins stole your big NFT!' + this.props.itemAsProp.item.uuid} onClick={() => this.toggleDisplayBigImage(this.state.ImgSrc) }/>
    </div>
  }

  toggleDisplayBigImage(ImgSrc: string) {
    DFConsts.gConsoleLog('NFTItemData.toggleDisplayBigImage - uuid: ' + this.props.itemAsProp.item.uuid);

    if(this.state.ImgSrc === undefined || this.state.ImgSrc === '') {
      this.setState({ ImgSrc });      
      
      const UpdatedState: IState.IState = DFConsts.CloneState(this.props.itemAsProp.state);
      UpdatedState.props.NFTItemDataBigImageToNotClean = this.props.itemAsProp.item.uuid;
      window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
    }
    else {
      this.setState({ ImgSrc: '' });
    }
  }

  continueNFTMintingProcess = async () => {
    const UpdatedState: IState.IState = DFConsts.CloneState(this.props.itemAsProp.state);
    UpdatedState.props.NFTListProjectSelected = this.props.itemAsProp.state.props.ProjectDisplayList?.find(item => item.uuid === this.props.itemAsProp.item.metadata.projectUuid);
    UpdatedState.props.NFTListSeriesSelected = this.props.itemAsProp.state.props.ProjectDisplayList?.find(item => item.uuid === this.props.itemAsProp.item.metadata.projectUuid)?.seriesLst.find(item => item.uuid === this.props.itemAsProp.item.metadata.seriesUuid);
    UpdatedState.props.BodyMode = DFConsts.BodyMode_Mint;
    UpdatedState.props.NFTItemGridDisplayList = undefined;
    UpdatedState.props.InfoMessage = undefined;
    UpdatedState.props.NFTItemInProcessing = this.props.itemAsProp.item;
    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
  };
  continueNFTMergeMintingProcess = async () => {
    const UpdatedState: IState.IState = DFConsts.CloneState(this.props.itemAsProp.state);
    UpdatedState.props.NFTListProjectSelected = this.props.itemAsProp.state.props.ProjectDisplayList?.find(item => item.uuid === this.props.itemAsProp.item.metadata.projectUuid);
    UpdatedState.props.NFTListSeriesSelected = this.props.itemAsProp.state.props.ProjectDisplayList?.find(item => item.uuid === this.props.itemAsProp.item.metadata.projectUuid)?.seriesLst.find(item => item.uuid === this.props.itemAsProp.item.metadata.seriesUuid);
    UpdatedState.props.BodyMode = DFConsts.BodyMode_Mint;
    UpdatedState.props.NFTItemGridDisplayList = undefined;
    UpdatedState.props.InfoMessage = undefined;
    UpdatedState.props.NFTItemInProcessing = this.props.itemAsProp.item;
    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
  };
  seeNFTInfo = async () => {
    const ProjectFound: IProject.IProject | undefined = this.props.itemAsProp.state.props.ProjectDisplayList?.find(item => item.uuid === this.props.itemAsProp.item.metadata.projectUuid); // not found if inactive, but user sees their NFTs in the project

    const UpdatedState: IState.IState = DFConsts.CloneState(this.props.itemAsProp.state);
    UpdatedState.props.NFTListProjectSelected = ProjectFound === undefined ? this.props.itemAsProp.state.props.NFTListProjectSelected : ProjectFound;
    UpdatedState.props.NFTListSeriesSelected = ProjectFound === undefined ? this.props.itemAsProp.state.props.NFTListSeriesSelected : ProjectFound.seriesLst.find(item => item.uuid === this.props.itemAsProp.item.metadata.seriesUuid);
    UpdatedState.props.BodyMode = DFConsts.BodyMode_Mint;
    UpdatedState.props.NFTItemGridDisplayList = undefined;
    UpdatedState.props.InfoMessage = undefined;
    UpdatedState.props.NFTItemInProcessing = this.props.itemAsProp.item;
    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));
  };

  render() {
    DFConsts.gConsoleLog('NFTItemData.render - uuid: ' + this.props.itemAsProp.item.uuid + ', NotToClean: ' + this.props.itemAsProp.state.props.NFTItemDataBigImageToNotClean);

    if(this.props.itemAsProp.state.props.NFTItemDataBigImageToNotClean !== this.props.itemAsProp.item.uuid && this.state?.ImgSrc !== '') {
      this.setState({ ImgSrc: '' });
    }

    if (this.props.itemAsProp.state.props.BodyMode === DFConsts.BodyMode_ProjectNFTList && this.props.itemAsProp.item.assets !== null)
    {
      const ImgSrc: string = this.props.itemAsProp.item.assets.imageURLs[0];
      return (  
        <div className="ImageItem" id={"itemdata_element_" + this.props.itemAsProp.item.uuid} >
          <div><img height="250" src={ImgSrc} onClick={() => { this.toggleDisplayBigImage(ImgSrc); } } alt={this.props.itemAsProp.item.uuid} /></div>
          <div><b>#{this.props.itemAsProp.item.numberInSeries}</b></div>
          {
            this.props.itemAsProp.item.metadata.nftItemTraits.length > 0 &&
              <div>
                <b>Rarity score: </b> {new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 4}).format(this.props.itemAsProp.item.metadata.rarityIndex)}
              </div>
          }
          <div><b>Minting status: </b> {INFTItemStatus.getNFTItemStatusPrettyString(this.props.itemAsProp.item.mintingStatus)}</div>
          {
            (INFTItemStatus.statusAllowsShowingTraits(this.props.itemAsProp.item.mintingStatus) && this.props.itemAsProp.item.guessedTraitItemData !== undefined && this.props.itemAsProp.item.guessedTraitItemData !== null) &&
            <div>
              {
                this.props.itemAsProp.item.guessedTraitItemData.isUserGuessCorrect === false ? 
                    <div>
                      <b>Guessed {this.props.itemAsProp.item.guessedTraitItemData.traitName}:</b> {this.props.itemAsProp.item.guessedTraitItemData.userGuessedTraitItemName}, 
                        <span><b> actual: </b> {this.props.itemAsProp.item.guessedTraitItemData.realTraitItemName}</span>
                    </div>
                  :
                  <div>
                    <b style={{color:'#cc0000'}}>Guessed {this.props.itemAsProp.item.guessedTraitItemData.traitName}: {this.props.itemAsProp.item.guessedTraitItemData.userGuessedTraitItemName}, congratulations, correct guess!</b>
                  </div>
                }
            </div>
          }
          <div><b>On-chain: </b> {DFConsts.getDeSocialWorldNFTLink(this.props.itemAsProp.item.assets.postURL, 'NFT Post @DeSocialWorld')}</div>
          {this.displayDesoCurrentInfo()}
          {this.displayTraitsInInfo()}
          {this.displayBigImageOnClick()}
        </div>
      );
      }
    else if (this.props.itemAsProp.state.props.BodyMode === DFConsts.BodyMode_Mint)
    {
      const ImgSrc: string = DFConsts.GenerativAPIURL_GetNFTImage + '?NFTItemToGetLocalImageUuid=' + this.props.itemAsProp.item.uuid;

      return (  
        <div className="ImageItem" id={"itemdata_element_" + this.props.itemAsProp.item.uuid} >
          <img height="350" src={ImgSrc} onClick={() => { this.toggleDisplayBigImage(ImgSrc); } } alt={this.props.itemAsProp.item.uuid} /> 
          <br/>
            <b>#</b> {this.props.itemAsProp.item.numberInSeries === 0 ? '<number in series available after minting>' : this.props.itemAsProp.item.numberInSeries}
          <br/>
          <div><b>Minting status:</b> {INFTItemStatus.getNFTItemStatusPrettyString(this.props.itemAsProp.item.mintingStatus)}</div>
          <div><b>Generated:</b> {DFConsts.FormatClientZoneSafeDate(this.props.itemAsProp.item.generatedTimestamp)}</div>
          <b>Confirmed: </b> 
          { this.props.itemAsProp.state.props.NFTItemInProcessing?.mintingStatus === INFTItemStatus.NFTItemStatus.New_WaitingForConfirmOrSkip ? 
            DFConsts.FormatClientZoneSafeDate(this.props.itemAsProp.item.confirmedTimestamp, INFTItemStatus.getNFTItemStatusPrettyString(INFTItemStatus.NFTItemStatus.New_WaitingForConfirmOrSkip))
            : this.props.itemAsProp.state.props.NFTItemInProcessing?.mintingStatus === INFTItemStatus.NFTItemStatus.EndStatus_Skipped ? 
              DFConsts.FormatClientZoneSafeDate(this.props.itemAsProp.item.confirmedTimestamp, INFTItemStatus.getNFTItemStatusPrettyString(INFTItemStatus.NFTItemStatus.EndStatus_Skipped))
              : DFConsts.FormatClientZoneSafeDate(this.props.itemAsProp.item.confirmedTimestamp, INFTItemStatus.getNFTItemStatusPrettyString(INFTItemStatus.NFTItemStatus.New_MergeMint_WaitingForSourceTransfers))
          }
          <br/>
          <div><b>Minted:</b> {DFConsts.FormatClientZoneSafeDate(this.props.itemAsProp.item.mintedTimestamp, 'awaiting minting')}</div>
          {
            this.props.itemAsProp.state.props.NFTListSeriesSelected?.mintProcessType === ISeries.MintProcessType.MintAndTransfer ? 
              <div><b>Transfered to Owner:</b> {DFConsts.FormatClientZoneSafeDate(this.props.itemAsProp.item.transferredTimestamp, 'awaiting transfer')}</div>
              : <div><b>Owner's bid accepted:</b> {DFConsts.FormatClientZoneSafeDate(this.props.itemAsProp.item.bidAcceptedTimestamp, 'awaiting bid acceptance')}</div>
          }
          <div><b>Unique identifier:</b> {this.props.itemAsProp.item.uuid}</div>
          <b>Traits:</b> {this.props.itemAsProp.item.metadata === undefined || this.props.itemAsProp.item.metadata === null || this.props.itemAsProp.item.numberInSeries === 0 ? 'available after you confirm minting' : 'click image to display traits'}
          {
            INFTItemStatus.statusIsMinted(this.props.itemAsProp.state.props.NFTItemInProcessing?.mintingStatus!) &&
              <div><b>On-chain: </b> {DFConsts.getDeSocialWorldNFTLink(this.props.itemAsProp.item.assets.postURL, 'NFT Post @DeSocialWorld')}</div>
          }
          {
              (INFTItemStatus.statusAllowsShowingTraits(this.props.itemAsProp.item.mintingStatus) && this.props.itemAsProp.item.guessedTraitItemData !== undefined && this.props.itemAsProp.item.guessedTraitItemData !== null) &&
              <div>
                {
                  this.props.itemAsProp.item.guessedTraitItemData.isUserGuessCorrect === false ? 
                      <div>
                        <b>Guessed {this.props.itemAsProp.item.guessedTraitItemData.traitName}:</b> {this.props.itemAsProp.item.guessedTraitItemData.userGuessedTraitItemName}, 
                          <span><b> actual: </b> {this.props.itemAsProp.item.guessedTraitItemData.realTraitItemName}</span>
                      </div>
                    :
                    <div>
                      <b style={{color:'#cc0000'}}>Guessed {this.props.itemAsProp.item.guessedTraitItemData.traitName}: {this.props.itemAsProp.item.guessedTraitItemData.userGuessedTraitItemName}, congratulations, correct guess!</b>
                    </div>
                  }
              </div>
          }
          {this.displayDesoCurrentInfo()}
          {this.displayTraitsInInfo()}
          {this.displayBigImageOnClick()}
          {
            this.props.itemAsProp.item.metadata.nftItemMergeMintedSource === undefined || this.props.itemAsProp.item.metadata.nftItemMergeMintedSource === null ?
              ''
              : <div className="MergeMintedImatgeItemSources">
                  <b>Sources:</b>&nbsp;{this.props.itemAsProp.item.metadata.nftItemMergeMintedSource.nftItemMergeMintedSourcePostLst.map((item, key) =>
                    (<div key={item.postHashHex}>{key > 0 ? ', ' : ''}{DFConsts.getDeSocialWorldNFTLink(item.postHashHex, 'Source ' + (key + 1).toString())}&nbsp;
                      {
                        item.isBurned ? '(burned)' : item.isTransferredAndAccepted ? '(received)' : '(not received)'
                      }</div>) 
                    )}
                </div>
          }
        </div>
      );
      }
      else if (this.props.itemAsProp.state.props.BodyMode === DFConsts.BodyMode_UserNFTList)
      {
        let ContinueProcessButton: JSX.Element = (<div></div>);

        if (this.props.itemAsProp.item.mintingStatus === INFTItemStatus.NFTItemStatus.New_WaitingForConfirmOrSkip)
        {
          ContinueProcessButton = (
            <div>
              <br/>
              <Button.Button id="continue_nft_minting_process_button" height = "calc(28px + 2vmin)"  
                onClick={() => { this.continueNFTMintingProcess(); }} children = "Continue NFT Minting Process" />
            </div>
            )
        }
        else if (this.props.itemAsProp.item.mintingStatus === INFTItemStatus.NFTItemStatus.New_MergeMint_WaitingForSourceTransfers)
        {
          ContinueProcessButton = (
            <div>
              <br/>
              <Button.Button id="continue_nft_minting_process_button" height = "calc(28px + 2vmin)"  
                onClick={() => { this.continueNFTMergeMintingProcess(); }} children = "Continue NFT Merge-Minting Process" />
            </div>
            )
        }
        else
        {
          ContinueProcessButton = (
            <div>
              <br/>
              <Button.Button id="see_full_nft_info_button" height = "calc(16px + 2vmin)" onClick={() => { this.seeNFTInfo(); }} width = "calc(120px + 2vmin)" children = "See NFT Info" />
            </div>
            )
        }

        const ImgSrc: string = DFConsts.GenerativAPIURL_GetNFTImage + '?NFTItemToGetLocalImageUuid=' + this.props.itemAsProp.item.uuid;

        return (  
          <div className="ImageItem" id={"itemdata_element_" + this.props.itemAsProp.item.uuid} >
            <img height="250" src={ImgSrc} alt={this.props.itemAsProp.item.uuid} 
              onClick={() => { this.toggleDisplayBigImage(ImgSrc); } } /> 
            <br/>
            <b>Project: </b>{this.props.itemAsProp.item.metadata.projectName}
            <br/>
            <b>Series: </b>{this.props.itemAsProp.item.metadata.seriesName}
            <br/>
            <b>Minting status: </b>{INFTItemStatus.getNFTItemStatusPrettyString(this.props.itemAsProp.item.mintingStatus)}
            {this.props.itemAsProp.item.numberInSeries === 0 ? '' : React.createElement('br')}
            {this.props.itemAsProp.item.numberInSeries === 0 ? '' : '#' + this.props.itemAsProp.item.numberInSeries + ' in Series'}
            {
              INFTItemStatus.statusAllowsShowingTraits(this.props.itemAsProp.item.mintingStatus) && this.props.itemAsProp.item.metadata.nftItemTraits.length > 0 &&
                <span>
                  <br/>
                  <b>Rarity score: </b> {new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 4}).format(this.props.itemAsProp.item.metadata.rarityIndex)}
                </span> 
            }
            {
                (INFTItemStatus.statusAllowsShowingTraits(this.props.itemAsProp.item.mintingStatus) && this.props.itemAsProp.item.guessedTraitItemData !== undefined && this.props.itemAsProp.item.guessedTraitItemData !== null) &&
                <div>
                  {
                    this.props.itemAsProp.item.guessedTraitItemData.isUserGuessCorrect === false ? 
                        <div>
                          <b>Guessed {this.props.itemAsProp.item.guessedTraitItemData.traitName}:</b> {this.props.itemAsProp.item.guessedTraitItemData.userGuessedTraitItemName}, 
                            <span><b> actual: </b> {this.props.itemAsProp.item.guessedTraitItemData.realTraitItemName}</span>
                        </div>
                      :
                      <div>
                        <b style={{color:'#cc0000'}}>Guessed {this.props.itemAsProp.item.guessedTraitItemData.traitName}: {this.props.itemAsProp.item.guessedTraitItemData.userGuessedTraitItemName}, congratulations, correct guess!</b>
                      </div>
                    }
                </div>
            }
            {ContinueProcessButton}
            {this.displayDesoCurrentInfo()}
            {this.displayTraitsInInfo()}
            {this.displayBigImageOnClick()}
          </div>
        );
      }
      else if (this.props.itemAsProp.state.props.BodyMode === DFConsts.BodyMode_Mate)
      {
        throw new Error('Mate Mode not handled yet.');
      }
      else
      {
        throw new Error('NFTItemData.render - invalid mode.');
      }
  }
}
