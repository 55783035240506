import React from "react";
import Deso from 'deso-protocol';
import { GetProfilesRequest, GetProfilesResponse, ProfileEntryResponse, GetUsersStatelessRequest, GetUsersResponse, GetSingleProfileRequest, GetSingleProfileResponse, } from 'deso-protocol-types';
import * as DFConsts from '../DFConsts'

interface CustomInputDeSoUsernameProps {
  disabled: boolean;
  defaultValuePublicKey?: string;
  defaultValueUsername?: string;
  input_id_username_to_populate?: string;
  input_id_publickey_to_populate?: string;
  input_id_encompassing_modal?: string;
  query?: string;
  filteredData?: ProfileEntryResponse[];
}
export class CustomInputDeSoUsername extends React.Component<CustomInputDeSoUsernameProps, CustomInputDeSoUsernameProps> {
  constructor (props: CustomInputDeSoUsernameProps) {
    super(props);
    DFConsts.gConsoleLog('CustomInputDeSoUsername.constructor.');
  }

  updateDimensions = () => {
    const elementdiv: HTMLDivElement = document.getElementById(this.IdDeSoUsernameSelectDiv) as HTMLDivElement;
    if (elementdiv !== undefined && elementdiv !== null && elementdiv.style.visibility === 'visible')
    {
      this.updateListPlacement();
    }
  };

  async componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('scroll', this.updateDimensions);

    if (DFConsts.IsPublicKeyValid(this.props.defaultValuePublicKey))
    {
      const defaultValueUsername: string = await this.getUsername(this.props.defaultValuePublicKey!);
      this.setState({ defaultValueUsername });
    }

    let query: string = "";
    let filteredData: ProfileEntryResponse[] = []

    if (this.props.query !== undefined)
    {
      query = this.props.query;
    }

    if (this.props.filteredData !== undefined)
    {
      filteredData = this.props.filteredData;
    }

    this.setState( { query, filteredData } );

    this.CurrentQueryTimestamp = Date.now();
    await this.getData(query, this.CurrentQueryTimestamp);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('scroll', this.updateDimensions);
  }

  shouldComponentUpdate(nextProps: CustomInputDeSoUsernameProps, nextState: CustomInputDeSoUsernameProps)
  {
    // https://www.freecodecamp.org/news/how-to-identify-and-resolve-wasted-renders-in-react-cc4b1e910d10/
    const PropsChanged: boolean = this.props !== nextProps;
    const StateChanged: boolean = this.state !== nextState;
    DFConsts.gConsoleLog('CustomInputDeSoUsername.shouldComponentUpdate - PropsChanged: ' + PropsChanged + ', StateChanged: ' + StateChanged);
    return PropsChanged || StateChanged;
  }

  IdLocalUsernameInput: string = "inputtext_desousername_" + Math.random();
  IdLocalUsernameInputHiddenPK: string = this.IdLocalUsernameInput + "_hiddenpk_" + Math.random();
  IdLocalUsernameInputHiddenUsername: string = this.IdLocalUsernameInput + "_hiddenusername_" + Math.random();
  IdDeSoUsernameSelectDiv: string = "desousername_select_div_" + Math.random();

  CurrentQueryTimestamp: number = Date.now();

  handleInputChange = async(event: any) => {
    const query: string = event.target.value;

    if (query === '')
    {
      const filteredData: ProfileEntryResponse[] = [];
      this.setState( { query, filteredData } );
    }
    else
    {
      this.setState( { query } );
    }

    while(this.state === null || query !== this.state.query)
    {
      await DFConsts.delay(10); // no idea why this hack is needed....
    }

    this.CurrentQueryTimestamp = Date.now();
    await this.getData(query, Date.now());
  }

  handleSelectUser = async(event: any, userdiv: string) => {
    const elemThis: HTMLDivElement = document.getElementById(userdiv) as HTMLDivElement;
    const elemSearch: HTMLInputElement = document.getElementById(this.IdLocalUsernameInput) as HTMLInputElement;
    const elemPK: HTMLInputElement = document.getElementById(this.IdLocalUsernameInputHiddenPK) as HTMLInputElement;
    const elemUsername: HTMLInputElement = document.getElementById(this.IdLocalUsernameInputHiddenUsername) as HTMLInputElement;

    const foundProfile: ProfileEntryResponse = this.state.filteredData?.find(item => item.Username === elemThis.textContent?.trim())!;

    if (this.props.input_id_publickey_to_populate !== undefined)
    {
      const elemExternalPK: HTMLInputElement = document.getElementById(this.props.input_id_publickey_to_populate) as HTMLInputElement;
      elemExternalPK.value = foundProfile.PublicKeyBase58Check;
    }

    if (this.props.input_id_username_to_populate !== undefined)
    {
      const elemExternalUsername: HTMLInputElement = document.getElementById(this.props.input_id_username_to_populate) as HTMLInputElement;
      elemExternalUsername.value = foundProfile.Username;
    }


    elemSearch.value = "";
    elemPK.value = foundProfile.PublicKeyBase58Check;
    elemUsername.value = foundProfile.Username;

    const query: string = "";
    const filteredData: ProfileEntryResponse[] = [];
    this.setState( { query, filteredData } );

    while(this.state === null || query !== this.state.query)
    {
      await DFConsts.delay(10); // no idea why this hack is needed....
    }
                    
    this.CurrentQueryTimestamp = Date.now();
    this.getData(query, this.CurrentQueryTimestamp);
  }

  getData = async(query: string, queryDate: number) => {

    if (query === "")
    {
      this.updateListPlacement();
      return;
    }

    let queryFullUsername: string = '';
    if (query[query.length - 1] === ' ')
    {
      queryFullUsername = query.trim();
    }

    if (queryFullUsername === '')
    {
      const requestGetProfiles: GetProfilesRequest = {
        PublicKeyBase58Check: "",
        Username: "",
        UsernamePrefix: query,
        Description: "",
        OrderBy: "influencer_coin_price",
        NumToFetch: 20,
        ReaderPublicKeyBase58Check: "",
        ModerationType: "unrestricted",
        FetchUsersThatHODL: false,
        AddGlobalFeedBool: false,
      };

      const deso = new Deso();
      const jsonResponse: GetProfilesResponse = await deso.user.getProfiles(requestGetProfiles);

      if (jsonResponse.ProfilesFound === null) { return; }

      if (this.CurrentQueryTimestamp === queryDate)
      {
        let filteredData: ProfileEntryResponse[] = [];
        jsonResponse.ProfilesFound.forEach(item => filteredData.push(item) );
        this.setState( { filteredData } );
      }
    }
    else
    {
      const requestGetSingleProfile: GetSingleProfileRequest = {
        PublicKeyBase58Check: "",
        Username: queryFullUsername,
        NoErrorOnMissing: true,
      };

      const deso = new Deso();
      const jsonResponse: GetSingleProfileResponse = await deso.user.getSingleProfile(requestGetSingleProfile);

      if (jsonResponse.Profile === null) { return; }

      if (this.CurrentQueryTimestamp === queryDate)
      {
        let filteredData: ProfileEntryResponse[] = [];
        filteredData.push(jsonResponse.Profile);
        this.setState( { filteredData } );
      }
    }

    this.updateListPlacement();
  };

  updateListPlacement() {
    const element: HTMLInputElement = document.getElementById(this.IdLocalUsernameInput) as HTMLInputElement;
    var rect: DOMRect = element.getBoundingClientRect();

    let xoffset: number = 0;
    let yoffset: number = 0;
    if (this.props.input_id_encompassing_modal !== undefined)
    {
      const elementModal = document.getElementById(this.props.input_id_encompassing_modal);
      if (elementModal !== null)
      {
        var rectOfModal: DOMRect = elementModal.getBoundingClientRect();
        xoffset = rectOfModal.left;
        yoffset = rectOfModal.top;
      }
    }

    const dataLength: number = this.state !== null ? this.state.filteredData != null ? this.state.filteredData.length : 0 : 0;

    DFConsts.gConsoleLog('CustomInputDeSoUsername.updateListPlacement - input rect; left: ' + rect.left + ', bottom: ' + rect.bottom + ', top: ' + rect.top + ', width: ' +  rect.width + ', filteredData.length: ' +  dataLength);
    DFConsts.gConsoleLog('CustomInputDeSoUsername.updateListPlacement - xoffset: ' + xoffset + ', yoffset: ' + yoffset);

    const elementdiv: HTMLDivElement = document.getElementById(this.IdDeSoUsernameSelectDiv) as HTMLDivElement;
    if (dataLength === 0)
    {
      elementdiv.style.visibility = 'hidden';
      document.documentElement.style.setProperty('--DeSoUsernameSelectList-left', '0px');
      document.documentElement.style.setProperty('--DeSoUsernameSelectList-top', '0px');
      document.documentElement.style.setProperty('--DeSoUsernameSelectList-bottom', '0px');
      document.documentElement.style.setProperty('--DeSoUsernameSelectList-width', '0px');
      document.documentElement.style.setProperty('--DeSoUsernameSelectList-height', '0px');
    }
    else
    {
      elementdiv.style.visibility = 'visible';

      if (rect.bottom < window.innerHeight * 0.7)
      {
        document.documentElement.style.setProperty('--DeSoUsernameSelectList-left', (rect.left - xoffset) + 'px');
        document.documentElement.style.setProperty('--DeSoUsernameSelectList-top', (rect.bottom - yoffset) + 'px');
        document.documentElement.style.setProperty('--DeSoUsernameSelectList-bottom', null);
        document.documentElement.style.setProperty('--DeSoUsernameSelectList-width', (rect.width * 0.9) + 'px');
        document.documentElement.style.setProperty('--DeSoUsernameSelectList-height', 'calc(' + (dataLength * 16) + 'px + 2hmin)');
      }
      else
      {
        document.documentElement.style.setProperty('--DeSoUsernameSelectList-left', (rect.left - xoffset) + 'px');
        document.documentElement.style.setProperty('--DeSoUsernameSelectList-top', null);
        document.documentElement.style.setProperty('--DeSoUsernameSelectList-bottom', (window.innerHeight - rect.bottom - yoffset + (window.innerWidth * 0.003)) + 'px');
        document.documentElement.style.setProperty('--DeSoUsernameSelectList-width', (rect.width * 0.9) + 'px');
        document.documentElement.style.setProperty('--DeSoUsernameSelectList-height', 'calc(' + (dataLength * 16) + 'px + 2hmin)');
      }
    }
  }

  async getUsername (PK: string): Promise<string> {
    if (!DFConsts.IsPublicKeyValid(PK))
    {
      return '';
    }

    const requestGetUsersStateless: GetUsersStatelessRequest = {
      PublicKeysBase58Check: [PK], 
      SkipForLeaderboard: false, 
      GetUnminedBalance: true, 
      IncludeBalance: true, 
    };
    
    const deso = new Deso();
    const jsonResponseDataUserInfo: GetUsersResponse = await deso.user.getUserStateless(requestGetUsersStateless);

    if (jsonResponseDataUserInfo.UserList !== undefined && jsonResponseDataUserInfo.UserList !== null && jsonResponseDataUserInfo.UserList[0] != null && 
      jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse != null && 
      jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse.Username !== undefined && jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse.Username != null)
    {
      return jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse.Username;
    }

    return '';
  }

  render() {

    return (
      <div className="searchForm">
        <form>
          <input id={this.IdLocalUsernameInput} name={this.IdLocalUsernameInput} 
            type="text" 
            size={25} maxLength={25} 
            defaultValue=""
            placeholder="DeSo Username Search..."
            disabled={this.props.disabled} 

            style={{ 
              fontSize: "calc(5px + 1vmin)",
              backgroundColor: "#eeeeee",
              border: "1px solid",
              borderRadius: "1%", borderColor: this.props.disabled ? 'gray' : 'black', color: this.props.disabled ? 'gray' : 'black', padding: '1px', margin: '2px', 
              height: "calc(8px + 1vmin)",
              width: "",
              textAlign: 'left' as 'left', // what a hack! https://stackoverflow.com/questions/43121661/typescript-type-inference-issue-with-string-literal
            }}
    
            onClick={() => {}} 
            onChange={(event) => { this.handleInputChange(event); } }
          />
          <input id={this.IdLocalUsernameInputHiddenPK} name={this.IdLocalUsernameInputHiddenPK}
            type="hidden"
            size={65} maxLength={60} 
            defaultValue={this.props.defaultValuePublicKey}
            placeholder="DeSo Public Key..."
            disabled={true} 

            style={{ 
              fontSize: "calc(2px + 1vmin)",
              backgroundColor: "#eeeeee",
              border: "1px solid",
              borderRadius: "1%", borderColor: this.props.disabled ? 'gray' : 'black', color: this.props.disabled ? 'gray' : 'black', padding: '1px', margin: '2px', 
              height: "calc(7px + 1vmin)",
              width: "",
              textAlign: 'left' as 'left', // what a hack! https://stackoverflow.com/questions/43121661/typescript-type-inference-issue-with-string-literal
            }}
    
            onClick={() => {}} 
            onChange={() => {}}
          />
          <input id={this.IdLocalUsernameInputHiddenUsername} name={this.IdLocalUsernameInputHiddenUsername}
            type="hidden"
            size={35} maxLength={25} 
            defaultValue={this.state === null ? '' : this.state.defaultValueUsername}
            placeholder="DeSo Username..."
            disabled={true} 

            style={{ 
              fontSize: "calc(2px + 1vmin)",
              backgroundColor: "#eeeeee",
              border: "1px solid",
              borderRadius: "1%", borderColor: this.props.disabled ? 'gray' : 'black', color: this.props.disabled ? 'gray' : 'black', padding: '1px', margin: '2px', 
              height: "calc(7px + 1vmin)",
              width: "",
              textAlign: 'left' as 'left', // what a hack! https://stackoverflow.com/questions/43121661/typescript-type-inference-issue-with-string-literal
            }}
    
            onClick={() => {}} 
            onChange={() => {}}
          />
        </form>

        <div id={this.IdDeSoUsernameSelectDiv} className="DeSoUsernameSelectList">
          {
            this.state !== null && 
              this.state.filteredData?.map((item, key) => 
                <div id={"DeSoUsernameSelectItem_" + item.PublicKeyBase58Check} className="DeSoUsernameSelectItem" key={item.PublicKeyBase58Check} 
                  onMouseOver={(event: any) => {
                    const elemThis: HTMLDivElement = document.getElementById("DeSoUsernameSelectItem_" + item.PublicKeyBase58Check) as HTMLDivElement;
                    elemThis.style.background = 'rgb(53, 158, 30)';
                  }}
                  onMouseOut={(event: any) => {
                    const elemThis: HTMLDivElement = document.getElementById("DeSoUsernameSelectItem_" + item.PublicKeyBase58Check) as HTMLDivElement;
                    elemThis.style.background = 'rgb(140, 140, 140)';
                  }}
                  onClick={(event: any) => { this.handleSelectUser(event, "DeSoUsernameSelectItem_" + item.PublicKeyBase58Check); }}
                  >
                  <img src={DFConsts.DeSoAPI_GetSingleProfilePicture + item.PublicKeyBase58Check} height="16px" width="16px" alt={' '} />&nbsp;{item.Username}
                </div>
              )
          }
        </div>

      </div>
    );
  }
}