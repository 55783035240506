import * as React from "react";
import * as DFConsts from '../DFConsts';
import * as Button from "../_CustomComponents/CustomButtonComponent";
import Deso from 'deso-protocol';
import 'deso-protocol';
import { GetUsersStatelessRequest, GetUsersResponse } from 'deso-protocol-types';
import * as IState from "../../classes/IState";
import * as IGenerativAPIError from '../../classes/Security/IGenerativAPIError'
import * as ICheckCreateUser from "../../classes/Security/ICheckCreateUser";
import * as IAuthorizeDerivedKey from '../../classes/Security/IAuthorizeDerivedKey'

export async function doLogin(state: IState.IState): Promise<IState.IState> {
  const deso = new Deso();
  const loggedInUserPublicKey: string | null = deso.identity.getUserKey(); 

  DFConsts.gConsoleLog('UserData.doLogin - User uuid: ' + loggedInUserPublicKey);

  if(loggedInUserPublicKey !== null && loggedInUserPublicKey !== 'null' && loggedInUserPublicKey !== '') // TODO HACK WTF DeSo api returning string 'null' instead of null value (check length of string!)
  {
    return await getUserStatelessInfo(state, loggedInUserPublicKey);
  }
  else
  {
    return state;
  }
}

async function getUserStatelessInfo(state: IState.IState, PublicKey: string): Promise<IState.IState> {

  const requestGetUsersStateless: GetUsersStatelessRequest = {
    PublicKeysBase58Check: [PublicKey], 
    SkipForLeaderboard: false, 
    GetUnminedBalance: true, 
    IncludeBalance: true, 
  };

  const deso = new Deso();
  const jsonResponseDataUserInfo: GetUsersResponse = await deso.user.getUserStateless(requestGetUsersStateless);

  if(jsonResponseDataUserInfo.UserList !== undefined && jsonResponseDataUserInfo.UserList !== null && jsonResponseDataUserInfo.UserList.length > 0 && jsonResponseDataUserInfo.UserList[0] !== null && 
    jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse !== null && 
    jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse.Username !== undefined && jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse.Username != null && jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse.Username.length > 0)
  {
    DFConsts.gConsoleLog('UserData.getUserStatelessInfo - User Publick Key: ' + PublicKey);

    if(DFConsts.GetUserSessionKey(PublicKey) === null)
    {
      DFConsts.gConsoleLog('UserData.getUserStatelessInfo - no session key, need to authorize browser for PK: ' + PublicKey);

      const LocalState: IState.IState = DFConsts.CloneState(state);
      LocalState.props.PublicKey = PublicKey;

      const IsKeyAuthorized: boolean = await IAuthorizeDerivedKey.authorizeDerivedKey_AuthorizeBrowser(LocalState);
      DFConsts.gConsoleLog('UserData.getUserStatelessInfo - authorizeDerivedKey_AuthorizeBrowser result: ' + IsKeyAuthorized);
    }

    const localUsr: string = jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse.Username;
    const localBalanceNanos: number = jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse.DESOBalanceNanos;

    return fetch(DFConsts.GenerativAPIURL_CheckCreateUser + '?UserPublicKey=' + PublicKey + '&Username=' + jsonResponseDataUserInfo.UserList[0].ProfileEntryResponse.Username, {mode: 'cors'})
    .then(response => {
      if (response.status === 200) { return response.json(); }
      DFConsts.gConsoleLog('UserData.getUserStatelessInfo - response.status: ' + response.status);
    })
    .then(jsonData => {
      const ResponseJson: ICheckCreateUser.CheckCreateUserResponse = jsonData;

      if (IGenerativAPIError.HandleGenerativAPIFail('Verifying user failed.', state, ResponseJson)) { return state; };

      DFConsts.gConsoleLog('UserData.getUserStatelessInfo - User uuid: ' + ResponseJson.userUuid);
      
      const UpdatedState: IState.IState = DFConsts.CloneState(state);
      UpdatedState.props.Username = localUsr;
      UpdatedState.props.PublicKey = PublicKey;
      UpdatedState.props.DeSoBalance = localBalanceNanos;
      UpdatedState.props.IsLoggedIn = true;
      return UpdatedState;
    })
    .catch(err => {
      DFConsts.gConsoleLog('UserData.getUserStatelessInfo - checking/creating user failed - error: ' + err); 
      if (IGenerativAPIError.HandleGenerativAPIFail('Verifying user failed.', state, null)) { return state; };
      return state;
    });
  }
  else
  {
    return state;
  }
}

export class UserData extends React.Component<IState.IState, IState.IState> {
  constructor (props: IState.IState) {
    super(props);
    DFConsts.gConsoleLog('UserData.constructor - PublicKey: ' + props.props.PublicKey);
  }

 render() {

  const loginDeSo = async () => {
    DFConsts.gConsoleLog('UserData.loginDeSo');

    const deso = new Deso();
    const requestedAccessLevel = '2';
    const jsonResponseDataLogin = await deso.identity.login(requestedAccessLevel);

    let UpdatedState: IState.IState = IState.clearStateOnLoginStatusChange(this.props);
    UpdatedState = await getUserStatelessInfo(UpdatedState, jsonResponseDataLogin.key);
    window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(UpdatedState) }));    
  }

  const logoutDeSo = async () => {
    if (this.props.props.PublicKey != null)
    {
      const deso = new Deso();
      const logoutResult = await deso.identity.logout(this.props.props.PublicKey);
      if (logoutResult === true)
      {
        const AfterLogoutState: IState.IState = IState.clearStateOnLoginStatusChange(this.props);
        window.dispatchEvent(new CustomEvent(DFConsts.CustomEvent_StateChange, { detail: JSON.stringify(AfterLogoutState) }));
      }
    }
  }
  
  if (this.props.props.PublicKey !== undefined && this.props.props.Username !== undefined && this.props.props.PublicKey.length !== 0 && this.props.props.Username.length !== 0 && this.props.props.DeSoBalance !== undefined)
  {
    return (  
      <div className="LoggedInUserContainer">
        <div style={{ minHeight: '100%', alignItems:'center', justifyContent:'center', display:'flex' }}>
          <img src={DFConsts.DeSoAPI_GetSingleProfilePicture + this.props.props.PublicKey} width="80%" alt={this.props.props.PublicKey} />
        </div>
        <div style={{ minHeight: '100%', alignItems:'center', justifyContent:'center', display: 'flex', flexDirection:'column' }}>
          <div>
            <b>{this.props.props.Username}</b>
          </div>
          <div>
            $DeSo: <b>{new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 4}).format(this.props.props.DeSoBalance / DFConsts.NanosInDeso)}</b>
          </div>
        </div>
        <div style={{ minHeight: '100%', alignItems:'center', justifyContent:'center', display:'flex' }}>
          <Button.Button id="logout_button" height = "calc(16px + 2vmin)" onClick={() => {logoutDeSo();}} width = "calc(20px + 3vmin)" children = "🚪" />
        </div>
      </div>
    );
  }
  else
  {
    return (  
      <div className="LoggedInUserContainer">
        <div style={{ minHeight: '100%', alignItems:'center', justifyContent:'center', display:'flex' }}>
          <img src='https://bitclout.com/assets/img/default_profile_pic.png' width="100%" alt="Not logged in." />
        </div>
        <div style={{ minHeight: '100%', alignItems:'center', justifyContent:'center', display:'flex' }}>
          <Button.Button id="login_button" height = "calc(16px + 2vmin)" onClick={() => {loginDeSo();}} width = "calc(100px + 3vmin)" children = "DeSo Login" />
        </div>
        <div style={{ minHeight: '100%', alignItems:'center', justifyContent:'center', display:'flex' }}>
        </div>
      </div>
    );
  }
  }
}
